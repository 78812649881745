import React, { useEffect, useState } from 'react';
import { FeatureType } from '../../../types/types';
import { Map } from 'ol';
import PromptMessageBox from './PromptMessageBox';
import OlFeature from 'ol/Feature';
import VectorSource from 'ol/source/Vector';
import VectorLayer from 'ol/layer/Vector';
import Draw, { DrawEvent } from "ol/interaction/Draw";
import GeometryType from 'ol/geom/GeometryType';
import { drawingLayerStyle } from '../style';
import MapUtil from '../MapUtil';
import * as dbAccessor from "../../../util/DbAccessor";
import {RoadWidth} from '../../../types/types';
import RoadWidthSelecter from './RoadWidthSelecter';

enum DrawStage {
    DRAWING,        // 描画
    SELECT_WIDTH,   // 道幅選択
}
type Props = {
    /** 親からもらうprops定義 */
    map: Map;
    close: () => void;  // 作図完了時のコールバック
}

/**
 * 道描画コントローラ
 */
export default function DrawRoadController(props: Props) {
    const [drawStage, setDrawStage] = useState(DrawStage.DRAWING);
    const [drawingLayer] = useState(new VectorLayer({
        source: new VectorSource()
    }));
    const [draw] = useState(new Draw({
        source: drawingLayer.getSource(),
        type: GeometryType.MULTI_LINE_STRING,
        style: drawingLayerStyle(FeatureType.ROAD),
        clickTolerance: 12,
    }));
    // 描画中のFeature
    const [drawingFeature, setDrawingFeature] = useState(undefined as OlFeature | undefined);

    const drawReset = () => {
        draw.abortDrawing();
        setDrawingFeature(undefined);
        drawingLayer.getSource().clear();
        props.map.removeInteraction(draw);
    }

    useEffect(() => {
        drawingLayer.setStyle(drawingLayerStyle(FeatureType.ROAD));

        props.map.addLayer(drawingLayer);

        draw.on('drawend', (event: DrawEvent) => {
            // 作図完了
            props.map.removeInteraction(draw);
            props.map.removeLayer(drawingLayer);
    
            setDrawingFeature(event.feature);
            setDrawStage(DrawStage.SELECT_WIDTH);
        });

        props.map.addInteraction(draw);

        return () => {
            drawReset();
            props.map.removeLayer(drawingLayer);
        }
    }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []);

    // 描画中にキャンセルボタンが押された場合
    const onDrawMenuCanceled = () => {
        // 描画とりやめ
        props.close();
    }

    const onWidthSelected = async(width: RoadWidth) => {
        if (drawingFeature === undefined) {
            console.warn('not set drawingFeature.');
            return;
        }

        const geoJson = MapUtil.createGeoJson(drawingFeature);

        // 道幅情報登録
        if (geoJson.properties === undefined || geoJson.properties === null) {
            geoJson.properties = {};
        }
        Object.assign(geoJson.properties, {
            width,
        });

        // DB登録
        await dbAccessor.registFeature({
            type: FeatureType.ROAD,
            parent: null,
            geoJson,
            name: '',
        });

        props.close();
    }

    // 道幅選択がキャンセルされた場合
    const onWidthSelectCanceled = () => {
        drawReset();
        props.map.addInteraction(draw);
        props.map.addLayer(drawingLayer);

        setDrawStage(DrawStage.DRAWING);

    }

    if (drawStage === DrawStage.DRAWING) {
        return (
            <PromptMessageBox 
                message={'地図上で作図してください\n（完了ボタンまたはダブルクリックで完了）'} 
                okname="完了" ok={()=>{draw.finishDrawing()}} cancel={onDrawMenuCanceled} />                
        )
    } else {
        if (drawingFeature === undefined) {
            console.warn('not set drawingFeature');
            return null;
        }
        return (
            <RoadWidthSelecter map={props.map} targetRoad={drawingFeature} onOk={onWidthSelected} onCancel={onWidthSelectCanceled} />
        )

    }

}
