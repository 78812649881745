import React, { useCallback, useEffect, useRef, useState } from 'react'
import { ImageInfo } from '../../../types/types';
import styles from './ImageInfoEdit.module.scss';
import * as commonUtility from '../../../util/CommonUtility';
import ImageRegister from '../../common/ImageRegister';

/**
 * 看板画像の登録・編集部品
 */
enum Mode {
    REGIST,       // 看板登録ボタンが表示されている状態
    SELECT_IMAGE,   // 画像取得中
}
type Props = {
    /** 親からもらうprops定義 */
    value?: ImageInfo;  // 現在登録されている看板画像情報
    onUpdate: (value: ImageInfo) => void; 

    url?: string;   // 外側からURLを渡すと、このURLの画像がロードされる
}

function ImageInfoEdit(props: Props) {
    const canvasRef = useRef<HTMLCanvasElement>(null);
    const [currentMode, setCurrentMode] = useState(Mode.REGIST);

    // ↓新たな画像を設定した場合のみ更新可能にするので、その判定のために、ここでは既存のものは設定していない
    const [image, setImage] = useState({
        thumb: props.value === undefined ? '' : props.value.thumb,
        original: '',
    });

    useEffect(() => {
        if (props.url === undefined) {
            return;
        }
        const img = commonUtility.createImageForLoad({
            callback: (image: ImageInfo) => {
                setImage(image);
            },
            errorCallback: () => {
                // setErrMsg('この画像は取得できません.');
            }
        })
        img.src = props.url;
    }, [props.url]);

    useEffect(() => {
         // 登録・更新可能かチェック
        const registable = ((): boolean => {
            if (props.value?.thumb !== undefined && props.value.thumb.length > 0
                && image.thumb.length === 0) {
                    // 削除の場合
                    return true;
                }
            return image.original !== props.value?.original;
        })();
        if (registable) {
            props.onUpdate(image);
        }
    }, [image, props]);

    useEffect(() => {
        if (image.thumb.length === 0) {
            return;
        }
        const img = new Image();
        img.onload = () => {
            const width = img.width;
            const height = img.height;
            const canvas = canvasRef.current;
            if (canvas === null) {
                return;
            }
            canvas.setAttribute('width', width + '');
            canvas.setAttribute('height', height + '');
            const ctx = canvas.getContext('2d');
            if (ctx !== null) {
                ctx.drawImage(img, 0, 0, width, height);
            }
        };
        img.src = image.thumb;
    
    }, [image.thumb]);

    /**
     * 看板画像登録ボタン押下時
     */
    const onRegistBtnClicked = () => {
        if (currentMode === Mode.REGIST) {
            // 画像登録モードに
            setCurrentMode(Mode.SELECT_IMAGE);
        } else {
            setCurrentMode(Mode.REGIST);
        }
    }

    const onDeleteBtnClicked = () => {
        const canvas = canvasRef.current;
        if (canvas === null) {
            return;
        }
        const ctx = canvas.getContext('2d');
        if (ctx !== null) {
            ctx.clearRect(0, 0, canvas.width, canvas.height);
        }
        canvas.setAttribute('width', '0');
        canvas.setAttribute('height', '0');

        setImage({
            thumb: '',
            original: '',
        });
    }

    const onImageChange = useCallback((image: ImageInfo) => {
        setImage(image);
        setCurrentMode(Mode.REGIST);
    }, []);

    const controllArea = currentMode === Mode.REGIST ?
            <React.Fragment>
                <button className={`${styles.btn} btn btn-secondary`} onClick={() => onRegistBtnClicked()}>
                    {image.thumb.length === 0 ? '登録' : '更新'}
                </button>
                {image.thumb.length > 0 &&
                    <button className={`${styles.btn} btn btn-secondary`} onClick={() => onDeleteBtnClicked()}>削除</button>
                }
            </React.Fragment>
            :
            <ImageRegister onSelect={(img)=>onImageChange(img)} onCancel={()=>setCurrentMode(Mode.REGIST)} />
            ;
    
            
    let imageAreaClassName = styles.ImageArea;
    if (image.thumb.length === 0) {
        imageAreaClassName += (" " + styles.NoImage);
    }
    return (
        <div className={styles.MyArea}>
            <div className={imageAreaClassName}>
                {image.thumb.length === 0 &&
                    <p>なし</p>
                }
                <canvas ref={canvasRef} className="img-canvas" width="0" height="0" />
            </div>
            <div className={styles.UpdateArea}>
                {controllArea}
            </div>
        </div>
    );


}
export default ImageInfoEdit;
