import React, { useEffect, useMemo, useState } from 'react';
import { Map } from 'ol';
import VectorLayer from 'ol/layer/Vector';
import PromptMessageBox from './PromptMessageBox';
import { Select } from 'ol/interaction';
import StructureStyleFunctionCreator from '../StructureStyleFunctionCreator';
import { singleClick } from 'ol/events/condition';
import { FeatureInfo } from '../../../types/info';
import VectorSource from 'ol/source/Vector';
import { FeatureLike } from 'ol/Feature';
import * as dbAccessor from "../../../util/DbAccessor";
import { DispatchProps, StructureImageDefine } from '../../../types/types';
import { RootState } from '../../../store';
import { connect } from 'react-redux';

/**
 * 情報の引っ越し先の建物を選択して、引っ越すコントローラ
 */

 type OwnProps = {
    /** 親からもらうprops定義 */
    map: Map;
    target: FeatureInfo;    // 移動する情報
    structureLayer: VectorLayer;
    close: (featureId: string) => void;  // 移動完了時のコールバック。移動先の建物IDをparamに入れて返す
}

type StateProps = OwnProps & {
    /** ストアの内容から生成するprops定義 */
    iconDefine: StructureImageDefine[],
}
type Props = StateProps & DispatchProps;

const mapStateToProps = (state: RootState, ownProps: OwnProps): StateProps => ({
    ...ownProps,
    iconDefine: state.systemReducer.iconDefine,
});

function InfoMoveController(props: Props) {
    const [okable, setOkable] = useState(false);

    const styleCreator = useMemo(() => {
        return new StructureStyleFunctionCreator(props.structureLayer.getSource(), props.iconDefine);
    }, [props.structureLayer, props.iconDefine]);

    // 移動元の建物を強調表示
    useEffect(() => {
        const targetFeature = props.structureLayer.getSource().getFeatures().find((feature) => {
            return feature.getId() === props.target.featureId;
        });
        if (targetFeature === undefined) {
            console.warn('移動元の建物が見つかりません');
            return;
        }
        const originFeatureLayer = new VectorLayer({
            source: new VectorSource({
                features: [targetFeature],
            }),
            style: styleCreator.selectedFeatureStyleFunction,
        });

        props.map.addLayer(originFeatureLayer);

        return () => {
            props.map.removeLayer(originFeatureLayer);
        }
        // eslint-disable-next-line
    }, []);

    const select = useMemo(() => {
        return new Select({
            layers: [props.structureLayer],
            style: styleCreator.getStructureStyleFunction(true),
            condition: singleClick,
            filter: (featureLike: FeatureLike) => {
                // 移動元の建物は選択できないようにする
                return featureLike.getId() !== props.target.featureId;
            },
        });
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        select.on("select", () => {
            // 単一選択にする
            if (select.getFeatures().getLength() > 1) {
                select.getFeatures().removeAt(0);
            }
            setOkable(
                select.getFeatures().getLength() > 0
            );
        })
        props.map.addInteraction(select);

        return () => {
            props.map.removeInteraction(select);
        }
    }, [props.map, select]);

    const onSelectFinishClicked = async() => {
        // 情報移動
        const featureId = select.getFeatures().item(0).getId() as string;
        await dbAccessor.moveInfo(props.target.id, featureId);

        props.close(featureId);
    }

    const onCancelClicked = () => {
        props.close(props.target.featureId);
    }

    return (
        <PromptMessageBox message={"移動先の建物を選択してください。"} ok={onSelectFinishClicked} cancel={onCancelClicked} okdisabled={!okable} />

    )
}
export default connect(mapStateToProps)(InfoMoveController);