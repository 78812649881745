import React, { useEffect, useMemo, useState } from 'react';
import { Collection, Map } from 'ol';
import PromptMessageBox from './PromptMessageBox';
import OlFeature from 'ol/Feature';
import Geometry from 'ol/geom/Geometry';
import { DragBox, Select, Translate } from 'ol/interaction';
import { TranslateEvent } from 'ol/interaction/Translate';
import MapUtil from '../MapUtil';
import * as dbAccessor from "../../../util/DbAccessor";
import VectorLayer from 'ol/layer/Vector';
import Toggle from 'react-toggle';
import "react-toggle/style.css";
import styles from './MoveController.module.scss';
import StructureStyleFunctionCreator from '../StructureStyleFunctionCreator';
import {platformModifierKeyOnly} from 'ol/events/condition';
import { DispatchProps, StructureImageDefine } from '../../../types/types';
import { RootState } from '../../../store';
import { connect } from 'react-redux';

/**
 * Feature移動コントローラ
 */

type OwnProps = {
    /** 親からもらうprops定義 */
    map: Map;
    structureLayer: VectorLayer;
    close: () => void;  // 編集完了時のコールバック
}
type StateProps = OwnProps & {
    /** ストアの内容から生成するprops定義 */
    iconDefine: StructureImageDefine[],
}
type Props = StateProps & DispatchProps;

const mapStateToProps = (state: RootState, ownProps: OwnProps): StateProps => ({
    ...ownProps,
    iconDefine: state.systemReducer.iconDefine,
});

const dragBox = new DragBox({
        condition: platformModifierKeyOnly,
      });
const movedFeatureCollection = new Collection<OlFeature<Geometry>>();

function MoveController(props: Props) {
    const [okable, setOkable] = useState(false);
    // 移動用インタラクション
    const styleCreator = useMemo(() => {
        return new StructureStyleFunctionCreator(props.structureLayer.getSource(), props.iconDefine);
    }, [props.structureLayer, props.iconDefine]);
    const select = useMemo(() => {
        return new Select({
            layers: [props.structureLayer],
            style: styleCreator.getStructureStyleFunction(true),
        });
    }, [props.structureLayer, styleCreator]);

    const [prevGeometory] = useState({} as {[id: string]: Geometry});
    const [multipleMode, setMultipleMode] = useState(false);    // 複数選択モードの場合、true

    const onFinishClicked = () => {
        movedFeatureCollection.forEach((feature) => {
            const geoJson = MapUtil.createGeoJson(feature);
            dbAccessor.updateFeature(feature.getId() as string, geoJson);
        })
        props.close();
    }

    const onCancelClicked = () => {
        // 元に戻す
        movedFeatureCollection.forEach((feature) => {
            const geometory = prevGeometory[feature.getId()];
            feature.setGeometry(geometory);
        })
        props.close();
    }

    useEffect(() => {
        // 移動前の状態を記憶
        props.structureLayer.getSource().getFeatures().forEach((feature) => {
            prevGeometory[feature.getId()] = feature.getGeometry().clone();
        });

    }, [props.structureLayer, prevGeometory]);

    // ボックス選択
    useEffect(() => {
        dragBox.on('boxend', () => {
            // Extent内の建物を選択状態にする
            const extent = dragBox.getGeometry().getExtent();
            props.structureLayer.getSource().forEachFeature((feature) => {
                const geometry = feature.getGeometry().clone();
                if (geometry.intersectsExtent(extent)) {
                    select.getFeatures().push(feature);
                }
            })
        });
        props.map.addInteraction(dragBox);

        return () => {
            props.map.removeInteraction(dragBox);
        };
    }, [props.map, props.structureLayer, select]);

    useEffect(() => {
        let translate : Translate;
        if (multipleMode) {
            // 複数選択モードの場合
            translate = new Translate({
                layers: [props.structureLayer],
                features: select.getFeatures(),
            });
            props.map.addInteraction(select);
            props.map.addInteraction(dragBox);
        } else {
            // 単一モードの場合
            translate = new Translate({
                layers: [props.structureLayer],
            });
            select.getFeatures().clear();
            props.map.removeInteraction(select);
            props.map.removeInteraction(dragBox);
        }

        translate.on('translateend', (e: TranslateEvent) => {
            e.features.forEach((feature) => {
                movedFeatureCollection.push(feature);
            });
            setOkable(true);
        });
        props.map.addInteraction(translate);

        return () => {
            props.map.removeInteraction(translate);
            props.map.removeInteraction(select);
        };
    }, [select, multipleMode, props.map, props.structureLayer]);

    const onMultipleModeToggleClicked = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.checked;
        setMultipleMode(value);

    };

    let message;
    if (multipleMode) {
        message = "移動したい建物を選択の上、D&Dで移動してください。\n複数選択する場合->Shift+クリック\n矩形選択する場合->Ctrl+矩形描画";
    } else {
        message = "移動したい建物をD&Dで移動してください";
    }
    return (
        <PromptMessageBox message={message} ok={onFinishClicked} okname="完了" cancel={onCancelClicked} okdisabled={!okable}>
            <div className={`row ${styles.mode}`}>
                <label>選択モード</label>
                <Toggle id={'toggle-multiple'} defaultChecked={false}
                    icons={{
                        unchecked: <i className="icon-house"></i>,
                        checked: <i className="icon-house-multiple"></i>,
                    }}
                    className={`my-toggle ${styles.toggle}`}
                onChange={onMultipleModeToggleClicked} />
            </div>
        </PromptMessageBox>
    );

}
export default connect(mapStateToProps)(MoveController);
