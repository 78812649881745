import { FeatureDocItem } from "../../types/feature";
import { Feature, ThemeDefine } from "../../types/types";

export type State = {
    features: { [id: string]: Feature };    // 地物情報
    themeDefines: ThemeDefine[];        // 主題図定義
}

export enum ReducerType {
    RegistFeature = 'RegistFeature',
    RemoveFeature = 'RemoveFeature',
    UpdateThemeDefine = 'UpdateThemeDefine',
}

export interface RegistFeatureAction {
    type: ReducerType;
    payload: Array<{id: string; item: FeatureDocItem}>;
}

export interface RemoveFeatureAction {
    type: ReducerType;
    /** 削除対象の図形ID */
    payload: string[];
}

export interface UpdateThemeDefineAction {
    type: ReducerType;
    /** 更新後の主題図情報 */
    payload: ThemeDefine[];
}

export type ActionTypes = RegistFeatureAction | RemoveFeatureAction | UpdateThemeDefineAction;
