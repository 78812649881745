import React, { Component } from 'react'
import { DispatchProps, Feature, FeatureType, RoadWidth } from '../../../types/types';
import { RootState } from '../../../store';
import { connect } from 'react-redux';
import Select, { SelectEvent } from 'ol/interaction/Select';
import Modify from 'ol/interaction/Modify';
import { click } from 'ol/events/condition';
import VectorLayer from 'ol/layer/Vector';
import { Map } from 'ol';
import { topographySelectStyleFunction } from '../style';
import VectorSource from 'ol/source/Vector';
import { Style, Fill, Stroke } from 'ol/style';
import CircleStyle from 'ol/style/Circle';
import OlFeature from 'ol/Feature';
import MapUtil from '../MapUtil';
import * as dbAccessor from "../../../util/DbAccessor";
import PromptMessageBox from './PromptMessageBox';
import * as operationThunks from '../../../store/operation/thunks';
import { DialogResult } from '../../../types/operation';
import RoadWidthSelecter from './RoadWidthSelecter';

// 編集状況
enum EditStage {
    SELECTING_FEATURE,
    EDITING,
    SELECT_ROAD_WIDTH,  // 道幅選択（道編集の場合のみ）
}

type OwnProps = {
    /** 親からもらうprops定義 */
    map: Map;
    topographyLayers: VectorLayer[];
    close: () => void;  // 編集完了時のコールバック
}
type StateProps = OwnProps & {
    /** ストアの内容から生成するprops定義 */
    features: { [id: string]: Feature };    // 地物情報
}
type Props = StateProps & DispatchProps;

const mapStateToProps = (state: RootState, ownProps: OwnProps): StateProps => ({
    ...ownProps,
    features: state.featureReducer.features,
});

type State = {
    editStage: EditStage,
    selectedFeature: OlFeature | null;
};

/**
 * 地形編集用コントロールクラス
 */
class EditTopographyController extends Component<Props, State> {
    private topographySelect: Select;
    private modifySource: VectorSource;
    private modifyLayer: VectorLayer;
    private modify: Modify;

    constructor(props: Props) {
        super(props);
        this.state = {
            editStage: EditStage.SELECTING_FEATURE,
            selectedFeature: null,
        };

        this.topographySelect = new Select({
            condition: click,
            layers: props.topographyLayers,
            style: topographySelectStyleFunction,
        });
        this.topographySelect.on('select', (evt: SelectEvent) => {
            let selectedFeature;
            if (evt.selected.length === 0) {
                selectedFeature = null;
            } else {
                selectedFeature = evt.selected[0];
            }
            this.setState({
                selectedFeature,
            });
        });
        this.modifySource = new VectorSource();
        this.modifyLayer = new VectorLayer({
            source: this.modifySource,
            style: new Style({
                fill: new Fill({
                    color: 'rgba(255, 255, 255, 0.2)'
                }),
                stroke: new Stroke({
                    color: '#ffcc33',
                    width: 2
                }),
                image: new CircleStyle({
                    radius: 7,
                    fill: new Fill({
                        color: '#ffcc33'
                    })
                })
            })
        });
        this.modify = new Modify({ source: this.modifySource });
    }

    render() {
        switch (this.state.editStage) {
            case EditStage.SELECTING_FEATURE:
                return (
                    <PromptMessageBox 
                        message={'編集対象の地形を選択して、OKボタンを押下してください。'}
                        ok={this.onSelectEditTargetOkClicked.bind(this)} 
                        cancel={this.onSelectEditTargetCancelClicked.bind(this)} 
                        okdisabled={this.state.selectedFeature === null} />
                );

            case EditStage.EDITING:
                return (
                    <PromptMessageBox 
                        message={'編集完了したら、完了ボタンを押下してください。'} 
                        ok={this.onEditOkClicked.bind(this)} 
                        cancel={this.onEditCancelClicked.bind(this)} 
                        okname="完了" />
                );

            case EditStage.SELECT_ROAD_WIDTH:
                const feature = this.modifySource.getFeatures()[0];
                if (feature === undefined) {
                    console.warn('[EditTopography]not set feature');
                    return null;
                }
                const width = feature.getProperties()['width'];
                return (
                    <RoadWidthSelecter map={this.props.map} targetRoad={feature} width={width} onOk={this.confirm.bind(this)} onCancel={this.onSelectRoadWidthCanceled.bind(this)} />
                )
            }
    }

    componentDidUpdate(prevProps: Props, prevState: State) {
        if (prevState.editStage !== this.state.editStage) {
            this.onEditStageChanged(prevState.editStage);
        }
    }

    componentDidMount() {
        this.onEditStageChanged();
    }

    componentWillUnmount() {
        this.props.map.removeInteraction(this.topographySelect);
        this.modifySource.clear();
        this.props.map.removeLayer(this.modifyLayer);
        this.props.map.removeInteraction(this.modify);
    }

    private onEditStageChanged(prevStage?: EditStage) {
        if (prevStage === undefined && this.state.editStage === EditStage.SELECTING_FEATURE) {
            // [図形選択]
            // 地形を選択可能にする。
            this.props.map.addInteraction(this.topographySelect);

        } else if (prevStage === EditStage.SELECTING_FEATURE && this.state.editStage === EditStage.EDITING) {
            // [図形選択→図形編集]
            // 地形選択インタラクションOff
            this.props.map.removeInteraction(this.topographySelect);

            // 対象図形のソースを編集用ソースにコピー
            let olFeature = (this.state.selectedFeature as OlFeature).clone();
            let originLine = MapUtil.getOriginalLine(olFeature);
            if (originLine !== undefined) {
                olFeature = originLine;
            }
            this.modifySource.addFeature(olFeature);
            this.props.map.addLayer(this.modifyLayer);
            // 編集インタラクションOn
            this.props.map.addInteraction(this.modify);

        } else if (prevStage === EditStage.EDITING && this.state.editStage === EditStage.SELECTING_FEATURE) {
            // [図形編集→図形選択（戻る）]
            // 編集インタラクションOff
            this.modifySource.clear();
            this.props.map.removeLayer(this.modifyLayer);
            this.props.map.removeInteraction(this.modify);

            // 地形を選択可能にする。
            this.props.map.addInteraction(this.topographySelect);

        } else if (prevStage === EditStage.SELECTING_FEATURE && this.state.editStage === EditStage.SELECT_ROAD_WIDTH) {
            // [図形編集→道幅選択]
            // 編集インタラクションOff
            this.props.map.removeLayer(this.modifyLayer);
            this.props.map.removeInteraction(this.modify);

        } else if (prevStage === EditStage.SELECT_ROAD_WIDTH && this.state.editStage === EditStage.SELECTING_FEATURE) {
            // [道幅選択→図形編集]
            // 編集インタラクションOn
            this.props.map.addLayer(this.modifyLayer);
            this.props.map.addInteraction(this.modify);

        }
    }

    // 編集対象選択時
    private onSelectEditTargetOkClicked() {
        this.setState({
            editStage: EditStage.EDITING,
        });
    }

    /**
     * 編集対象選択キャンセル
     */
    private onSelectEditTargetCancelClicked() {
        // 終了
        this.props.close();
    }

    /**
     * 編集キャンセル時
     */
    private onEditCancelClicked() {
        this.setState({
            editStage: EditStage.SELECTING_FEATURE,
        });
    }

    /**
     * 編集完了時
     */
    private async onEditOkClicked() {
        const feature = this.props.features[this.state.selectedFeature?.getId() as string];
        if (feature.type === FeatureType.ROAD) {
            this.setState({
                editStage: EditStage.SELECT_ROAD_WIDTH,
            });
        } else {
            this.confirm();
        }
    }

    private onSelectRoadWidthCanceled() {
        this.setState({
            editStage: EditStage.EDITING,
        });
    }

    private async confirm(width?: RoadWidth) {
        const result = await this.props.dispatch(operationThunks.confirmThunk({
            message: '変更を確定してよろしいですか。'
        })) as DialogResult;
        if (result === DialogResult.CANCEL) {
            return;
        }

        // DB更新
        this.modifySource.getFeatures().forEach((feature: OlFeature) => {
            const geoJson = MapUtil.createGeoJson(feature);
            if (width !== undefined) {
                // 道幅情報登録
                if (geoJson.properties === undefined || geoJson.properties === null) {
                    geoJson.properties = {};
                }
                Object.assign(geoJson.properties, {
                    width,
                });
            }
            const id = this.state.selectedFeature?.getId() as string;
            dbAccessor.updateFeature(id, geoJson);
        });

        // 終了
        this.props.close();
    }
}
export default connect(mapStateToProps)(EditTopographyController);
