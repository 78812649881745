import { Map, Overlay } from 'ol';
import React, { CSSProperties, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import { Feature, FeatureType } from '../../types/types';
import {buffer, getSize} from 'ol/extent';
import MapUtil from './MapUtil';
import VectorSource from 'ol/source/Vector';
import { usePrevious } from '../../util/customHooks';
import map_define from './map_define';

type Props = {
    map: Map | undefined;
    topographySource: VectorSource;
}

// 表示する島名を返す
function createNamedEarthMap(state: RootState): {[id: string]: Feature} {
    const namedEarthArr = Object.values(state.featureReducer.features).filter((feature: Feature) => {
        if (feature.type !== FeatureType.EARTH) {
            return false;
        }
        if (feature.name === undefined || feature.name.length === 0) {
            return false;
        }

        // 表示範囲内の地物に絞る
        let extent = state.operationReducer.mapViewExtent;
        // --表示範囲よりも少し内側に入ったときに名前表示する
        const extentSize = getSize(extent);
        const minLen = Math.min(extentSize[0], extentSize[1]);
        extent = buffer(extent, -(minLen/5));
        const olFeature = MapUtil.createOlFeatureFromFeature(feature);
        return olFeature.getGeometry().intersectsExtent(extent);
    });
    const map = {} as {[id: string]: Feature};
    namedEarthArr.forEach((earth) => {
        map[earth.id] = earth;
    });
    return map;
}

export default function LandNameCreator(props: Props) {
    // 島名の付与された島
    const namedEarthMap = useSelector((state: RootState) => createNamedEarthMap(state));
    const prevNamedEarthMap = usePrevious(namedEarthMap);

    const [landNameRefMap] = useState({} as { [id: string]: HTMLDivElement });
    const [landNameOverlayMap] = useState({} as  { [id: string]: Overlay });

    const zoomLv = useSelector((state: RootState) => state.operationReducer.zoom);
    const disabledLandName = useSelector((state: RootState) => state.operationReducer.disabledLandName);

    // 島名の付与された島に変更があった場合
    useEffect(() => {
        if (props.map === undefined) {
            return;
        }
        // オーバレイを配置する
        Object.keys(namedEarthMap).forEach((id: string) => {
            const prevEarth = prevNamedEarthMap[id];
            if (prevEarth === undefined) {
                // 追加
                const element = landNameRefMap[id];
                if (element === undefined || element === null) {
                    return;
                }
                const overlay = new Overlay({
                    id: 'landname' + id,
                    element,
                    autoPan: false,
                    autoPanAnimation: {
                        duration: 250
                    },
                    className: 'landnameOverlayContainer',
                });
                const olFeature = props.topographySource.getFeatureById(id);
                const coord = olFeature.getGeometry().getExtent();
                overlay.setPosition([
                    coord[0] + (coord[2] - coord[0]) / 2, 
                    coord[1] + (coord[3] - coord[1]) / 2
                ]);
                props.map?.addOverlay(overlay);            
                landNameOverlayMap[id] = overlay;
            }
        });

        Object.keys(prevNamedEarthMap).forEach((id: string) => {
            const feature = namedEarthMap[id];
            if (feature === undefined) {
                // 削除
                const overlay = landNameOverlayMap[id];
                props.map?.removeOverlay(overlay);
            }
        });
    }, [namedEarthMap, prevNamedEarthMap, landNameOverlayMap, landNameRefMap, props.map, props.topographySource]);

    // 島名オーバーレイ
    const landNameOverlay = useMemo(() => {
        if (Object.values(namedEarthMap).length === 0) {
            return null;
        }

        // ズームLv.によって、フェードアウトするかどうか切り替え
        const isLandNameFade = zoomLv > map_define.LandNameShowZoomLv;
        const fadeclass= isLandNameFade ? "fadeOut" : "";
        const style = disabledLandName ? {
            display: 'none',
        } : {} as CSSProperties;
        return (
            <React.Fragment>
                {
                    Object.values(namedEarthMap).map((topography) => {
                        return (
                            <div key={topography.id}>
                                <div style={style} className={`landnameOverlay ${fadeclass}`} ref={ref => landNameRefMap[topography.id] = ref as HTMLDivElement}>
                                    {topography.name}
                                </div>
                            </div>
                        )
                    })
                }
            </React.Fragment>
        );
    }, [landNameRefMap, namedEarthMap, zoomLv, disabledLandName]);

    return landNameOverlay;
}