import { FeatureInfo, FeatureInfoContainDel } from '../../types/info';
import { TagInfo } from '../../types/types';
import { State, ReducerType, ActionTypes, AddFeaturePopupInfoAction, UpdateFeaturePopupInfoAction, RemoveFeaturePopupInfoAction, FlushFeaturePopupInfoDiffAction, UpdateTagInfoAction, RemoveTagInfoAction } from './types';

const initialState: State = {
    featurePopupInfoMap: {},
    tags: {},
}

export const reducer = (state = initialState, action: ActionTypes) => {
    switch (action.type) {
        case ReducerType.AddFeaturePopupInfo:
            return addFeaturePopupInfoReducer(state, (action as AddFeaturePopupInfoAction).payload);

        case ReducerType.UpdateFeaturePopupInfo:
            return updateFeaturePopupInfoReducer(state, (action as UpdateFeaturePopupInfoAction).payload);

        case ReducerType.RemoveFeaturePopupInfo:
            return removeFeaturePopupInfoReducer(state, (action as RemoveFeaturePopupInfoAction).payload);

        case ReducerType.FlushFeaturePopupInfoDiff:
            return flushFeaturePopupInfoDiffReducer(state, (action as FlushFeaturePopupInfoDiffAction).payload);

        case ReducerType.AddTagInfo:
        case ReducerType.UpdateTagInfo:
            return updateTagInfoReducer(state, (action as UpdateTagInfoAction).payload);
    
        case ReducerType.RemoveTagInfo:
            return removeTagInfoReducer(state, (action as RemoveTagInfoAction).payload);
        
        default:
            return state;
    }
}

/**
 * 建物の看板情報を追加登録する
 *
 * @param {State} state
 * @param {*} action
 * @returns
 */
function addFeaturePopupInfoReducer(state: State, payload: FeatureInfo) {
    const featureId = payload.featureId;
    let infoArr = state.featurePopupInfoMap[featureId];
    if (infoArr === undefined) {
        infoArr = [];
    }
    return Object.assign({}, state, {
        featurePopupInfoMap: Object.assign({}, state.featurePopupInfoMap, {
            [featureId]: infoArr.concat(payload),
        }),
    });
}

/**
 * 建物の看板情報を更新する
 * @param state 
 * @param action 
 */
function updateFeaturePopupInfoReducer(state: State, payload: FeatureInfo) {
    const featureId = payload.featureId;
    let infoArr = state.featurePopupInfoMap[featureId];
    if (infoArr === undefined) {
        console.warn('情報の属する建物がありません。', payload);
        return state;
    }
    const index = infoArr.findIndex((info) => {
        return info.id === payload.id;
    });
    if (index === -1) {
        console.warn('更新対象の情報がありません。', payload);
        return state;
    }
    const newInfoArr = infoArr.concat();
    newInfoArr.splice(index, 1, payload);
    return Object.assign({}, state, {
        featurePopupInfoMap: Object.assign({}, state.featurePopupInfoMap, {
            [featureId]: newInfoArr,
        }),
    });
}

function removeFeaturePopupInfoReducer(state: State, payload: {featureId: string; infoId: number}) {
    let infoArr = state.featurePopupInfoMap[payload.featureId];
    if (infoArr === undefined) {
        console.warn('情報の属する建物がありません。', payload);
        return state;
    }
    const index = infoArr.findIndex((info) => {
        return info.id === payload.infoId;
    });
    if (index === -1) {
        console.warn('削除対象の情報がありません。', payload);
        return state;
    }
    const newInfoArr = infoArr.concat();
    newInfoArr.splice(index, 1);
    return Object.assign({}, state, {
        featurePopupInfoMap: Object.assign({}, state.featurePopupInfoMap, {
            [payload.featureId]: newInfoArr,
        }),
    });
}

/**
 * ポップアップ情報の差分をチェックして、必要に応じて既存のポップアップを追加・更新・削除する。
 * @param state 
 * @param action 
 */
function flushFeaturePopupInfoDiffReducer(state: State, payload: FeatureInfoContainDel) {
    const info = payload;
    const featureInfo = JSON.parse(JSON.stringify(info));
    delete featureInfo.del;

    const featurePopupInfoList = state.featurePopupInfoMap[info.featureId];
    if (info.del) {
        // 削除時
        return removeFeaturePopupInfoReducer(state, {
            featureId: info.featureId,
            infoId: info.id
        });
    } else if(featurePopupInfoList === undefined || featurePopupInfoList.find((myinfo) => {
            return myinfo.id === info.id;
        }) === undefined) {
        // 追加時
        // 画像が存在する場合のみ追加
        if (info.imageThumb.length > 0) {
            return addFeaturePopupInfoReducer(state, featureInfo);
        }
    } else {
        // 更新時
        if (info.imageThumb.length === 0) {
            // 画像が存在しなくなっていたら削除
            return removeFeaturePopupInfoReducer(state, {
                featureId: info.featureId,
                infoId: info.id
            });
        } else {
            // 更新
            return updateFeaturePopupInfoReducer(state, featureInfo);
        }
    }
    return state;
}

/**
 * タグ情報を登録する
 * @param state 
 * @param payload 
 */
function updateTagInfoReducer(state: State, payload: TagInfo) {
    return Object.assign({}, state, {
        tags: Object.assign({}, state.tags, {
            [payload.id]: payload,
        }),
    });
}

/**
 * タグ情報を削除する
 * @param state 
 * @param payload 削除するタグID
 */
function removeTagInfoReducer(state: State, payload: string) {
    const newTags = Object.assign({}, state.tags) as { [id: string]: TagInfo };
    delete newTags[payload];
    return Object.assign({}, state, {
        tags: newTags,
    }); 
}

