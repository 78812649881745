import React, { Component } from 'react'
import { connect } from "react-redux";
import 'ol/ol.css';
import { Map, View } from 'ol';
import { defaults } from 'ol/interaction'
import { Vector as VectorLayer } from 'ol/layer';
import { Vector as VectorSource } from 'ol/source';
import OlFeature from 'ol/Feature';
import { ScaleLine } from 'ol/control';
import './Map.scss'
import styles from './Map.module.scss';
import { topographyStyleFunction } from './style';
import { MapMode, FeatureType, Feature, DispatchProps, VillageUserInfo, ThemeDefine, StructureImageDefine } from "../../types/types";
import { storeGetter } from "../../util/props";
import * as dbAccessor from "../../util/DbAccessor";
import MapUtil from './MapUtil';
import Translate from 'ol/interaction/Translate';
import { RootState } from '../../store';
import StructureStyleFunctionCreator from './StructureStyleFunctionCreator';
import Select, { SelectEvent } from 'ol/interaction/Select';
import { click } from 'ol/events/condition';
import {buffer} from 'ol/extent';
import * as operationActions from '../../store/operation/actions';
import { KickCommand } from '../../types/operation';
import DrawController from './DrawController';
import PopupCreator from './PopupCreator';
import LandNameCreator from './LandNameCreator';

type StateProps = {
    /** ストアの内容から生成するprops定義 */
    features: { [id: string]: Feature };  // 全地物情報
    structures: Feature[];      // 建物・道
    topographies: Feature[];
    selectedFeatureId: string | undefined;
    selectedFeature: Feature | undefined;
    mapMode: MapMode;
    userInfo: VillageUserInfo | undefined; // 現在のユーザ情報
    themeDefines: ThemeDefine[];        // 主題図定義
    iconDefine: StructureImageDefine[],
    kickCommand: KickCommand | undefined;
    kickCommandOption: {} | undefined;
}

type Props = StateProps & DispatchProps;

const mapStateToProps = (state: RootState): StateProps => ({
    features: state.featureReducer.features,
    structures: storeGetter(state).structures,
    topographies: storeGetter(state).topographies,
    selectedFeatureId: state.operationReducer.selectFeatureId,
    selectedFeature: storeGetter(state).selectedFeature,
    mapMode: state.operationReducer.mode,
    userInfo: state.systemReducer.users[state.systemReducer.signInUserId as string] as VillageUserInfo,
    themeDefines: state.featureReducer.themeDefines,
    iconDefine: state.systemReducer.iconDefine,
    kickCommand: state.operationReducer.kickCommand?.command,
    kickCommandOption: state.operationReducer.kickCommand?.option,
});

type State = {
}

class MapOL extends Component<Props, State> {
    private mymap: Map | undefined;

    // 地形、道路
    private topographySource: VectorSource = new VectorSource();
    private topographyLayer: VectorLayer = new VectorLayer({
        source: this.topographySource,
        style: topographyStyleFunction
    });

    // 建物
    private structureSource: VectorSource = new VectorSource();
    private styleCreator: StructureStyleFunctionCreator;
    private structureLayer: VectorLayer;

    // 描画ツール
    private translate: Translate | undefined;       // 引越し用

    private select: Select | undefined;                       // 地図クリック制御

    // 最後に位置情報をDBに保存した時間
    private lastViewUpdateTime: number = 0;
    private UPDATE_INTERVAL = 10 * 1000; // 位置情報の更新間隔。この時間以内に地図移動されても更新されない。
    private saveViewHandle: any = undefined;

    constructor(props: Props) {
        super(props);
        this.styleCreator = new StructureStyleFunctionCreator(this.structureSource, props.iconDefine);
        this.structureLayer = new VectorLayer({
            source: this.structureSource,
            style: this.styleCreator.getStructureStyleFunction(),
            maxZoom: 19,
        });
        this.state = {
            popupInfoMap: {},
            // namedEarthMap: {},
        }
    }

    render() {
        let option = null;
        if (this.props.mapMode === MapMode.LOADING) {
            option = (
                <div className={styles.OverlayNotification}>
                    loading...
                </div>
            );

        } else {
            option = <DrawController map={this.mymap as Map} styleCreator={this.styleCreator} 
                        topographyLayer={this.topographyLayer} structureLayer={this.structureLayer}
                        select={this.select} />
        }

        return (
            <React.Fragment>
                <div className={styles.Map} id="map" />
                <PopupCreator map={this.mymap} structureSource={this.structureSource} select={this.select} />
                <LandNameCreator map={this.mymap} topographySource={this.topographySource} />
                {option}
            </React.Fragment >
        );
    }

    componentDidMount() {
        const view = this.props.userInfo?.view;
        const center = (view === undefined || view.center === undefined) ? [0, 0] : view.center;
        const zoom = (view === undefined || view.zoom === undefined) ? 18 : view.zoom;
        this.mymap = new Map({
            target: 'map',
            view: new View({
                projection: 'EPSG:3857',
                center,
                zoom,
                minZoom: 10,
                maxZoom: 19,
            }),
            interactions: defaults({ doubleClickZoom: false, shiftDragZoom: false }),
        });
        this.mymap.addLayer(this.topographyLayer);
        this.mymap.addLayer(this.structureLayer);

        // スケール
        const scaleControl = new ScaleLine({
            units: 'metric',
        });
        this.mymap.addControl(scaleControl);

        // クリックイベントの設定
        this.select = new Select({
            condition: click,
            layers: [this.structureLayer],
            style: this.styleCreator.selectedFeatureStyleFunction,
        });
        this.select.on('select', this.selectEventCallback.bind(this));
        this.mymap.addInteraction(this.select);

        if (Object.keys(this.props.features).length > 0) {
            this.onFeatureChanged({}, this.props.features);
        }
        this.mymap.addEventListener('moveend', (): boolean => {
            this.updateStoreViewInfo();
            this.saveViewInfo();
            return true;
        });
        this.mymap.on('pointerdrag', () => {
            this.updateStoreViewInfo();
        });
        this.updateStoreViewInfo();

        if (view === undefined || view.center === undefined) {
            this.toCenter();
        }
    }

    private selectEventCallback(evt: SelectEvent) {
        console.log('selectEventCallback')
        if (evt.selected.length === 0) {
            // 選択解除
            this.props.dispatch(operationActions.unselectFeatureAction());
            return;
        }

        // 1つしか選択できないようにする
        if (evt.selected.length > 1) {
            this.select?.getFeatures().removeAt(0);
        }

        // 選択ジオメトリに対する挙動
        const featureId = evt.selected[0].getId();
        this.props.dispatch(operationActions.selectFeatureAction(featureId as string));
    }

    /**
     * 現在のView位置をDB保存する
     */
    private saveViewInfo() {
        if (this.mymap === undefined) {
            return;
        }
        if (this.saveViewHandle !== undefined) {
            clearTimeout(this.saveViewHandle);
            this.saveViewHandle = undefined;
        }
        const now = new Date().getTime();
        if (now - this.lastViewUpdateTime < this.UPDATE_INTERVAL) {
            // 前回保存時刻から間もないので予約
            this.saveViewHandle = setTimeout(this.saveViewInfo.bind(this), 3000);
            return;
        }
        this.lastViewUpdateTime = now;
        const center = this.mymap?.getView().getCenter();
        const zoom = this.mymap?.getView().getZoom();
        // DBに現在位置保存
        if (this.props.userInfo !== undefined) {
            dbAccessor.updateUserInfo(Object.assign({}, this.props.userInfo, {
                view: {
                    center,
                    zoom,
                }
            }));
        }
    }

    private updateStoreViewInfo() {
        const extent = this.mymap?.getView().calculateExtent();
        if (extent === undefined) {
            return;
        }
        const zoom = this.mymap?.getView().getZoom();
        if (zoom === undefined) {
            return;
        }
        this.props.dispatch(operationActions.updateMapViewExtent(extent, zoom));
    }

    componentDidUpdate(prevProps: Props, prevState: State) {
        if (this.mymap === undefined) {
            return;
        }

        if (prevProps.features !== this.props.features) {
            // 地物追加or削除
            this.onFeatureChanged(prevProps.features, this.props.features);
        }
        if (prevProps.mapMode !== this.props.mapMode) {
            // 描画モード変更
            this.onMapModeChanged();
        }
        if (prevProps.themeDefines !== this.props.themeDefines) {
            this.onThemeDefinesChanged();
        }
        if (prevProps.iconDefine !== this.props.iconDefine) {
            this.styleCreator.setIconDefine(this.props.iconDefine);
            this.structureLayer.setStyle(this.styleCreator.getStructureStyleFunction());
        }
        if(prevProps.kickCommand !== this.props.kickCommand) {
            // let drawController = undefined;
            if (this.props.kickCommand === KickCommand.MapToCenter) {
                this.toCenter();
                this.props.dispatch(operationActions.commandKicked());


            } else if (this.props.kickCommand === KickCommand.JumpToInfo) {
                // 指定の情報へ移動
                // 建物IDを取得
                const infoId = (this.props.kickCommandOption as any).infoId;
                dbAccessor.getInfoFeatureId(infoId)
                .then((featureId: string|undefined) => {
                    if (featureId === undefined) {
                        return;
                    }
                    // 建物へ移動
                    const feature = this.structureSource.getFeatureById(featureId);
                    this.mymap?.getView().fit(feature.getGeometry().getExtent(), {
                        maxZoom: this.mymap?.getView().getZoom(),
                    });
                    // 移動先の建物の情報を表示する
                    this.select?.getFeatures().removeAt(0);
                    this.select?.getFeatures().push(feature);
                    this.props.dispatch(operationActions.selectFeatureAction(featureId, infoId));
                });
                this.props.dispatch(operationActions.commandKicked());
            }

        }
    }

    private toCenter() {
        const extent = this.topographySource.getExtent();
        if (extent[0] === Infinity) {
            return;
        }
        this.mymap?.getView().fit(extent);
    }

    private async onThemeDefinesChanged() {
        this.styleCreator.setThemeDefines(this.props.themeDefines);
        this.structureLayer.setStyle(this.styleCreator.getStructureStyleFunction());

        // 対象のFeatureで地図fitする
        const targetIds = [] as string[];   // 対象建物ID
        this.props.themeDefines.forEach((def) => {
            const featureIds = Object.keys(def.featureInfoMap);
            Array.prototype.push.apply(targetIds, featureIds);
        });
        this.fitFeatures(targetIds);
    }

    /**
     * 指定の建物が収まる範囲で地図fitさせる
     * @param featureIds fit対象の建物ID配列
     */
    private fitFeatures(featureIds: string[]) {
        const targetSource = new VectorSource();
        targetSource.addFeatures(
            this.structureSource.getFeatures().filter((feature) => {
                return featureIds.indexOf(feature.getId() as string) !== -1;
            })
        );
        let extent = targetSource.getExtent();
        if (extent[0] === Infinity) {
            return;
        }
        extent = buffer(extent, 100);
        this.mymap?.getView().fit(extent);
    }

    private onFeatureChanged(oldFeatures: { [id: string]: Feature }, newFeatures: { [id: string]: Feature }) {
        const oldIds = Object.keys(oldFeatures);

        // 追加された地物を地図に描画
        const newFeatureIds = [] as string[];
        Object.keys(newFeatures).forEach((id: string) => {
            if (oldIds.indexOf(id) === -1) {
                newFeatureIds.push(id);
                return;
            }

            // 更新確認
            const oldFeature = JSON.parse(JSON.stringify(oldFeatures[id])) as Feature;
            const newFeature = JSON.parse(JSON.stringify(newFeatures[id])) as Feature;
            if (JSON.stringify(oldFeature) !== JSON.stringify(newFeature)) {
                newFeatureIds.push(id);
            }
        });
        const updateFeature = (source: VectorSource, feature: OlFeature) => {
            const exist = source.getFeatureById(feature.getId());
            if (exist !== null) {
                source.removeFeature(exist);
            }
            source.addFeature(feature);

            // パンニング可能範囲設定
            if (source === this.topographySource) {
                const tExt = this.topographySource.getExtent();
                const extent = buffer(tExt, 10000);
    
                const view = new View({
                    projection: this.mymap?.getView().getProjection(),
                    center: this.mymap?.getView().getCenter(),
                    zoom: this.mymap?.getView().getZoom(),
                    minZoom: this.mymap?.getView().getMinZoom(),
                    maxZoom: this.mymap?.getView().getMaxZoom(),
                    extent,
                });
                this.mymap?.setView(view);
            }
        }
        newFeatureIds.forEach((id: string) => {
            const feature = newFeatures[id];
            const myFeature = MapUtil.createOlFeatureFromFeature(feature);
            switch (feature.type) {
                case FeatureType.STRUCTURE:
                    // シンボル
                    updateFeature(this.structureSource, myFeature);
                    break;
                case FeatureType.ROAD:
                case FeatureType.EARTH:
                case FeatureType.FOREST:
                    updateFeature(this.topographySource, myFeature);
                    break;
            }
        });

        // 削除された地物を地図から削除
        const newIds = Object.keys(newFeatures);
        const delFeatureIds = Object.keys(oldFeatures).filter((id: string) => {
            return newIds.indexOf(id) === -1;
        });
        delFeatureIds.forEach((id: string) => {
            const feature = oldFeatures[id];
            let source: VectorSource | undefined;
            switch (feature.type) {
                case FeatureType.STRUCTURE:
                    source = this.structureSource;
                    break;
                case FeatureType.ROAD:
                case FeatureType.EARTH:
                case FeatureType.FOREST:
                    source = this.topographySource;
                    break;
            }
            if (source !== undefined) {
                const olFeature = source.getFeatureById(id);
                source.removeFeature(olFeature);
            }
        });
        this.mymap?.render();
    }

    /**
     * 描画モードが変更された場合
     *
     * @memberof Map
     */
    private onMapModeChanged() {
        if (this.mymap === undefined) {
            return;
        }
        switch (this.props.mapMode) {
            case MapMode.NORMAL:
                if (this.translate !== undefined) {
                    this.mymap.removeInteraction(this.translate);
                    this.translate = undefined;
                }
                this.mymap.addInteraction(this.select as Select);
                if (this.select !== undefined) {
                    this.select.on('select', this.selectEventCallback.bind(this));
                }
                break;

            case MapMode.DRAW:
                console.log('remove');
                // 地形作図or編集or情報編集or引っ越し
                this.mymap.removeInteraction(this.select as Select);
                if (this.select !== undefined) {
                    console.log('unselect')
                    this.select.un('select', this.selectEventCallback.bind(this));
                }
                break;

            default:
        }
    }

}

export default connect(mapStateToProps)(MapOL);
