import React, { Component } from 'react'
import * as dbAccessor from "../../util/DbAccessor";
import { VillageUserInfoStore } from '../../types/types';
import { DispatchProps } from '../../types/types';
import { RootState } from '../../store';
import { connect } from 'react-redux';
import styles from './RegistUser.module.scss';

type OwnProps = {
    /** 親からもらうprops定義 */
    uid: string;
    group?: string;
    complete: () => void; // 住民登録完了時のコールバック
}
type StateProps = OwnProps & {
    /** ストアの内容から生成するprops定義 */
}
type Props = StateProps & DispatchProps;

const mapStateToProps = (state: RootState, ownProps: OwnProps): StateProps => ({
    ...ownProps,
});

type State = {
    name: string;
}

/**
 * ユーザ登録画面
 *
 * @export
 * @class RegistUser
 * @extends {Component<MyProps, MyState>}
 */
class RegistUser extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            name: '',
        };
    }

    render() {
        return (
            <div className={styles.content}>
                <div className="card">
                    <div className="card-header">
                        住民登録
                    </div>
                    <div className="card-body">
                        <div className="form-group row">
                            <label className="col-sm-2 col-form-label">氏名</label>
                            <div className="col-sm-10">
                                <input value={this.state.name} type="text" name="name" className="form-control" onChange={this.onChangeHandlerName.bind(this)} />
                            </div>
                        </div>
                        <button onClick={this.onRegist.bind(this)} className="btn btn-primary" disabled={!this.isSubmitable}>登録</button>
                    </div>
                </div >
            </div>
        );
    }

    private get isSubmitable(): boolean {
        return this.state.name.length > 0;
    }

    private onChangeHandlerName(event: any) {
        this.setState({
            name: event.target.value,
        });
    }

    private onRegist() {
        // ユーザ情報登録
        dbAccessor.getVillageUserCollection().doc(this.props.uid).set({
            name: this.state.name,
            groups: this.props.group === undefined ? [] : [this.props.group],
            special: false,
        } as VillageUserInfoStore)
        .then(() => {
            console.log('住民登録完了');
            this.props.complete();
        })
        .catch((e) => {
            console.warn('住民登録失敗', e);
        })
    }
}
export default connect(mapStateToProps)(RegistUser);
