import React, { Component } from 'react';
import { DispatchProps } from '../../types/types';
import { RootState } from '../../store';
import { connect } from 'react-redux';
import styles from './CircleMenu.module.scss';
import MenuBtn from './MenuBtn';
import { MenuDefine } from './types';
import * as operationActions from '../../store/operation/actions';
import { KickCommand } from '../../types/operation';

type OwnProps = {
    /** 親からもらうprops定義 */
    define: MenuDefine[];
}
type StateProps = OwnProps & {
    /** ストアの内容から生成するprops定義 */
}
type Props = StateProps & DispatchProps;

const mapStateToProps = (state: RootState, ownProps: OwnProps): StateProps => ({
    ...ownProps,
});

type State = {
    define: MenuDefine;
    ancestor: MenuDefine[];     // 末尾に現在の親が入っている。メニューが閉じている時は空。
    startDegree: number;        // 開始角度

    // アニメーション関連
    newAncestor: MenuDefine[];         // ancestor予約
    openFinished: boolean;            // メニューが完全にオープンしている状態か（時間を置いてtransform設定しないとアニメーションにならないのでフラグを用意している）
    guideShowFlag: boolean;           // メニューオープンアニメーションが完全に終了してからGuide表示しないと位置がおかしくなるので、そのためのフラグ
}

const rootMenuDefine = {
    caption: 'メニュー',
    iconClass: 'icon-menu',
} as MenuDefine;

class CircleMenu extends Component<Props, State> {
    private rotaiting: boolean = false; // 回転中かどうか
    private rotated: boolean = false;   // 回転処理を行ったか（クリックイベントの無効判断用のフラグ）
    private beforeDegree = 0;
    private parentRef: React.RefObject<HTMLDivElement>

    constructor(props: Props) {
        super(props);
        this.parentRef = React.createRef();
        this.state = {
            define: Object.assign({}, rootMenuDefine, {
                children: props.define,
            }),
            ancestor: [],
            startDegree: 0,
            openFinished: false,
            guideShowFlag:false,
            newAncestor: [],
        };
    }

    render() {
        const parentGuide = this.state.ancestor.length === 0 ? this.getGuide(this.parent) : undefined;  // メニューを閉じている場合は配下のguideを表示
        const parent = (
            <div ref={this.parentRef} className={styles.StartingPoint}>
                <MenuBtn onClick={this.onParentClick.bind(this)} iconClass={this.parent.iconClass} guide={parentGuide}>{this.parent.caption}</MenuBtn>
            </div>
        )
        const children = this.children.map((child, index) =>{
            const style = this.state.openFinished ? this.getStyle(index) : undefined;
            const guide = (this.state.openFinished && this.state.guideShowFlag) ? this.getGuide(child) : undefined;
            return (
                <div key={index} onTouchStart={this.onTouchStart.bind(this)} onMouseDown={this.onMousedown.bind(this)} 
                                onTouchEnd={this.onTouchEnd.bind(this)} onMouseUp={this.onMouseUp.bind(this)} 
                                onTouchMove={this.onTouchMove.bind(this)} onMouseMove={this.onMousemove.bind(this)} 
                                className={styles.StartingPoint} style={style}>
                    <MenuBtn onClick={this.onChildClick.bind(this, child)} isSubMenu iconClass={child.iconClass} badge={child.badge} disabled={child.disalbed} guide={guide}>{child.caption}</MenuBtn>
                </div>
            );
        })
        const circleClass = this.state.ancestor.length === 0 ? styles.hide : '';
        const circle = (
            <svg viewBox="0 0 260 260" xmlns="http://www.w3.org/2000/svg" className={circleClass}>
                <circle cx="130" cy="130" r="130" fill="none" stroke="rgba(255,255,255,0.5)" strokeWidth="5"/>
            </svg>
        )
        return (
            <div className={styles.MenuArea}>
                <div onMouseMove={this.onMousemove.bind(this)} onMouseUp={this.onMouseUp.bind(this)} onMouseLeave={this.onMouseUp.bind(this)} className={styles.TouchArea} />
                {circle}
                {parent}
                {children}
            </div>
        );
    }

    componentDidUpdate(prevProps: Props, prevState: State) {
        if (prevState.ancestor !== this.state.ancestor) {
            this.setState({
                startDegree: 0,
            });
        }
        if (prevState.newAncestor !== this.state.newAncestor) {
            // 表示するメニューの変更が予約された時
            this.changeShowMenu();
        }
        if (prevProps.define !== this.props.define) {
            this.setState((state) => {
                return {
                    define: Object.assign({}, rootMenuDefine, {
                        children: this.props.define,
                    }),
                }
            })
        }
    }

    private changeShowMenu() {
        const guideShowDelay = 300;
        if (this.state.ancestor.length === 0) {
            // メニューが閉じている時は、メニューを開く
            this.setState({
                ancestor: this.state.newAncestor,
            });
            setTimeout(() => {
                this.setState({
                    guideShowFlag: false,
                    openFinished: true,
                });
                setTimeout(() => {
                    this.setState({
                        guideShowFlag: true,
                    });
                }, guideShowDelay);
            }, 10);
            return;
        }

        // メニューが開いている時
        if (this.state.ancestor.length < this.state.newAncestor.length) {
            // 階層を下る場合
            // -- 1. 現在のメニューを閉じる
            this.setState({
                openFinished: false,
            });
            // -- 2. 新しいメニューにする
            setTimeout(() => {
                this.setState({
                    openFinished: false,
                    ancestor: this.state.newAncestor,
                });
                setTimeout(() => {
                    this.setState({
                        guideShowFlag: false,
                        openFinished: true,
                    });
                    setTimeout(() => {
                        this.setState({
                            guideShowFlag: true,
                        });
                    }, guideShowDelay);
                }, 10);
            }, 200);
        } else {
            // 階層を上がる場合
            // -- 1. 現在のメニューを閉じる
            this.setState({
                openFinished: false,
            });
            // -- 2. 新しいメニューにする
            setTimeout(() => {
                this.setState({
                    ancestor: this.state.newAncestor,
                });
                const openFinished = this.state.newAncestor.length > 0;
                setTimeout(() => {
                    this.setState({
                        guideShowFlag: false,
                        openFinished,
                    });
                    setTimeout(() => {
                        this.setState({
                            guideShowFlag: true,
                        });
                    }, guideShowDelay);
                }, 10);
            }, 200);
        }

    }

    private get parent(): MenuDefine {
        if (this.state.ancestor.length === 0) {
            return this.state.define;
        }
        return this.state.ancestor[this.state.ancestor.length-1];
    }

    private get children(): MenuDefine[] {
        if (this.state.ancestor.length === 0) {
            // メニューが閉じている場合
            return [];
        }
        return this.parent.children === undefined ? [] : this.parent.children;
    }

    // 1メニューごとの角度
    private get perDegree(): number {
        return Math.max(38, 90 / (this.children.length -1));
    }

    private get maxStartDegree(): number {
        const totalDegree = this.perDegree * (this.children.length - 1);
        return totalDegree - 90;
    }

    private getStyle(index: number): React.CSSProperties {
        const r = 130;  // length[px]
        const theta = this.perDegree * index - this.state.startDegree;
        const rad = theta * (Math.PI / 180);
        const y = theta === 90 || theta === 270 ? 0 : (- r * Math.cos(rad));
        const x = theta === 0 || theta === 180 ? 0 : r * Math.sin(rad);
        const style = {
            transform: 'translate(' + x + 'px, ' + y + 'px)'
        } as React.CSSProperties;
        if (this.state.startDegree !== 0) {
            // 回転アニメーションの時はtransitionオフ
            style.transition = 'unset';
        }
        return style;
    }

    private onParentClick() {
        if (this.state.ancestor.length === 0) {
            // メニューが閉じている時は、メニューを開く
            this.setState({
                newAncestor: [this.state.define],
            });
            return;
        }
        // メニューが開いている時は、1つ上の階層に戻る
        const newAncestor = this.state.ancestor.concat();
        newAncestor.pop();
        this.setState({
            newAncestor,
        });
    }

    private onChildClick(child: MenuDefine) {
        if (this.rotaiting) {
            return;
        }
        if (this.rotated) {
            this.rotated = false;
            return;
        }
        if (child.children === undefined || child.children.length === 0) {
            // メニュー実行
            this.props.dispatch(operationActions.kickCommand(child.command as KickCommand));
            this.setState({
                openFinished: false,
                ancestor: [],
            });
            return;
        }
        // 階層を下る
        this.setState((state) => {
            return {
                newAncestor: state.ancestor.concat(child),
            }
        });
    }

    private onTouchStart(evt: React.TouchEvent) {
        this.startRotation(
            evt.touches[0].clientX,
            evt.touches[0].clientY,
        );
    }
    private onMousedown(evt: React.MouseEvent) {
        this.startRotation(evt.clientX, evt.clientY);
    }

    private startRotation(clientX: number, clientY: number) {
        if (this.rotaiting) {
            return;
        }
        this.rotaiting = true;
        this.rotated = false;
        this.beforeDegree = this.calculateDegree(clientX, clientY);
    }

    private onTouchEnd(evt: React.TouchEvent) {
        this.endRotation();
    }
    private onMouseUp(evt: React.MouseEvent) {
        this.endRotation();
    }
    private endRotation() {
        if (!this.rotaiting) {
            return;
        }
        this.rotaiting = false;

    }

    private onTouchMove(evt: React.TouchEvent) {
        this.rotate(
            evt.touches[0].clientX,
            evt.touches[0].clientY
        );

    }
    private onMousemove(evt: React.MouseEvent) {
        if (evt.buttons !== 1) {
            return;
        }
        this.rotate(evt.clientX, evt.clientY);
    }
    private rotate(clientX: number, clientY: number) {
        if (!this.rotaiting) {
            return;
        }
        const degree = this.calculateDegree(clientX, clientY);
        const rot = degree - this.beforeDegree;
        this.beforeDegree = degree;
        this.rotated = true;
        this.setState((state) => {
            let startDegree = Math.max(0, state.startDegree + rot);
            startDegree = Math.min(this.maxStartDegree, startDegree);
            return {
                startDegree,
            }
        });
    }

    /**
     * 指定の座標が基点に対して何度か返す
     * @param x 
     * @param y 
     */
    private calculateDegree(x: number, y: number): number {
        if (this.parentRef.current === null) {
            return 0;
        }
        const py = this.parentRef.current.getBoundingClientRect().y;
        const px = this.parentRef.current.getBoundingClientRect().x;
        const rad = Math.atan2(-(y - py), x - px);
        const theta = rad * (180 / Math.PI);
        return theta;
    }

    /**
     * 自身か自身の配下のguideを返す
     * @param def 
     */
    private getGuide(def: MenuDefine): string | undefined {
        if (def.guide !== undefined) {
            return def.guide;
        }
        // 子孫のガイドを探す
        if (def.children === undefined) {
            return undefined;
        }
        let guide;
        def.children.some((child) => {
            guide = this.getGuide(child);
            return guide !== undefined;
        });
        return guide;
    }
}

export default connect(mapStateToProps)(CircleMenu);
