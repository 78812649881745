import React, { useEffect, useState } from 'react';
import { connect } from "react-redux";
import { RootState } from "../store";
import { KickCommand } from '../types/operation';
import { DispatchProps } from "../types/types";
import * as operationActions from '../store/operation/actions';
import YoutubePlayer from './common/YoutubePlayer';
import styles from './YoutubePopup.module.scss';

type OwnProps = {
    /** 親からもらうprops定義 */
}
type StateProps = OwnProps & {
    /** ストアの内容から生成するprops定義 */
    kickCommand: KickCommand | undefined,
    commandOption: {videoId: string, seek: number} | undefined,
}
const mapStateToProps = (state: RootState, ownProps: OwnProps): StateProps => ({
    ...ownProps,
    kickCommand: state.operationReducer.kickCommand?.command,
    commandOption: state.operationReducer.kickCommand?.option,
});
type Props = StateProps & DispatchProps;

function YoutubePopup(props: Props) {
    const [videoId, setVideoId] = useState(null as null | string);
    const [seek, setSeek] = useState(0);
    const [prevPoint, setPrevPoint] = useState(null as null | {x: number, y: number});
    const [position, setPosition] = useState({x: 240, y: 10});
    const [reserve, setReserve] = useState(null as null | {videoId: string, seek: number});

    const dispatch = props.dispatch;

    useEffect(() => {
        if (props.kickCommand === KickCommand.StartPopupVideo) {
            const newVideoId = props.commandOption?.videoId;
            const newSeek = props.commandOption?.seek;
            dispatch(operationActions.commandKicked());

            console.log('newVideoId', newVideoId, 'current', videoId);

            if (newVideoId !==undefined) {
                if (videoId !== null) {
                    // 他の動画が再生中の場合は、確認表示
                    setReserve({
                        videoId: newVideoId,
                        seek: newSeek===undefined ? 0 : newSeek,
                    });
                }else{
                    setVideoId(newVideoId);
                    setSeek(newSeek===undefined ? 0 : newSeek);

                }
            }
        }
    }, [videoId, dispatch, props.kickCommand, props.commandOption]);

    const onClose = () => {
        setVideoId(null);
    }

    const onMouseDown = (evt: React.MouseEvent) => {
        const x = evt.screenX;
        const y = evt.screenY;
        setPrevPoint({x, y});
    }

    const onTouchStart = (evt: React.TouchEvent) => {
        const x = evt.touches.item(0).screenX;
        const y = evt.touches.item(0).screenY;
        setPrevPoint({x, y});
    }

    const onMouseMove = (evt: React.MouseEvent) => {
        const x = evt.screenX;
        const y = evt.screenY;
        dragging(x, y);
    }

    const onTouchMove = (evt: React.TouchEvent) => {
        const x = evt.touches.item(0).screenX;
        const y = evt.touches.item(0).screenY;
        dragging(x, y);
    }

    const dragging = (x: number, y: number) => {
        if (prevPoint === null) {
            return;
        }
        const dx = x - prevPoint.x;
        const dy = y - prevPoint.y;
        setPosition({
            x: position.x + dx,
            y: position.y + dy,
        });
        setPrevPoint({x, y});
    }

    const stopDrag = () => {
        setPrevPoint(null);
    }

    const style = {
        top: position.y + 'px',
        left: position.x + 'px'
    };

    if(videoId === null) {
        return null;
    }

    const switchNewVideo = () => {
        if (reserve === null) {
            return;
        }
        setVideoId(reserve.videoId);
        setSeek(reserve.seek);
        setReserve(null);
    }

    const switchCancel = () => {
        setReserve(null);
    }

    const confirm = reserve === null ? null : (
        <div className={styles.confirm}>
            新たな動画に切り替えていいですか。
            <div>
                <button className="btn btn-secondary" onClick={switchNewVideo}>はい</button>
                <button className="btn btn-secondary" onClick={switchCancel}>いいえ</button>
            </div>
        </div>
    );
    return (
        <div className={styles.myframe} style={style}
            onMouseDown={onMouseDown} onTouchStart={onTouchStart}
            onMouseMove={onMouseMove} onTouchMove={onTouchMove}
            onMouseUp={stopDrag} onMouseLeave={stopDrag} onTouchEnd={stopDrag}
            >
            <div className={styles.header}>
                <span className={styles.closeBtn} onClick={onClose}>×</span>
            </div>
            {confirm}
            <YoutubePlayer videoId={videoId} seek={seek} isContiueInPopup={false} />
        </div>
    )
}
export default connect(mapStateToProps)(YoutubePopup);
