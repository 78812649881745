import React, { useEffect, useState } from 'react'
import { DispatchProps, GroupInfo, TagInfo } from '../../types/types';
import { RootState } from '../../store';
import { connect } from 'react-redux';
import * as dbAccessor from "../../util/DbAccessor";
import styles from './ManageTagTab.module.scss';

type OwnProps = {
    /** 親からもらうprops定義 */
    change: (tag: TagInfo) => void; // タグ情報について変更された場合のイベントハンドラ
}
type StateProps = OwnProps & {
    /** ストアの内容から生成するprops定義 */
    groups: GroupInfo[];
    tags: { [id: string]: TagInfo };
}
type Props = StateProps & DispatchProps;

const mapStateToProps = (state: RootState, ownProps: OwnProps): StateProps => ({
    ...ownProps,
    groups: Object.values(state.systemReducer.groups),
    tags: state.infoReducer.tags,
});

function ManageTagTab(props: Props) {
    const [editTagMap, setEditTagMap] = useState(props.tags);
    const [tagsInfoNumMap, setTagsInfoNumMap] = useState(undefined as undefined | {[id: string]: number});
    const [delFlgList, setDelFlgList] = useState([] as string[]);

    useEffect(() => {
        setEditTagMap(props.tags);
    }, [props.tags]);

    useEffect(() => {
        // タグ使用情報数を取得
        dbAccessor.getTagsInfoNum()
        .then((result) => {
            setTagsInfoNumMap(result);
        });
    }, [editTagMap]);

    const onChange = (tagId: string, groupId: string) => {
        const newTagInfo = JSON.parse(JSON.stringify(editTagMap[tagId])) as TagInfo;
        if (newTagInfo.authGroups === undefined) {
            newTagInfo.authGroups = [];
        }
        const index = newTagInfo.authGroups.indexOf(groupId);
        if (index === -1) {
            // 未設定の場合
            newTagInfo.authGroups.push(groupId);
        } else {
            // 設定済みの場合、はずす
            newTagInfo.authGroups.splice(index, 1);
        }
        setEditTagMap(
            Object.assign({}, editTagMap, {
                [tagId]: newTagInfo,
            })
        );
        props.change(newTagInfo);
    }

    const onDelFlgChanged = (e: React.ChangeEvent<HTMLInputElement>, tagId: string) => {
        const val = e.target.checked;
        if (val) {
            // 削除対象に追加
            const index = delFlgList.indexOf(tagId);
            if (index === -1) {
                setDelFlgList(delFlgList.concat(tagId));
            }
        } else {
            // 削除対象から除外
            const index = delFlgList.indexOf(tagId);
            if (index !== -1) {
                const newDelFlgList = [] as string[];
                Array.prototype.push.apply(newDelFlgList, delFlgList);
                newDelFlgList.splice(index, 1);
                setDelFlgList(newDelFlgList);
            }
        }
    }

    const onDelExecuteClicked = async() => {
        // 削除実行
        await dbAccessor.removeTag(delFlgList);

        setDelFlgList([]);
    }

    return (
        <div className={styles.tagList}>
            <table>
                <thead>
                    <tr>
                        <th rowSpan={2}>タグ</th>
                        <th colSpan={props.groups.length}>限定参照</th>
                        <th rowSpan={2}>使用情報数</th>
                        <th rowSpan={2}>
                            削除<br/>
                            <button className="btn btn-secondary btn-sm" onClick={onDelExecuteClicked}>実行</button>
                        </th>
                    </tr>
                    <tr>
                        {props.groups.map((group) => {
                            return <th key={group.id}>{group.name}</th>
                        })}
                    </tr>
                </thead>
                <tbody>
                    {Object.values(editTagMap).map((tag) => {
                        let infoNum;
                        if (tagsInfoNumMap === undefined) {
                            infoNum = '取得中'
                        } else {
                            infoNum = tagsInfoNumMap[tag.id] === undefined ? 0 : tagsInfoNumMap[tag.id];
                        }
                        return <tr key={tag.id}>
                            <td>{tag.name}</td>
                            {props.groups.map((group) => {
                                return <td key={group.id}>
                                    <input type="checkbox" checked={tag.authGroups !== undefined && tag.authGroups?.indexOf(group.id) !== -1} onChange={() => onChange(tag.id, group.id)}></input>
                                </td>
                            })}
                            <td>{infoNum}</td>
                            <td><input type="checkbox" checked={delFlgList.indexOf(tag.id)!==-1} onChange={(e)=>{onDelFlgChanged(e, tag.id)}} /></td>
                        </tr>
                    })}
                </tbody>
            </table>
        </div>
    );
}
export default connect(mapStateToProps)(ManageTagTab);
