import React, { useEffect, useState } from "react"
import { connect } from "react-redux";
import { RootState } from "../../store"
import { DispatchProps } from "../../types/types";
import * as operationActions from '../../store/operation/actions';
import { KickCommand } from "../../types/operation";
import * as CommonUtility from '../../util/CommonUtility';

type OwnProps = {
    /** 親からもらうprops定義 */
    videoId: string;
    isContiueInPopup: boolean;  // コンポーネント破棄時にポップアップで再生継続するか
    seek?: number;      // 再生開始位置
}
type StateProps = OwnProps & {
    /** ストアの内容から生成するprops定義 */
}
const mapStateToProps = (state: RootState, ownProps: OwnProps): StateProps => ({
    ...ownProps,
});
type Props = StateProps & DispatchProps;

let componentMaxId = 0;
const playerMap = {} as {[id: string]: any};
function YoutubePlayer(props: Props) {
    const [id] = useState('youtube-player-' + (++componentMaxId));
    const dispatch = props.dispatch;

    useEffect(() => {
        // YoutubeAPIロード

        const loadVideo = (YT: any): Promise<any> => {
            return new Promise<any>((resolve) => {
                const player = playerMap[id];
                if (player !== undefined) {
                    resolve(player);
                    return;
                }
                console.log('loadVideo')
                const myplayer = new YT.Player(id, {
                    // videoId: props.videoId,
                    width: 300,
                    height: 168,
                    playerVars: {'autoplay': 0},
                    events: {
                        'onReady': () => {
                            resolve(myplayer);
                        }
                    }
                });
                playerMap[id] = myplayer;
            });
        };
    
        CommonUtility.loadYoutubeAPI().then((YT) => {
            return loadVideo(YT);
        }).then((player) => {
            // ビデオ設定&再生
            if (props.seek) {
                // 開始位置指定がある場合は、そこから再生開始
                player.loadVideoById(props.videoId, props.seek);
            } else {
                player.cueVideoById(props.videoId);
            }
        })

        return () => {
            const player = playerMap[id];
            if (player === undefined || player.getPlayerState === undefined) {
                return;
            }
            if (!props.isContiueInPopup) {
                return;
            }
            // コンポーネント破棄時、再生中ならポップアップ化
            if (player.getPlayerState() === 1) {
                // 再生中の場合
                dispatch(operationActions.kickCommand(KickCommand.StartPopupVideo, {
                    videoId: props.videoId,
                    seek: player.getCurrentTime(),
                }));
            }
        }
    }, [props.videoId, props.seek, props.isContiueInPopup, id, dispatch]);

    return (
        <div>
            <div id={id} />
        </div>
    )
}
export default connect(mapStateToProps)(YoutubePlayer);
