import React, { useEffect, useMemo, useState } from 'react'
import { connect } from "react-redux";
import MainView from './MainView';
import { DispatchProps, VillageInfo } from '../types/types';
import Auth from '../components/auth/Auth';
import { RootState } from '../store';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import * as systemThunks from '../store/system/thunks';
import { KickCommand } from '../types/operation';
import { useCommandCatch } from '../util/customHooks';

type RouteParam = {
    id: string;
    group?: string;
}
type OwnProps = RouteComponentProps<RouteParam>;

type StateProps = OwnProps & {
    /** ストアの内容から生成するprops定義 */
    signInUserId: string | undefined;
}

type Props = StateProps & DispatchProps;

const mapStateToProps = (state: RootState, ownProps: OwnProps): StateProps => ({
    ...ownProps,
    signInUserId: state.systemReducer.signInUserId,
});

enum Stage {
    GettingVillage, // 村情報取得中
    IllegalVillage, // 村ID不正
    Authing,    // 認証待ち
    Enter,      // 入村
}
/**
 * 村IDによって入る村を切り替える
 *
 * @class Main
 * @extends {Component<MyProps>}
 */
function Main(props: Props) {
    const params = useMemo(() => {
        return props.match.params.id.split('_');
    }, [props.match.params.id]);
    const villageId = useMemo(() => {
        return params[0]
    }, [params]);
    const group = useMemo(() => {
        return params.length > 1 ? params[1] : undefined
    },[params]);

    const [stage, setStage] = useState(Stage.GettingVillage);

    const dispatch = props.dispatch;

    useEffect(() => {
        // 村情報取得開始
        console.log('村情報取得開始')
        dispatch(systemThunks.updateVillageIdThunk(villageId))
        .then((villageInfo: VillageInfo) => {
            console.log('toAuth')

            if (villageInfo.disclosure === 'hidden') {
                setStage(Stage.Authing);
            } else {
                // ゲスト許容している場合は、入村
                setStage(Stage.Enter);
            }
        })
        .catch(() => {
            setStage(Stage.IllegalVillage);
        })
    // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (stage === Stage.Enter) {
            // 村情報、住民情報取得開始
            dispatch(systemThunks.loadSystemInfoThunk());

            // 建物アイコン定義取得
            dispatch(systemThunks.loadIconDefineThunk());
        }
    }, [dispatch, stage]);

    useCommandCatch(KickCommand.ExitVillage, () => {
        // 村を出る
        props.history.push('/');
    });

    switch(stage) {
        case Stage.GettingVillage:
            return (
                <div>
                    村情報取得中...
                </div>
            );

        case Stage.IllegalVillage:
            return (
                <div>
                    指定の村は存在しません。{villageId}
                </div>
            );

        case Stage.Authing:
            // 認証待ち
            return (
                <Auth group={group} complete={()=>{setStage(Stage.Enter)}} />
            );

        case Stage.Enter:
            return (
                <MainView />
            );

    }
}
export default withRouter(connect(mapStateToProps)(Main));
