import { Feature, FeatureType } from "../types/types";
import { RootState } from "../store";
import { FeatureInfo } from "../types/info";

export const storeGetter = (state: RootState) => {
    const userName = (uid: string) => {
        const user = state.systemReducer.users[uid];
        if (user === undefined) {
            return '';
        }
        return user.name;
    };
    const structures = Object.values(state.featureReducer.features).filter((value: Feature) => {
        return value.type === FeatureType.STRUCTURE;
    });
    const topographies = Object.values(state.featureReducer.features).filter((value: Feature) => {
        return value.type === FeatureType.EARTH || value.type === FeatureType.FOREST;
    });

    return {
        feature: (id: string): Feature => {
            return state.featureReducer.features[id];
        },

        structures,

        topographies,

        // 指定の地物の子地物を返す
        children: (parentId: string): Feature[] => {
            return Object.values(state.featureReducer.features).filter((value: Feature) => {
                return value.parent === parentId;
            });
        },

        featurePopupInfo: (id: string): FeatureInfo[] => {
            const infos = state.infoReducer.featurePopupInfoMap[id];
            if (infos === undefined) {
                return [];
            }
            return infos;
        },

        selectedFeature: state.operationReducer.selectFeatureId === undefined ? undefined : state.featureReducer.features[state.operationReducer.selectFeatureId],

        featureCreator: (featureId: string): string | undefined => {
            return state.featureReducer.features[featureId].user;
        },

        featureCreatorName: (featureId: string): string => {
            if (state.featureReducer.features[featureId] === undefined) {
                return '';
            }
            const id = state.featureReducer.features[featureId].user;
            if (id === undefined) {
                return '';
            }
            return userName(id);
        },

        userName,
    }
};
