import React, { useEffect, useMemo, useRef, useState } from 'react'
import { DispatchProps, TagInfo, VillageUserInfo } from '../../../types/types';
import { RootState } from '../../../store';
import { connect } from 'react-redux';
import { storeGetter } from '../../../util/props';
import TagPanel from '../TagPanel';
import StateUtility from '../../../util/StateUtility';
import InfoOutline from './InfoOutline';
import { DragSource, DragSourceMonitor, DragSourceConnector, DragSourceSpec, DropTargetSpec, DropTargetMonitor, DropTargetConnector, DropTarget } from 'react-dnd';
import styles from './InfoPanel.module.scss';
import { FeatureInfo } from '../../../types/info';
import * as CommonUtility from '../../../util/CommonUtility';
import { OverlayTrigger, Overlay, Tooltip, Popover } from 'react-bootstrap';
import { InfoLinkWithStore } from '../InfoLink';

/** 
 * 情報表示パネル
 */

type OwnProps = {
    /** 親からもらうprops定義 */
    info: FeatureInfo;
    onMove: () => void;   // 引っ越しボタン押下時のコールバック先
    onInfo: () => void;   // infoボタン押下時のコールバック先
    onEdit: () => void;   // 編集ボタン押下時のコールバック先
    onNewIdea: () => void;   // ひらめきボタン押下->新規情報作成選択時のコールバック
    switchOrder: (infoIdA: number, infoIdB: number) => void;
    orderChangeCommit: () => void;
    onMounted: (param: any) => void;  // レンダリング完了後のコールバック
}
type StateProps = OwnProps & {
    /** ストアの内容から生成するprops定義 */
    userName: (uid: string) => string;
    tagInfos: TagInfo[];
    userInfo: VillageUserInfo | undefined; // 現在のユーザ情報
}
type DragCollectProps = {
    connectDragSource: any;
    isDragging: boolean;
}
type DropCollectProps = {
    isOver: boolean;
    canDrop: boolean;
    connectDropTarget: any;
}


type Props = StateProps & DispatchProps & DragCollectProps & DropCollectProps;

const mapStateToProps = (state: RootState, ownProps: OwnProps): StateProps => ({
    ...ownProps,
    userName: storeGetter(state).userName,
    tagInfos: StateUtility.idToTagInfo(state)(ownProps.info.tags),
    userInfo: state.systemReducer.users[state.systemReducer.signInUserId!],
});

function InfoPanel(props: Props) {
    const eventDateStr = useMemo((): string => {
        if (props.info.eventStartDate === undefined) {
            return '';
        }
        let result = CommonUtility.convertDateToStr(props.info.eventStartDate);
        if (props.info.eventEndDate === undefined) {
            return result;
        }
        result += ' - ' + CommonUtility.convertDateToStr(props.info.eventEndDate);
        return result;
    }, [props.info.eventStartDate, props.info.eventEndDate]);

    const [showIdeaMenu, setShowIdeaMenu] = useState(false);
    const ideaMenuBtn = useRef(null);

    const myRef = useRef<HTMLDivElement>(null);
    useEffect(() => {
        if (myRef.current === null) {
            return;
        }
        props.onMounted(myRef.current.getBoundingClientRect());
    }, [props]);

    const linkInfos = useMemo(() => {
        return props.info.link_infos.map((infoId, index) => {
            console.log('linkinfos', infoId);
            return <InfoLinkWithStore infoId={infoId} key={'infolink-'+ index} />;
        });
    }, [props.info.link_infos]);

    return props.connectDropTarget(props.connectDragSource(
        <div className={`${styles.card} card`} ref={myRef}>
            <div className={`${styles.Header} card-header`}>
                {props.info.title}
                {props.userInfo !== undefined &&
                    <div className={styles.IconArea}>
                        <OverlayTrigger placement="bottom" overlay={
                            <Tooltip id="tooltip-info-edit">編集</Tooltip>
                        }>
                            <i className="icon-edit" onClick={props.onEdit}></i>
                        </OverlayTrigger>
                        <OverlayTrigger placement="bottom" overlay={
                            showIdeaMenu ? <span/> : <Tooltip id="tooltip-info-idea">リンク</Tooltip>
                        }>
                            <i className="icon-link" ref={ideaMenuBtn} onClick={()=>{setShowIdeaMenu(!showIdeaMenu)}}></i>
                        </OverlayTrigger>
                        <OverlayTrigger placement="bottom" overlay={
                            <Tooltip id="tooltip-info-move">引越し</Tooltip>
                        }>
                            <i className="icon-truck" onClick={props.onMove}></i>
                        </OverlayTrigger>
                        <OverlayTrigger placement="bottom" overlay={
                            <Tooltip id="tooltip-info-info">情報</Tooltip>
                        }>
                            <i className="icon-info" onClick={props.onInfo}></i>
                        </OverlayTrigger>
                        <Overlay target={ideaMenuBtn.current} show={showIdeaMenu} placement="bottom">
                            <Popover id="tooltip-info-idea-menu">
                                <Popover.Title>リンク</Popover.Title>
                                <Popover.Content>
                                    <a className="nav-link" href="# " 
                                        onClick={()=>{setShowIdeaMenu(false); props.onNewIdea();}} >
                                            新規情報作成
                                    </a>
                                    <a className="nav-link disabled" href="# ">この情報のリンクをコピー</a>
                                </Popover.Content>
                            </Popover>
                        </Overlay>
                    </div>
                }
                <div className={styles.tagArea}>
                    <TagPanel tags={props.tagInfos} />
                </div>
            </div>
            <div className={`${styles.Body} card-body`}>
                <InfoOutline info={props.info} />
            </div>
            {(eventDateStr.length > 0 || linkInfos.length > 0) && 
                <div className="card-footer">
                    {eventDateStr}
                    {linkInfos}
                </div>
            }
        </div>
    ));
}

const dragSpec: DragSourceSpec<Props, { id: number }> = {
    canDrag(props: Props) {
        return true;
    },

    beginDrag(props: Props, monitor: DragSourceMonitor, component: any) {
        return { id: props.info.id };
    },
}
const dragCollect = (connect: DragSourceConnector, monitor: DragSourceMonitor): DragCollectProps => ({
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging()
});

const dropSpec: DropTargetSpec<Props> = {
    hover(props: Props, monitor: DropTargetMonitor, component: any) {
        // 位置を一時的に入れ替える
        if (monitor.getItem().id === undefined || props.info.id === monitor.getItem().id) {
            return;
        }
        props.switchOrder(monitor.getItem().id, props.info.id);
    },
    canDrop(props: Props, monitor: DropTargetMonitor) {
        if (monitor.getItem().id === undefined) {
            return false;
        }
        return true;
    },
    drop(props: Props, monitor: DropTargetMonitor, component: any) {
        props.orderChangeCommit();
    }
}
/**
 * Specifies which props to inject into your component.
 */
const dropCollect = (connect: DropTargetConnector, monitor: DropTargetMonitor): DropCollectProps => ({
    // Call this function inside render()
    // to let React DnD handle the drag events:
    connectDropTarget: connect.dropTarget(),
    // You can ask the monitor about the current drag state:
    isOver: monitor.isOver(),
    // isOverCurrent: monitor.isOver({ shallow: true }),
    canDrop: monitor.canDrop(),
    // itemType: monitor.getItemType()
});

export default DropTarget('InfoPanel', dropSpec, dropCollect)(DragSource('InfoPanel', dragSpec, dragCollect)(connect(mapStateToProps)(InfoPanel)));
