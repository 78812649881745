import { FeatureDocItem } from "../../types/feature";
import { ThemeDefine } from "../../types/types";
import { ActionTypes, ReducerType } from "./types";

/**
 * 地物を登録する
 * @param items 登録建物情報
 */
export function registFeatureAction(items: Array<{id: string; item: FeatureDocItem}>): ActionTypes {
    return {
        type: ReducerType.RegistFeature,
        payload: items,
    };
}

/**
 * 地物を削除する
 * @param ids 削除対象の図形ID
 */
export function removeFeatureAction(ids: string[]): ActionTypes {
    return {
        type: ReducerType.RemoveFeature,
        payload: ids,
    };
}

/**
 * 主題図定義を更新する
 * @param themeDefines 更新後の主題図定義情報
 */
export function updateThemeDefinesAction(themeDefines: ThemeDefine[]): ActionTypes {
    return {
        type: ReducerType.UpdateThemeDefine,
        payload: themeDefines,
    };
}
