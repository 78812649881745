import React, { Component } from 'react'
import { DispatchProps } from '../../../types/types';
import { RootState } from '../../../store';
import { connect } from 'react-redux';
import Select, { SelectEvent } from 'ol/interaction/Select';
import { click } from 'ol/events/condition';
import VectorLayer from 'ol/layer/Vector';
import { Map } from 'ol';
import { topographySelectStyleFunction } from '../style';
import OlFeature from 'ol/Feature';
import * as dbAccessor from "../../../util/DbAccessor";
import PromptMessageBox from './PromptMessageBox';
import * as operationThunks from '../../../store/operation/thunks';
import { DialogResult } from '../../../types/operation';

// 編集状況
enum EditStage {
    SELECTING_FEATURE,
}

type OwnProps = {
    /** 親からもらうprops定義 */
    map: Map;
    topographyLayers: VectorLayer[];
    close: () => void;  // 編集完了時のコールバック
}
type StateProps = OwnProps & {
    /** ストアの内容から生成するprops定義 */
}
type Props = StateProps & DispatchProps;

const mapStateToProps = (state: RootState, ownProps: OwnProps): StateProps => ({
    ...ownProps,
});

type State = {
    editStage: EditStage,
    selectedFeature: OlFeature | null;
    promptMessage: string;
};

/**
 * 地形編集用コントロールクラス
 */
class RemoveTopographyController extends Component<Props, State> {
    private topographySelect: Select;

    constructor(props: Props) {
        super(props);
        this.state = {
            editStage: EditStage.SELECTING_FEATURE,
            selectedFeature: null,
            promptMessage:'',
        };

        this.topographySelect = new Select({
            condition: click,
            layers: props.topographyLayers,
            style: topographySelectStyleFunction,
        });
        this.topographySelect.on('select', (evt: SelectEvent) => {
            let selectedFeature;
            if (evt.selected.length === 0) {
                selectedFeature = null;
            } else {
                selectedFeature = evt.selected[0];
            }
            this.setState({
                selectedFeature,
            });
        });
    }

    render() {
        let option = null;
        switch (this.state.editStage) {
            case EditStage.SELECTING_FEATURE:
                option = <PromptMessageBox message={this.state.promptMessage} ok={this.onRemoveOkClicked.bind(this)} cancel={this.onSelectEditTargetCancelClicked.bind(this)} okdisabled={this.state.selectedFeature === null} />
                break;
        }
        return (
            option
        );
    }

    componentDidUpdate(prevProps: Props, prevState: State) {
        if (prevState.editStage !== this.state.editStage) {
            this.onEditStageChanged(prevState.editStage);
        }
    }

    componentDidMount() {
        this.onEditStageChanged();
    }

    componentWillUnmount() {
        this.props.map.removeInteraction(this.topographySelect);
    }

    private onEditStageChanged(prevStage?: EditStage) {
        switch (prevStage) {
            case EditStage.SELECTING_FEATURE:
                this.props.map.removeInteraction(this.topographySelect);
                break;
        }
        switch (this.state.editStage) {
            case EditStage.SELECTING_FEATURE:
                // 地形を選択可能にする。
                this.props.map.addInteraction(this.topographySelect);
                this.setState({
                    promptMessage: '削除対象の地形を選択して、OKボタンを押下してください。',
                });
                break;
        }
    }

    /**
     * 対象選択キャンセル
     */
    private onSelectEditTargetCancelClicked() {
        // 終了
        this.props.close();
    }

    /**
     * 削除確定時
     */
    private async onRemoveOkClicked() {
        const result = await this.props.dispatch(operationThunks.confirmThunk({
            message: '本当に削除してよろしいですか。'
        })) as DialogResult;
        if (result === DialogResult.CANCEL) {
            return;
        }

        // DB更新
        const id = this.state.selectedFeature?.getId() as string;
        dbAccessor.removeFeature(id);

        // 終了
        this.props.close();
    }
}
export default connect(mapStateToProps)(RemoveTopographyController);
