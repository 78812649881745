import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import InfoViewDialog from './InfoViewDialog';
import styles from './InfoOutline.module.scss';
import { FeatureInfo } from '../../../types/info';
import { useInfoFormat } from '../../../hooks/InfoFormat';

/**
 * 右パネルに表示する情報概要
 */

type Props = {
    info: FeatureInfo;
};

export default function InfoOutline(props: Props) {
    const myRef = useRef<HTMLDivElement>(null);
    const descRef = useRef<HTMLDivElement>(null);
    const [showDetailDialog, setShowDetailDialog] = useState(false);
    const [isOver, setIsOver] = useState(false);

    const showDetail = useCallback(() => {
        setShowDetailDialog(true);
    }, []);

    const hideDetail = useCallback(() => {
        setShowDetailDialog(false);
    }, []);

    const imgSrc = useMemo((): string => {
        return props.info.imageThumb;
    },[props.info.imageThumb]);

    const shapedDescription = useInfoFormat(props.info.contents, 'view');

    useEffect(() => {
        setTimeout(() => {
            // 文字が収まりきっているかチェック
            if (myRef.current === null || descRef.current === null) {
                return;
            }
            const myHeight = myRef.current.getBoundingClientRect().height;
            const descHeight = descRef.current.getBoundingClientRect().height;
            setIsOver(myHeight < descHeight);
        }, 100);
    }, [props.info, shapedDescription]);

    return (
        <React.Fragment>
            {showDetailDialog && 
                <InfoViewDialog info={props.info} close={hideDetail} />
            }
            <div className={styles.Outline} ref={myRef}>
                {imgSrc.length > 0 &&
                <figure>
                    <img src={imgSrc} alt="infoimage" />
                </figure>
                }
                <div className={styles.description} ref={descRef}>
                    {shapedDescription}
                </div>
                {isOver &&
                    <div className={styles.ShowDetail}>
                        <a href="# " onClick={showDetail}>▼</a>
                    </div>
                }
            </div>
        </React.Fragment>
    );
}
