import React, { Component, ChangeEvent } from "react";
import { FeatureType } from "../types/types";
import { Modal } from 'react-bootstrap';

type MyProps = {
    type: FeatureType;
    name: string;
    onOk: (name: string) => void;
    onCancel: () => void;
};
type MyState = {
    name: string;
}

/**
 * 属性入力コンポーネント
 */
export default class AttrEdit extends Component<MyProps, MyState> {
    constructor(props: MyProps) {
        super(props);
        this.state = {
            name: props.name,
        };
    }
    render() {
        return (
            <Modal show={true}>
                <Modal.Header>
                    {this.name}情報
                </Modal.Header>
                <Modal.Body>
                    <div className="form-group row">
                        <label className="col-sm-2 col-form-label">{this.name}名</label>
                        <div className="col-sm-10">
                            <input type="text" name={this.inputName} className="form-control" value={this.state.name} onChange={this.handleChange.bind(this)}></input>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-primary" onClick={this.onOkClicked.bind(this)}>OK</button>
                    <button className="btn btn-secondary" onClick={this.onCancelClicked.bind(this)}>Cancel</button>
                </Modal.Footer>
            </Modal>
        );
    }

    get name(): string {
        switch (this.props.type) {
            case FeatureType.STRUCTURE:
                return '建物';
            case FeatureType.EARTH:
                return '島';
            default:
                return '';
        }
    }

    get inputName(): string {
        switch (this.props.type) {
            case FeatureType.STRUCTURE:
                return 'building';
            case FeatureType.EARTH:
                return 'earth';
            default:
                return '';
        }
    }

    onOkClicked() {
        this.props.onOk(this.state.name);
    }

    onCancelClicked() {
        this.props.onCancel();
    }

    handleChange(e: ChangeEvent<HTMLInputElement>) {
        this.setState({
            name: e.target.value,
        });
    }
}