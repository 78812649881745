// 主題図設定パネル
import React, { Component } from 'react'
import { DispatchProps, TagInfo, ThemeDefine, ThemeKind } from '../../types/types';
import { RootState } from '../../store';
import { connect } from 'react-redux';
import styles from './ThemeSettingPanel.module.scss';
import StateUtility from '../../util/StateUtility';
import ThemeTagSelectDialog from './setting/ThemeTagSelectDialog';
import '../../styles/_common.scss';
import TagBadge from '../common/TagBadge';
import * as featureThunks from '../../store/feature/thunks';

type OwnProps = {
    /** 親からもらうprops定義 */
}
type StateProps = OwnProps & {
    /** ストアの内容から生成するprops定義 */
    tagMap: { [id: string]: TagInfo };
    tags: TagInfo[];
    tagThemeDefine: ThemeDefine[];
    specialUser: boolean;
}
type Props = StateProps & DispatchProps;

const mapStateToProps = (state: RootState, ownProps: OwnProps): StateProps => ({
    ...ownProps,
    tagMap: state.infoReducer.tags,
    tags: StateUtility.themeTags(state),
    tagThemeDefine: state.featureReducer.themeDefines.filter((def: ThemeDefine) => {
        return def.kind === ThemeKind.Tag;
    }),
    specialUser: state.systemReducer.users[state.systemReducer.signInUserId as string]?.special === true ? true : false,
});

type State = {
    showTagSelectDialog: boolean;
    selectedTag: TagInfo | undefined;
    waiting: boolean;   // 強調表示処理中の場合、true
};

class ThemeSettingPanel extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        let selectedTag = undefined;
        if (props.tagThemeDefine.length > 0) {
            const tagId = props.tagThemeDefine[0].value;
            selectedTag = props.tagMap[tagId];
        }

        this.state = {
            showTagSelectDialog: false,
            selectedTag,
            waiting: false,
        };
    }

    render() {
        const noselectTag = {
            id: 'noselect',
            name: '指定なし',
            color: '#aaaaff',
        } as TagInfo;

        return (
            <div className={styles.ThemeSettingPanel}>
                {this.state.showTagSelectDialog &&
                    <ThemeTagSelectDialog close={this.onDialogClosed.bind(this)} />
                }
                {this.state.waiting &&
                    <div className={`${styles.spinnerArea} spinner-border text-secondary`} role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                }
                <h6>タグ別強調表示</h6>
                {this.props.specialUser &&
                    <a href="# " className="text-secondary" onClick={this.onShowTagSelectDialog.bind(this)}>
                        <i className="icon-cog"></i>
                    </a>
                }
                <div className={styles.ConditionItemArea}>
                    <TagBadge tag={noselectTag} outline={this.state.selectedTag !== undefined} click={this.onThemeTagSelected.bind(this, undefined)} />
                    {this.props.tags.map((tag: TagInfo) => {
                        const selected = this.state.selectedTag?.id === tag.id;
                        return (
                            <TagBadge tag={tag} key={tag.id} outline={!selected} click={this.onThemeTagSelected.bind(this, tag)} />
                        )
                    })}
                </div>
            </div>
        );
    }

    componentDidUpdate(prevProps: Props) {
        if (JSON.stringify(prevProps.tags) !== JSON.stringify(this.props.tags)) {
            this.onTagsDefinedChanged();
        }
    }

    private onTagsDefinedChanged() {
        // 登録されているタグ情報が変化した場合
        if (this.state.selectedTag !== undefined) {
            const hit = this.props.tags.find((tag) => {
                return tag.id === this.state.selectedTag?.id;
            });
            if (hit !== undefined) {
                // 選択されているタグに変化がなければ、そのまま
                return;
            }
        }
    }

    private async onThemeTagSelected(tag: TagInfo | undefined) {
        this.setState({
            selectedTag: tag,
        });
        // スピナー表示
        this.setState({
            waiting: true,
        });
        await this.props.dispatch(featureThunks.updateThemeTagThunk(tag))
        this.setState({
            waiting: false,
        });
    }

    private onShowTagSelectDialog() {
        this.setState({
            showTagSelectDialog: true,
        });
    }

    private onDialogClosed() {
        this.setState({
            showTagSelectDialog: false,
        });
    }

}
export default connect(mapStateToProps)(ThemeSettingPanel);
