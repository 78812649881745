import React, { useCallback, useEffect, useMemo, useState } from 'react';
import OlFeature from 'ol/Feature';
import { FeatureInfo } from '../../types/info';
import { Map, Overlay } from 'ol';
import Select from 'ol/interaction/Select';
import { useDispatch, useSelector } from 'react-redux';
import * as operationActions from '../../store/operation/actions';
import Popup from './Popup';
import { RootState } from '../../store';
import VectorSource from 'ol/source/Vector';
import { MapMode } from '../../types/types';
import { usePrevious } from '../../util/customHooks';

type Props = {
    /** 親からもらうprops定義 */
    map: Map | undefined;
    structureSource: VectorSource;
    select: Select | undefined;
}

// ポップアップを表示する建物情報
type PopupFeatureInfo = {
    olFeature: OlFeature,
    info: FeatureInfo,
    imageUrl: string;
}

export default function PopupCreator(props: Props) {
    const [popupInfoMap, setPopupInfoMap] = useState({} as { [infoId: string]: PopupFeatureInfo});   // ポップアップ表示する情報
    const prevPopupInfoMap = usePrevious(popupInfoMap);
    const [popupRefMap] = useState({} as { [id: string]: HTMLDivElement });
    const [popupOverlayMap] = useState({} as { [id: string]: Overlay });

    const themeDefine = useSelector((state: RootState) => state.featureReducer.themeDefines);
    const featurePopupInfoMap = useSelector((state: RootState) => state.infoReducer.featurePopupInfoMap);
    const selectedFeatureId = useSelector((state: RootState) => state.operationReducer.selectFeatureId);
    const mapMode = useSelector((state: RootState) => state.operationReducer.mode);
    const features = useSelector((state: RootState) => state.featureReducer.features);

    const dispatch = useDispatch();

    useEffect(() => {
        const result = {} as {[infoId: string]: PopupFeatureInfo};
        props.structureSource.getFeatures().forEach((olFeature, index) => {
            let infos = featurePopupInfoMap[olFeature.getId() as string];
            if (infos === undefined) {
                return;
            }
            const featureId = olFeature.getId() as string;
            if (themeDefine.length > 0) {
                // 主題図設定されている場合は、さらに絞り込む
                infos = infos.filter((info) => {
                    // 主題図設定対象となる情報かどうかをチェック
                    return themeDefine.some((def) => {
                        const infoIds = def.featureInfoMap[featureId];
                        if (infoIds === undefined) {
                            return false;
                        }
                        return infoIds.indexOf(info.id) !== -1;
                    });
                });
            }
            if (infos.length > 0) {
                result[infos[0].id] = {
                    olFeature,
                    info: infos[0],
                    imageUrl: infos[0].imageThumb,
                };
            }
        });
        setPopupInfoMap(result);
    }, [props.structureSource, featurePopupInfoMap, themeDefine, features]);

    /**
     * 看板情報に変更があった場合
     * @param prevPopupInfoMap 
     */
     useEffect(() => {
        if (props.map === undefined) {
            return;
        }

        // オーバレイを配置する
        Object.keys(popupInfoMap).forEach((infoId: string) => {
            const prevPopupInfo = prevPopupInfoMap[infoId];
            const popupInfo = popupInfoMap[infoId];
            if (prevPopupInfo === undefined) {
                // 追加
                const element = popupRefMap[infoId];
                if (element === undefined || element === null) {
                    return;
                }

                const overlay = new Overlay({
                    id: 'popup' + infoId,
                    element,
                    autoPan: false,
                    autoPanAnimation: {
                        duration: 250
                    },
                    stopEvent:false,
                });
                const coord = popupInfo.olFeature.getGeometry().getExtent();
                overlay.setPosition([coord[0], coord[1] + 20]);

                props.map?.addOverlay(overlay);
                popupOverlayMap[infoId] = overlay;
            }
        });

        Object.keys(prevPopupInfoMap).forEach((infoId: string) => {
            const popupInfo = popupInfoMap[infoId];
            if (popupInfo === undefined) {
                // 削除
                const overlay = popupOverlayMap[infoId];
                props.map?.removeOverlay(overlay);
            }
        });
    }, [popupInfoMap, prevPopupInfoMap, props.map, popupRefMap, popupOverlayMap]);
    
    // 選択アイテム変更時
    useEffect(() => {
        if (mapMode !== MapMode.NORMAL || selectedFeatureId === undefined) {
            return;
        }
        // 現在のポップアップActiveを解除する
        props.map?.getOverlays().forEach((overlay) => {
            const overlayElement = overlay.getElement().parentElement;
            if (overlayElement !== null) {
                overlayElement.classList.remove('active');
            }
        });
        // ポップアップが存在したら、前面に表示する
        const infos = featurePopupInfoMap[selectedFeatureId];
        if (infos === undefined) {
            return;
        }
        let overlay: Overlay | undefined | null;
        infos.some((info) => {
            overlay = props.map?.getOverlayById('popup' + info.id);
            if (overlay !== undefined && overlay !== null) {
                return true;
            }
            return false;
        });
        if (overlay !== undefined && overlay !== null) {
            const overlayElement = overlay.getElement().parentElement;
            if (overlayElement !== null) {
                overlayElement.classList.add('active');
            }
        }
    }, [selectedFeatureId, mapMode, featurePopupInfoMap, props.map]);


    const onPopupClose = useCallback((infoId: number) => {
        const overlay = props.map?.getOverlayById('popup' + infoId);
        if (overlay !== undefined) {
            overlay.setPosition(undefined);
        }
    }, [props.map]);

    const onPopupCllick = useCallback((info: PopupFeatureInfo) => {
        // 当該建物を選択状態にする
        props.select?.getFeatures().clear();
        props.select?.getFeatures().push(info.olFeature);
        dispatch(operationActions.selectFeatureAction(info.olFeature.getId() as string));
    }, [props.select, dispatch]);


    // 看板
    const popupDiv = useMemo(() => {
        if(Object.values(popupInfoMap).length === 0){
            return null;
        }
        return  (
            <React.Fragment>
            {
                Object.values(popupInfoMap).map((info) => {
                    return <div key={info.info.id} >
                        <div ref={ref => popupRefMap[info.info.id] = ref as HTMLDivElement}>
                            <Popup imageUrl={info.imageUrl} click={()=>onPopupCllick(info)} close={()=>onPopupClose(info.info.id)} />
                        </div>
                    </div>
                })
            }
            </React.Fragment>
        )

    }, [popupInfoMap, popupRefMap, onPopupCllick, onPopupClose]);

    return popupDiv;
}