import { VillageDocItem } from "../../types/feature";
import { GroupInfo, VillageUserInfo } from "../../types/types";
import { ActionTypes, State, ReducerType, RegistVillageInfoAction, SingInAction, RegistUserAction, RegistGroupAction, RemoveGroupAction, LoadIconDefineAction } from "./types";
import { StructureImageDefine } from '../../types/types';

const initialState = {
    village: { name: '' },
    signInUserId: undefined,
    users: {},
    groups: {},
    iconDefine: [] as StructureImageDefine[],
} as State;

export const reducer = (state = initialState, action: ActionTypes): State => {
    switch(action.type) {
        case ReducerType.RegistVillageInfo:
            return registVillageInfo(state, (action as RegistVillageInfoAction).payload);
        case ReducerType.SignIn:
            return signInReducer(state, (action as SingInAction).payload);
        case ReducerType.RegistUserInfo:
            return registUserReducer(state, (action as RegistUserAction).payload);
        case ReducerType.RegistGroupInfo:
            return registGroupReducer(state, (action as RegistGroupAction).payload);
        case ReducerType.RemoveGroupInfo:
            return removeGroupReducer(state, (action as RemoveGroupAction).payload);
        case ReducerType.SetIconDefine:
            return setIconDefineReducer(state, (action as LoadIconDefineAction).payload);
        default:
            return state;
    }
}

function registVillageInfo(state: State, payload: VillageDocItem) {
    return Object.assign({}, state, {
        village: payload,
    });
}
function signInReducer(state: State, payload: string | undefined) {
    return Object.assign({}, state, {
        signInUserId: payload,
    });
}
function registUserReducer(state: State, payload: VillageUserInfo) {
    return Object.assign({}, state, {
        users: Object.assign({}, state.users, {
            [payload.id]: payload,
        })
    });
}
function registGroupReducer(state: State, payload: GroupInfo) {
    return Object.assign({}, state, {
        groups: Object.assign({}, state.groups, {
            [payload.id]: payload
        })
    });
}

function removeGroupReducer(state: State, payload: string) {
    const groups = Object.assign({}, state.groups);
    delete groups[payload];
    return Object.assign({}, state, {
        groups,
    });
}

function setIconDefineReducer(state: State, payload: StructureImageDefine[]) {
    return Object.assign({}, state, {
        iconDefine: payload,
    });
}