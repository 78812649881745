import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { DispatchProps, StructureImageDefine } from '../../../types/types';
import { Modal, Button, Tabs, Tab } from 'react-bootstrap';
import styles from './SelectStructureDialog.module.scss';
import IconRegistController, { Stage } from './IconRegistController';
import { RootState } from '../../../store';
import { connect } from 'react-redux';

type OwnProps = {
    /** 親からもらうprops定義 */
    currentIconId?: string;         // 現在の画像ID
    cancel?: () => void;    // Cancel押下時のコールバック
    ok?: (selectIcon: StructureImageDefine) => void;        // OK押下時のコールバック
}
type StateProps = OwnProps & {
    /** ストアの内容から生成するprops定義 */
    iconDefine: StructureImageDefine[],
}
type Props = StateProps & DispatchProps;

const mapStateToProps = (state: RootState, ownProps: OwnProps): StateProps => ({
    ...ownProps,
    iconDefine: state.systemReducer.iconDefine,
});

/**
 * 建物選択ダイアログ
 */
function SelectStructureDialog(props: Props) {
    const [show, setShow] = useState(true);
    const systemIconDefine = useMemo(() => {
        return props.iconDefine.filter(def => !def.original);
    }, [props.iconDefine]);
    const originalIconDefine = useMemo(() => {
        return props.iconDefine.filter(def => def.original);
    }, [props.iconDefine]);
    const [selectedDefine, setSelectedDefine] = useState(null as StructureImageDefine | null);

    useEffect(() => {
        if (props.currentIconId !== undefined) {
            let hit = props.iconDefine.find((def) => {
                return props.currentIconId === def.id;
            });
            if (hit !== undefined) {
                setSelectedDefine(hit);
            }
        }
    }, [props.currentIconId, props.iconDefine]);

    const [iconRegisterStage, setIconRegisterStage] = useState(Stage.NORMAL);

    const title = useMemo((): string => {
        if (props.currentIconId !== undefined) {
            return '改装';
        }
        return '建物選択';
    }, [props.currentIconId]);

    const onIconRegistStageChanged = useCallback((stage: Stage) => {
        setIconRegisterStage(stage);
    }, []);

    const okDisable = useMemo((): boolean => {
        if (selectedDefine === null) {
            return true;
        }
        if (props.currentIconId === selectedDefine.id) {
            return true;
        }
        return false;
    }, [selectedDefine, props.currentIconId]);

    const onSelect = useCallback((def: StructureImageDefine) => {
        if (iconRegisterStage) {
            // アイコン登録, 変更中は選択できない
            return;
        }
        setSelectedDefine(def);
    }, [iconRegisterStage]);

    const onCancel = useCallback(() => {
        if (props.cancel) {
            props.cancel();
        }
        setShow(false);
    }, [props]);

    const onOk = useCallback(() => {
        if (props.ok) {
            props.ok(selectedDefine as StructureImageDefine);
        }
        setShow(false);
    }, [selectedDefine, props]);

    const imageListCreateFunc = useCallback((imageDefine: StructureImageDefine[]) => {
        const imageList = imageDefine.map((def: StructureImageDefine) => {
            let className = styles.listGroupItem + " list-group-item list-group-item-action";
            if (def.imagePath === selectedDefine?.imagePath) {
                className += " active " + styles.active;
            }
            return <li className={className} key={def.imagePath}
                onClick={()=>onSelect(def)}>
                <img src={def.imagePath} alt={def.name} />
            </li>
        });
        return (
            <ul className={styles.listGroup}>
                {imageList}
            </ul>
        );
    }, [selectedDefine, onSelect]);

    const systemImageList = imageListCreateFunc(systemIconDefine);

    const originalImageList = useMemo(() => {
        return imageListCreateFunc(originalIconDefine);
    }, [originalIconDefine, imageListCreateFunc]);

    const originalIconController = (
        <div className={styles.ControllerArea}>
            <IconRegistController selectDefine={selectedDefine} onStageChanged={onIconRegistStageChanged} />
        </div>
    );

    return (
        <Modal show={show} className={styles.SelectStructureDialog}>
            <Modal.Header>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div>建物を選択してください.</div>
                <Tabs defaultActiveKey="system">
                    <Tab eventKey="system" title="システム">
                        {systemImageList}
                    </Tab>
                    <Tab eventKey="original" title="村人特製">
                        {originalImageList}
                        {originalIconController}
                        {iconRegisterStage === Stage.UPDATING &&
                            <div className={`spinner-border text-secondary`} role="status" />
                    }
                    </Tab>
                </Tabs>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" disabled={iconRegisterStage!==Stage.NORMAL} onClick={onCancel}>
                        Cancel
                </Button>
                <Button variant="primary" onClick={onOk} disabled={okDisable||iconRegisterStage!==Stage.NORMAL}>
                        OK
                </Button>
            </Modal.Footer>
        </Modal>
    );
}
export default connect(mapStateToProps)(SelectStructureDialog);
