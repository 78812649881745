import React, { useEffect, useState } from 'react';
import { DatePickerCalendar } from 'react-nice-dates';
import { enGB } from 'date-fns/locale';
import 'react-nice-dates/build/style.css';
import * as dbAccessor from "../../util/DbAccessor";
import * as CommonUtility from '../../util/CommonUtility';
import styles from './CalendarControlPanel.module.scss';
import { ThemeKind } from '../../types/types';
import { useDispatch } from 'react-redux';
import * as featureActions from '../../store/feature/actions';
import { KickCommand } from '../../types/operation';
import { useCommandCatch } from '../../util/customHooks';

type Props = {
    /** 親からもらうprops定義 */
}

/**
 * カレンダー主題図パネル
 * @param props 
 */
export default function CalendarControlPanel(props: Props) {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);
    const [date, setDate] = useState(undefined as Date | undefined);
    const [eventMap, setEventMap] = useState({} as {[date_str: string]: Array<{infoId: number, featureId: string}>});

    const modifiers = {
        disabled: (date: Date) => {
            const dateStr = CommonUtility.convertDateToDateStr(date);
            return eventMap[dateStr] === undefined;
        },
        highlight: (date: Date) => {
            const dateStr = CommonUtility.convertDateToDateStr(date);
            return eventMap[dateStr] !== undefined;
        }
    };
    const modifiersClassNames = {
        disabled: styles.disabled,
        highlight: styles.highlight,
    };

    const loadEventFunc = () => {
        dbAccessor.getEventInfo().then((result) => {
            setEventMap(result);
            setLoading(false);
        });
    };

    // コンポーネント初期化時
    useEffect(() => {
        setLoading(true);
        loadEventFunc();
        const timeH = setInterval(loadEventFunc, 30000);

        return () => {
            clearInterval(timeH);
        }
    }, []);

    // props変更時
    useCommandCatch(KickCommand.RefreshInfo, () => {
        loadEventFunc();
    });

    useEffect(() => {
        if (date === undefined) {
            return;
        }
        // 日付でフィルタ
        const dateStr = CommonUtility.convertDateToDateStr(date);
        const featureInfoMap = {} as {[featureId: string]: number[]};
        eventMap[dateStr].forEach((info) => {
            if(featureInfoMap[info.featureId] === undefined) {
                featureInfoMap[info.featureId] = [];
            }
            featureInfoMap[info.featureId].push(info.infoId);
        });
        dispatch(featureActions.updateThemeDefinesAction([
            {
                kind: ThemeKind.Calendar,
                value: dateStr,
                color: 'orange',
                featureInfoMap,
            }
        ]));
    }, [date, eventMap, dispatch]);

    return (
        <React.Fragment>
            <DatePickerCalendar weekdayFormat="EEEEEE" 
                                        date={date} 
                                        onDateChange={(d)=>{if(d!==null) setDate(d)}} 
                                        locale={enGB}
                                        modifiers={modifiers}
                                        modifiersClassNames={modifiersClassNames} />
            {loading &&
                <div className={`${styles.Spinner} spinner-border text-light`} role="status">
                    <span className="sr-only">Loading...</span>
                </div>
            }
        </React.Fragment>
    );
}
