import { TagInfo } from "../../types/types";
import { FeatureInfo, FeatureInfoContainDel } from '../../types/info';

export interface State {
    featurePopupInfoMap: { [id: string]: FeatureInfo[] };
    tags: { [id: string]: TagInfo };
}

export enum ReducerType {
    // 情報関連
    AddFeaturePopupInfo = 'AddFeaturePopupInfo',
    UpdateFeaturePopupInfo = 'UpdateFeaturePopupInfo',
    RemoveFeaturePopupInfo = 'RemoveFeaturePopupInfo',
    FlushFeaturePopupInfoDiff = 'FlushFeaturePopupInfoDiff',

    // タグ関連
    AddTagInfo = 'AddTagInfo',
    UpdateTagInfo = 'UpdateTagInfo',
    RemoveTagInfo = 'RemoveTagInfo',
}

export interface AddFeaturePopupInfoAction {
    type: ReducerType;
    payload: FeatureInfo;
}

export interface UpdateFeaturePopupInfoAction {
    type: ReducerType;
    payload: FeatureInfo;
}

export interface RemoveFeaturePopupInfoAction {
    type: ReducerType;
    payload: {
        featureId: string;  // 図形ID
        infoId: number;     // 情報ID
    };
}

export interface FlushFeaturePopupInfoDiffAction {
    type: ReducerType;
    /** {FeatureInfoContainDel} 差分として受け取った情報 */
    payload: FeatureInfoContainDel;
}

export interface UpdateTagInfoAction {
    type: ReducerType;
    payload: TagInfo;
}

export interface RemoveTagInfoAction {
    type: ReducerType;
    /** {string} 削除対象のタグID */
    payload: string;
}
export type ActionTypes = AddFeaturePopupInfoAction | UpdateFeaturePopupInfoAction | RemoveFeaturePopupInfoAction | FlushFeaturePopupInfoDiffAction
                                | UpdateTagInfoAction | RemoveTagInfoAction;
