import React, { Component } from 'react';
import { DispatchProps, TagInfo } from '../../../types/types';
import { RootState } from '../../../store';
import { connect } from 'react-redux';
import { Modal } from 'react-bootstrap';
import * as dbAccessor from "../../../util/DbAccessor";
import "react-toggle/style.css";
import '../../../styles/_common.scss';
import StateUtility from '../../../util/StateUtility';
import TagListItem from './TagListItem';

type OwnProps = {
    /** 親からもらうprops定義 */
    close?: () => void;
}
type StateProps = OwnProps & {
    /** ストアの内容から生成するprops定義 */
    tags: { [id: string]: TagInfo };
}
type Props = StateProps & DispatchProps;

const mapStateToProps = (state: RootState, ownProps: OwnProps): StateProps => ({
    ...ownProps,
    tags: state.infoReducer.tags,
});

type State = {
    workingTags: { [id: string]: TagInfo };     // 編集中のタグ情報
    sortList: string[];     // ソート順（ID格納）
    statusMessage: string;
}

class ThemeTagSelectDialog extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            workingTags: JSON.parse(JSON.stringify(props.tags)),
            sortList: this.createSortList(props.tags),
            statusMessage: '',
        }
    }

    render() {
        const sortedTags = this.state.sortList.map((id: string) => {
            return this.state.workingTags[id];
        });
        return (
            <Modal show={true} onHide={this.onClose.bind(this)} scrollable>
                <Modal.Header closeButton>
                    <Modal.Title>タグ設定</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {Object.values(this.props.tags).length===0 ?
                        <p>タグがまだ生成されていません。情報編集画面でタグを生成してください。</p>    
                        :
                        <React.Fragment>
                            <div className="list-group">
                                {sortedTags.map((tag) => {
                                    return (
                                        <TagListItem key={tag.id} tag={tag} 
                                            onToggleClicked={(event) => this.onToggleClicked(tag, event)}
                                            switchOrder={this.temporaryOrderChanged.bind(this)}
                                            orderChangeCommit={this.orderCommit.bind(this)}
                                            />
                                    );
                                })}
                            </div>
                        </React.Fragment>
                    }
                </Modal.Body>
                <Modal.Footer>
                    <p>{this.state.statusMessage}</p>
                    <button className="btn btn-primary" onClick={this.onSave.bind(this)} disabled={this.okDisable}>OK</button>
                    <button className="btn btn-secondary" onClick={this.onClose.bind(this)}>Cancel</button>
                </Modal.Footer>
            </Modal>
        );
    }

    componentDidUpdate(prevProps: Props, prevState: State) {
        if (JSON.stringify(prevProps.tags) !== JSON.stringify(this.props.tags)) {
            this.setState((state) => {
                const temp = JSON.parse(JSON.stringify(this.props.tags));
                return {
                    workingTags: Object.assign({}, temp, state.workingTags),
                };
            });
        }
        if (prevState.workingTags !== this.state.workingTags) {
            this.setState({
                sortList: this.createSortList(this.state.workingTags),
            });
        }
    }

    private createSortList(tagMap: {[id:string]: TagInfo}): string[] {
        return StateUtility.sortedTagInfo(Object.values(tagMap)).map((tag => {
            return tag.id;
        }));
    }

    private onClose() {
        this.props.close?.apply(this);
    }

    private onToggleClicked(tag: TagInfo, event: React.ChangeEvent<HTMLInputElement>) {
        const val = event.target.checked;
        const newTag = Object.assign({}, tag, {
            useTheme: val,
        });
        this.setState((state) => {
            return {
                workingTags: Object.assign({}, state.workingTags, {
                    [tag.id]: newTag,
                })
            }
        })
    }

    private async onSave() {
        this.setState({
            statusMessage: '保存中...',
        });
        // 変更のあったタグを抽出して更新
        await dbAccessor.updateTags(this.editedTags);
        this.setState({
            statusMessage: '',
        });
        this.onClose();
    }

    /**
     * 変更のあったタグ
     */
    private get editedTags(): TagInfo[] {
        return Object.values(this.state.workingTags).filter((tag) => {
            const prevTag = this.props.tags[tag.id];
            return JSON.stringify(prevTag) !== JSON.stringify(tag);
        });
    }

    private get okDisable(): boolean {
        return this.editedTags.length === 0;
    }

    private temporaryOrderChanged(aId: string, bId: string) {
        const aIndex = this.state.sortList.indexOf(aId);
        const bIndex = this.state.sortList.indexOf(bId);
        const newSortList = this.state.sortList.concat();
        newSortList.splice(aIndex, 1, bId);
        newSortList.splice(bIndex, 1, aId);
        this.setState({
            sortList: newSortList,
        })
    }

    private orderCommit() {
        const newTagMap = Object.assign({}, this.state.workingTags);

        this.state.sortList.forEach((id, index) => {
            newTagMap[id].order = index;
        });
        this.setState({
            workingTags: newTagMap,
        });
    }
}
export default connect(mapStateToProps)(ThemeTagSelectDialog);
