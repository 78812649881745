import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import { RootState } from '../../store';
import { DispatchProps } from '../../types/types';
import styles from './AuthNotification.module.scss';
import * as firebase from 'firebase/app';
import 'firebase/messaging';
import * as dbAccessor from '../../util/DbAccessor';
import FirebaseConfig from '../../config/firebase-config.json';

type OwnProps = {
    /** 親からもらうprops定義 */
}
type StateProps = OwnProps & {
    /** ストアの内容から生成するprops定義 */
    signInUserId: string | undefined;
}
type Props = StateProps & DispatchProps;

const mapStateToProps = (state: RootState, ownProps: OwnProps): StateProps => ({
    ...ownProps,
    signInUserId: state.systemReducer.signInUserId,
});

let gettedToken = false;
/**
 * 通知許可処理
 * @param props 
 * @returns 
 */
function AuthNotification(props: Props) {
    const [showDialog, setShowDialog] = useState(false);

    useEffect(() => {
        if (props.signInUserId === undefined) {
            // サインアウト時
            return;
        }
        // サインイン時
        if (window.navigator.userAgent.indexOf("Safari") !== -1) {
            // iOSは、画面真っ白になってしまうので、何もしない
            return;
        }
        const vapidKey = FirebaseConfig.vapidKey;
        const messaging = firebase.messaging();
        setTimeout(() => {
            if (!gettedToken) {
                setShowDialog(true);
            }
        }, 500);
        messaging.getToken({vapidKey})
        .then((token) => {
            console.log('token', token);
            const uid = firebase.auth().currentUser?.uid;
            console.log('uid', uid)
            if (uid !== undefined) {
                dbAccessor.registMessagingToken(uid, token)
                .then((result: boolean) => {
                    console.log('registed token.', result);
                })
                .catch((e) => {
                    console.warn('failed to regist messaging token.', e);
                })
            }
        })
        .catch((e) => {
            console.warn('failed getting messaging token.', e);
        })
        .finally(() => {
            setShowDialog(false);
            gettedToken = true;
        });
    }, [props.signInUserId]);


    const message = "通知を「許可」すると、村に変化があった際に通知を受け取れます。\n「ブロック」すると、通知は受け取れません。" 
    return (
        <Modal show={showDialog} dialogClassName={styles.container}>
            <Modal.Body>
                {message}                
            </Modal.Body>
        </Modal>
    );
}
export default connect(mapStateToProps)(AuthNotification);
