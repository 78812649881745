export enum DialogResult {
    OK,
    CANCEL,
}
export enum DialogMode {
    OkCancel,
    OkOnly,
}
export type ConfirmDialogParam = {
    message: string;    // ダイアログに表示するメッセージ
    title?: string;      // ダイアログタイトル。省略時は「確認」となる。
    mode?: DialogMode;  // 省略時はOkCancel
}
/**
 * 特定の処理をキックするための列挙体
 */
export enum KickCommand {
    ShowManageDialog = 'ShowManageDialog',       // 管理画面表示
    ShowUserInfoDialog = 'ShowUserInfoDialog',     // 住民情報ダイアログ表示
    MapToCenter = 'MapToCenter',            // 地図を中央に
    AllPopupOpen = 'AllPopupOpen',           // 全てのポップアップを最大化
    AllPopupClose = 'AllPopupClose',          // 全てのポップアップを最小化
    ExitVillage = 'ExitVillage',           // 村を出る
    SignIn = 'SignIn',                      // サインイン

    DrawStructure = 'DrawStructure',          // 建物作成
    DrawEarth = 'DrawEarth',              // 島作成
    DrawForest = 'DrawForest',             // 緑地作成
    DrawRoad = 'DrawRoad',               // 道作成
    EditTopography = 'EditTopography',         // 地形編集
    RemoveStructure = 'RemoveStructure',        // 建物削除
    RemoveTopography = 'RemoveTopography',       // 地形削除
    RenameEarth = 'RenameEarth',            // 島名変更
    MoveStructure = 'MoveStructure',          // 建物移築
    ChangeStructure = 'ChangeStructure',        // 建物改装
    InfoMove = 'InfoMove',                      // 情報移動
    CreateNewIdea = 'CreateNewIdea',                // ひらめき情報作成
    JumpToInfo = 'JumpToInfo',                  // 指定の情報へ移動

    StartPopupVideo = 'StartPopupVideo',        // ポップアップ動画再生開始。引数にVideoID, 開始位置

    RefreshInfo = 'RefreshInfo',            // 最新情報を取得したいコンポーネントで情報更新させる

    ShowMapHistory = 'ShowMapHistory',      // 過去地図表示モード
}