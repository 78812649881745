import React, { useMemo, useRef, useState } from 'react'
import { DispatchProps, TagInfo, ImageInfo, SourceInfo } from '../../../types/types';
import { RootState } from '../../../store';
import { connect } from 'react-redux';
import * as dbAccessor from "../../../util/DbAccessor";
import TagPanel from '../TagPanel';
import StateUtility from '../../../util/StateUtility';
import { Modal } from 'react-bootstrap';
import ImageInfoEdit from './ImageInfoEdit';
import '../../../styles/_common.scss';
import { FeatureInfo } from '../../../types/info';
import * as operationThunks from '../../../store/operation/thunks';
import * as operationActions from '../../../store/operation/actions';
import { DialogResult, KickCommand } from '../../../types/operation';
import styles from './InfoAddEditDialog.module.scss';
import * as CommonUtility from '../../../util/CommonUtility';
import FacebookEventImport, {EventInfo as FaceBookEventResult} from './FacebookEventImport';
import ContentEditor from './ContentEditor';

/**
 * 情報登録/編集パネル
 */

type OwnProps = {
    /** 親からもらうprops定義 */
    info?: FeatureInfo;     // 編集対象の情報。未指定の場合は、追加モードとして動作
    // 追加時に指定する情報
    addInfo?: {
        featureId: string;      // 追加対象の建物
        linkInfoId?: number;    // 特定の情報に対するリンクを置く場合に指定。追加モードの場合のみ。
    };
    close: () => void;      // 情報登録完了後にコールバック
    cancel: () => void;     // キャンセル時のコールバック
}
type StateProps = OwnProps & {
    /** ストアの内容から生成するprops定義 */
    // selectedFeatureId: string | undefined;
    tagInfos: (idArr: string[]) => TagInfo[];
}

type Props = StateProps & DispatchProps;

const mapStateToProps = (state: RootState, ownProps: OwnProps): StateProps => ({
    ...ownProps,
    // selectedFeatureId: state.operationReducer.selectFeatureId,
    tagInfos: StateUtility.idToTagInfo(state),
});

enum Mode {
    Normal,
    FacebookEventList,
}

function getDateStr(date: Date | undefined): string {
    if (date === undefined) {
        return '';
    }
    return CommonUtility.convertDateToDateStr(date);
}

function getTimeStr(date: Date | undefined): string {
    if (date === undefined) {
        return '';
    }
    return CommonUtility.convertDateToTimeStr(date);
}

function createNewDate(currentDate: Date | undefined, newDateStr: string): Date {
    const newDay = new Date(newDateStr);
    if (currentDate === undefined) {
        // 日時未設定の場合は、00:00を設定
        return newDay;
    }
    const ret = CommonUtility.replaceDate(currentDate, newDay);
    return ret;
}

function createNewTime(currentDate: Date | undefined, newTimeStr: string): Date {
    // 日時未設定の場合は、現在の日付を設定
    return CommonUtility.replaceTime(
        currentDate === undefined ? new Date() : currentDate,
        newTimeStr
    );
}

function createDefaultContents(props: Props) {
    if (props.info !== undefined) {
        return props.info.contents;
    }
    if (props.addInfo?.linkInfoId !== undefined) {
        // 情報へのリンクを冒頭に設定
        return '>[[info:' + props.addInfo.linkInfoId + ']]';
    }
    return '';
}

function InfoAddEditDialog(props: Props) {
    let registing: boolean = false; // 登録処理中の場合,true

    const [mode, setMode] = useState(Mode.Normal);
    const [title, setTitle] = useState(props.info === undefined ? '' : props.info.title);

    const defaultContents = useRef(createDefaultContents(props));
    const [contents, setContents] = useState(defaultContents.current);

    const [image, setImage] = useState({
        original: '',
        thumb: props.info?.imageThumb === undefined ? '' : props.info.imageThumb,
    });
    const [tags, setTags] = useState(props.info === undefined ? [] : props.tagInfos(props.info.tags));
    const [eventStartDate, setEventStartDate] = useState(props.info?.eventStartDate);
    const [eventEndDate, setEventEndDate] = useState(props.info?.eventEndDate);
    const [source, setSource] = useState({
        source_type: 'manual',
    } as SourceInfo);
    const [imgUrl, setImgUrl] = useState(undefined as string | undefined);

    const modalTitle = useMemo((): string => {
        return mode === Mode.Normal ?
                props.info === undefined ? '新規情報登録' : '情報更新'
            :
                'Facebookイベント選択';
    }, [mode, props.info]);

    const featureId = useMemo((): string => {
        if (props.info !== undefined) {
            return props.info.featureId;
        } else {
            return props.addInfo?.featureId as string;
        }
    }, [props.info, props.addInfo]);

    /**
     * イベント日時のValidationチェック結果メッセージを返す.
     * 問題ない場合は、空文字.
     */
    const eventValidMessage = useMemo((): string => {
        if (eventStartDate === undefined) {
            if (eventEndDate === undefined) {
                return '';
            } else {
                return '開始日時を設定してください。'
            }
        }
        if (eventEndDate !== undefined && eventStartDate > eventEndDate) {
            return '開始日時よりも後の終了日時を設定してください。'
        }
        return '';
    },[eventStartDate, eventEndDate]);
    
    /**
     * 更新or登録可能か
     */
     const registable = useMemo((): boolean => {
        if (title.length === 0) {
            // タイトルは必須
            return false;
        }
        if (eventValidMessage.length > 0) {
            // イベント日時に誤りがある場合
            return false;
        }
        if (props.info === undefined) {
            return true;
        }

        // 更新時
        if (props.info?.title !== title) {
            return true;
        }
        if (props.info?.contents !== contents) {
            return true;
        }
        if (props.info?.imageThumb !== image.thumb) {
            return true;
        }
        const tagIds = tags.map((tagInfo) => {
            return tagInfo.id;
        });
        if (JSON.stringify(props.info.tags) !== JSON.stringify(tagIds)) {
            return true;
        }
        if (props.info?.eventStartDate !== eventStartDate) {
            return true;
        }
        if (props.info?.eventEndDate !== eventEndDate) {
            return true;
        }
        return false;
    },[title, contents, image, eventStartDate, eventEndDate, eventValidMessage, props.info, tags]);

    /**
     * 登録ボタン押下
     */
    const onRegistBtnClicked = async() => {
        if (featureId === undefined) {
            console.warn('建物ID不明');
            return;
        }
        // 二重登録防止
        if (registing) {
            console.log('二重登録防止');
            return;
        }
        registing = true;

        // DB登録
        await dbAccessor.registFeatureInfo(featureId,
            {
                title,
                contents,
                image,
                tags,
                eventStartDate,
                eventEndDate,
                source,
            });
        // 情報更新検知したいコンポーネントに通知する
        props.dispatch(operationActions.kickCommand(KickCommand.RefreshInfo));

        // 入力モード終了
        props.close();
    };

    /**
     * 更新ボタン押下
     */
    const onUpdateBtnClicked = async() => {
        if (featureId === undefined || props.info === undefined) {
            console.warn('登録対象なし')
            return;
        }
        // 二重登録防止
        if (registing) {
            console.log('二重登録防止');
            return;
        }
        registing = true;

        // DB更新
        await dbAccessor.updateFeatureInfo(featureId, 
            props.info.id,
            {
                title,
                contents,
                image,
                tags,
                eventStartDate,
                eventEndDate,
                source,
            }
        );
        // 情報更新検知したいコンポーネントに通知する
        props.dispatch(operationActions.kickCommand(KickCommand.RefreshInfo));

        // 入力モード終了
        props.close();
    }

    const onDeleteBtnClicked = async() => {
        if (featureId === undefined || props.info === undefined) {
            console.warn('削除対象なし')
            return;
        }

        const result = await props.dispatch(operationThunks.confirmThunk({
            message: '削除してよろしいですか。'
        })) as DialogResult;
        if (result === DialogResult.CANCEL) {
            return;
        }

        // DB更新
        await dbAccessor.deleteFeatureInfo(featureId, props.info.id);
        // 情報更新検知したいコンポーネントに通知する
        props.dispatch(operationActions.kickCommand(KickCommand.RefreshInfo));

        // 入力モード終了
        props.close();
    }

    /**
     * 情報タイトル欄の値が更新された場合
     */
     const onChangeTitle = (event: React.ChangeEvent<HTMLInputElement>) => {
        setTitle(event.target.value);
    }
    /**
     * 看板画像が更新された場合
     */
    const onImageUpdate = (imageInfo: ImageInfo) => {
        setImage(imageInfo);
    }

    /**
     * 詳細欄の値が更新された場合
     */
    const onChangeContent = (newContent: string) => {
        setContents(newContent);
    }
    
    const onTagsChanged = (tags: TagInfo[]) => {
        setTags(tags);
    }

    const onClearEventDate = () => {
        setEventStartDate(undefined);
        setEventEndDate(undefined);
    }

    const onEventStartDateChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        const newEventStartDate = createNewDate(eventStartDate, value);
        setEventStartDate(newEventStartDate);
    }

    const onEventEndDateChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        const newEventEndDate = createNewDate(eventEndDate, value);
        setEventEndDate(newEventEndDate);
    }

    const onEventStartTimeChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        const newEventStartDate = createNewTime(eventStartDate, value);
        setEventStartDate(newEventStartDate);
    }

    const onEventEndTimeChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        const newEventEndDate = createNewTime(eventEndDate, value);
        setEventEndDate(newEventEndDate);
    }

    const onFacebookEventImportCanceled = () => {
        setMode(Mode.Normal);
    };

    const onFacebookEventImport = (info: FaceBookEventResult) => {
        setMode(Mode.Normal);
        setTitle(info.title);
        setContents(info.contents);
        setImgUrl(info.imgUrl);
        setEventStartDate(info.eventStartDate);
        setEventEndDate(info.eventEndDate);
        setSource(info.source);
    };

    const footer = props.info === undefined ?
        // 登録時
        <button className="btn btn-primary" disabled={!registable} onClick={onRegistBtnClicked}>登録</button>
        :
        // 更新時
        <React.Fragment>
            <button className="btn btn-primary" disabled={!registable} onClick={onUpdateBtnClicked}>更新</button>
            <button className="btn btn-primary" onClick={onDeleteBtnClicked}>削除</button>
        </React.Fragment>
    ;

    return (
        <Modal show={true}>
            {mode === Mode.Normal ?
                // 情報入力モード
                <React.Fragment>
                    <Modal.Header>
                        <Modal.Title>{modalTitle}</Modal.Title>
                        {/* <button className="btn btn-outline-primary" onClick={()=>{setMode(Mode.FacebookEventList)}}>
                            <img src={process.env.PUBLIC_URL+'/logo/f_logo_RGB-Blue_58.png'} className={styles.fbLogo} alt="Facebook" />
                            イベント取得
                        </button> */}
                    </Modal.Header>
                    <Modal.Body>
                        <div className={styles.formBody}>
                            <div className="form-group">
                                <label>タイトル<span className="annotation">*</span></label>
                                <input value={title} className="form-control" onChange={onChangeTitle} />
                            </div>
                            <div className="form-group">
                                <label>看板</label>
                                <ImageInfoEdit url={imgUrl} value={image} onUpdate={onImageUpdate} />
                            </div>
                            <div className="form-group">
                                <label>内容</label>
                                <ContentEditor content={defaultContents.current} onChange={onChangeContent} />
                                {/* <textarea value={contents} className="form-control" onChange={onChangeDescription} rows={5} /> */}
                            </div>
                            <div className="form-group">
                                    <label>開催日時</label>
                                    <button className="btn btn-secondary btn-sm mx-sm-3" 
                                        onClick={onClearEventDate}
                                        disabled={eventStartDate===undefined && eventEndDate===undefined}>クリア</button>
                                <div className="form-inline">
                                    <input className="form-control" type="date" value={getDateStr(eventStartDate)} onChange={onEventStartDateChanged} />
                                    <input className="form-control" type="time" value={getTimeStr(eventStartDate)} onChange={onEventStartTimeChanged} />
                                    <span>～</span>
                                    <input className="form-control" type="date" value={getDateStr(eventEndDate)} onChange={onEventEndDateChanged} />
                                    <input className="form-control" type="time" value={getTimeStr(eventEndDate)} onChange={onEventEndTimeChanged} />
                                </div>
                                {eventValidMessage.length > 0 &&
                                    <div className="invalid">
                                        {eventValidMessage}
                                    </div>
                                }
                            </div>
                        </div>
                        <TagPanel tags={tags} change={onTagsChanged} />
                    </Modal.Body>
                    <Modal.Footer>
                        {footer}
                        <button className="btn btn-secondary" onClick={props.cancel}>Cancel</button>
                    </Modal.Footer>
                </React.Fragment>
            :
                <FacebookEventImport imported={onFacebookEventImport} cancel={onFacebookEventImportCanceled} />
            }
        </Modal>
    );
}
export default connect(mapStateToProps)(InfoAddEditDialog);
