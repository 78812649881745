import React, { useEffect, useState } from 'react';
import { DispatchProps, VillageUserInfo } from '../types/types';
import { RootState } from '../store';
import { connect } from 'react-redux';
import Map from '../components/map/MapOL';
import Menu from '../components/menu/Menu';
import RightSideArea from '../components/infos/RightSideArea';
import UpdateUserInfo from '../components/auth/UpdateUserInfo';
import ManageDialog from '../components/manage/ManageDialog';
import { HTML5Backend } from 'react-dnd-html5-backend'
import { DndProvider } from 'react-dnd'
import IslandList from '../components/IslandList';
import './MainView.scss';
import MainControlPanel from '../components/theme/MainControlPanel';
import MyMediaQuery from '../components/common/MyMediaQuery';
import * as infoThunks from '../store/info/thunks';
import * as featureThunks from '../store/feature/thunks';
import YoutubePopup from '../components/YoutubePopup';
import AuthDialog from '../components/auth/AuthDialog';

type OwnProps = {
    /** 親からもらうprops定義 */
}
type StateProps = OwnProps & {
    /** ストアの内容から生成するprops定義 */
    userInfo: VillageUserInfo | undefined; // 現在のユーザ情報
}
type Props = StateProps & DispatchProps;

const mapStateToProps = (state: RootState, ownProps: OwnProps): StateProps => ({
    ...ownProps,
    userInfo: state.systemReducer.users[state.systemReducer.signInUserId as string] as VillageUserInfo | undefined,
});

/**
 * 地図メイン画面
 *
 * @export
 * @returns
 */
function MainView(props: Props) {
    const [loaded, setLoaded] = useState(false);

    const dispatch = props.dispatch;
    useEffect(() => {
        let timeH: NodeJS.Timeout;
        // 入村したら、地理情報ロード
        props.dispatch(featureThunks.loadFeatureThunk())
        .then(() => {
            return dispatch(infoThunks.loadTagsInfoThunk());
        })
        .then(() => {
            return dispatch(infoThunks.loadFeatureInfoPopupThunk());
        })
        .then(() => {
            // 定期更新チェック
            console.log('start check')
            timeH = setInterval(async() => {
                console.log('checkPopupInfoDiff', timeH);
                dispatch(infoThunks.checkPopupInfoDiff());
            }, 10000);
            setLoaded(true);
        })

        return () => {
            if (timeH) {
                console.log('checkPopupInfo clear', timeH)
                clearInterval(timeH)
            }
        }
    }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []);

    if (!loaded) {
        return (
            <div>
                ロード中...
            </div>
        );
    }
    return (
        <DndProvider backend={HTML5Backend}>
            <Map />
            <div id="island-menu-area" >
                <IslandList />
            </div>
            <div id="menu-area">
                <Menu />
            </div>
            <RightSideArea />
            <div id="view-control-area">
                <MyMediaQuery>
                    <MyMediaQuery.LongHeightDevice>
                        <MainControlPanel />
                    </MyMediaQuery.LongHeightDevice>
                    <MyMediaQuery.ShortHeightDevice>
                        <MainControlPanel close />
                    </MyMediaQuery.ShortHeightDevice>
                </MyMediaQuery>
            </div>
            <YoutubePopup />

            {props.userInfo !== undefined &&
                <UpdateUserInfo />
            }
            <ManageDialog />
            <AuthDialog />
        </DndProvider>
    );

}
export default connect(mapStateToProps)(MainView);
