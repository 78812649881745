import React, { useState } from 'react';
import { FacebookEventInfo, FacebookGroupEventInfo } from '../../../types/types';
import styles from './FacebookEventGroupItem.module.scss';

type Props = {
    /** 親からもらうprops定義 */
    group: FacebookGroupEventInfo;
    selectId: string | null;
    click: (item: FacebookEventInfo) => void;    // アイテムクリックした際のコールバック
}

export default function FacebookEventGroupItem(props: Props) {
    const [isOpen, setOpen] = useState(false);
    
    const onAccordionClicked = () => {
        setOpen(!isOpen);
    }

    const onItemClicked = (item: FacebookEventInfo) => {
        props.click(item);
    }

    const accordionBtnStyle = (isOpen ? 'icon-circle-up' : 'icon-circle-down') + ' ' + styles.accordion;

    const createDateStr = (ev: FacebookEventInfo) => {
        return ev.start_time + (ev.end_time === undefined ? '' : ' ~ ' + ev.end_time)
    }
    return (
        <React.Fragment>
            <li className={`list-group-item bg-info ${styles.group}`} onClick={onAccordionClicked} >
                {props.group.name}
                <i className={accordionBtnStyle} />
            </li>
            <ul className={`list-group ${isOpen ? '' : styles.hide}`}>
                {props.group.events.map((ev) => {
                    return (
                        <li className={`list-group-item list-group-item-action ${styles.item} ${props.selectId===ev.id ? 'active' : ''}`} key={ev.id} onClick={()=>{onItemClicked(ev)}}>
                            <div className={styles.title}>{ev.name}</div>
                            <div className={styles.date}>{createDateStr(ev)}</div>
                        </li>
                    )
                })}
            </ul>
        </React.Fragment>
    );
}