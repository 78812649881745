import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import PromptMessageBox from '../draw/PromptMessageBox';
import Slider, { createSliderWithTooltip } from 'rc-slider';
import 'rc-slider/assets/index.css';
import * as dbAccessor from "../../../util/DbAccessor";
import { FeatureType, FeatureWithOperation } from '../../../types/types';
import { Vector as VectorSource } from 'ol/source';
import MapUtil from '../MapUtil';
import { useDispatch } from 'react-redux';
import * as operationActions from '../../../store/operation/actions';
import { Vector as VectorLayer } from 'ol/layer';
import styles from './MapHistoryController.module.scss';

type Props = {
    structureLayer: VectorLayer;
    topographyLayer: VectorLayer;
    close: () => void;
}

const SliderWithTooltip = createSliderWithTooltip(Slider);
export default function MapHistoryController(props: Props) {
    const [loading, setLoading] = useState(true);
    const [features, setFeatures] = useState([] as FeatureWithOperation[]);
    const [originalStructureSource] = useState(props.structureLayer.getSource());
    const [originalTopographySource] = useState(props.topographyLayer.getSource());
    const structureSource = useRef(null as VectorSource | null);
    const topographySource = useRef(null as VectorSource | null);
    const [timeValue, setTimeValue] = useState(0);
    const [playing, setPlaying] = useState(false);
    const intervalRef = useRef(null as NodeJS.Timeout | null);
    const dispatch = useDispatch();

    // 初期処理
    useEffect(() => {
        // ポップアップ非表示
        dispatch(operationActions.disabledPopupAction());
        // 島名非表示
        dispatch(operationActions.disabledLandNameAction());

        // 全履歴情報取得
        dbAccessor.getAllHistoryFeature()
        .then((features) => {
            setFeatures(features);
            setLoading(false);
        });
    }, [dispatch]);


    const min = useMemo(() => {
        return 0;
    }, []);

    const max = useMemo(() => {
        return features.length - 1;
    }, [features]);

    useEffect(() => {
        setTimeValue(max);
    }, [max]);

    const formatter = useCallback((value:number) => {
        if (features[value] === undefined) {
            return '';
        }
        return features[value].date.toLocaleDateString();
    }, [features]);

    // スライドを手動で動かした場合のコールバック
    const onTimeChanged = useCallback((value: number) => {
        setTimeValue(value);
    }, []);

    const onPlayBtnClicked = useCallback(() => {
        setPlaying(!playing);
    }, [playing]);

    const intervalCallback = useCallback((currentValue: number): number => {
        const interval = Math.max(1, Math.floor((max - min) / 100));
        let newValue = currentValue + interval;
        if (max < newValue) {
            newValue = max;
            setPlaying(false);
        }
        return newValue;
    }, [min, max]);

    // 再生ボタン押下時の挙動
    useEffect(() => {
        if (playing) {
            // 再生開始
            if (intervalRef.current !== null) {
                return;
            }
            if (max === timeValue) {
                setTimeValue(min);
            }
            intervalRef.current = setInterval(() => {
                setTimeValue((cur) => {
                    return intervalCallback(cur);
                });
            }, 500);
        } else {
            // 停止
            if (intervalRef.current !== null) {
                clearInterval(intervalRef.current);
                intervalRef.current = null;
            }
        }
    }, [playing, timeValue, max, min, intervalCallback]);

    const redraw = useCallback((value: number) => {
        if (value === -1) {
            return;
        }
        // 表示対象の地物を抽出
        let targets = features.slice(0, value + 1);
        if (targets.length > 0) {
            const currentTime = features[value].date.valueOf();
            targets = targets.filter((feature) => {
                if (currentTime < feature.date.valueOf()) {
                    return false;
                }
                if (feature.endDate === undefined) {
                    return true;
                }
                return currentTime <= feature.endDate.valueOf();
            });
        }
        console.log('targets', targets);

        // 建物
        const structures = targets.filter((target) => target.type === FeatureType.STRUCTURE);
        const olStructureFeatures = structures.map((target) => {
            return MapUtil.createOlFeatureFromFeature(target);
        });
        // 土地
        const topographies = targets.filter((target) => target.type !== FeatureType.STRUCTURE);
        const olTopograpyFeatures = topographies.map((target) => {
            return MapUtil.createOlFeatureFromFeature(target);
        });

        const newStructureSource = new VectorSource();
        newStructureSource.addFeatures(olStructureFeatures);
        props.structureLayer.setSource(newStructureSource);
        if (structureSource.current !== null) {
            structureSource.current.dispose();
        }
        structureSource.current = newStructureSource;

        const newTopographySource = new VectorSource();
        newTopographySource.addFeatures(olTopograpyFeatures);
        props.topographyLayer.setSource(newTopographySource);
        if (topographySource.current !== null) {
            topographySource.current.dispose();
        }
        topographySource.current = newTopographySource;
    }, [props.structureLayer, props.topographyLayer, features]);

    useEffect(() => {
        redraw(timeValue);
    }, [timeValue, redraw]);

    const onClose = useCallback(() => {
        // 地物を戻す
        props.structureLayer.setSource(originalStructureSource);
        if (structureSource.current !== null) {
            structureSource.current.dispose();
        }

        props.topographyLayer.setSource(originalTopographySource);
        if (topographySource.current !== null) {
            topographySource.current.dispose();
        }

        // ポップアップ表示
        dispatch(operationActions.enabledPopupAction());
        // 島名表示
        dispatch(operationActions.enabledLandNameAction());

        props.close();
    }, [props, originalStructureSource, originalTopographySource, dispatch]);

    return (
        <PromptMessageBox message="地図の歴史を遡ります." cancel={onClose}>
            <div className={styles.bodyArea}>
                <button className={`${styles.btn} btn btn-light`} onClick={onPlayBtnClicked}>
                    <i className={playing ? 'icon-pause2' : 'icon-play3'} />
                </button>
                <div className={styles.slideArea}>
                    <SliderWithTooltip
                        min={min}
                        max={max}
                        value={timeValue}
                        onChange={onTimeChanged}
                        tipFormatter={formatter}
                        tipProps={{placement: 'bottom'}}
                    />
                </div>
                {loading &&
                    <div className="spinner-border" />
                }
            </div>
        </PromptMessageBox>
    );
}