import React, {  CSSProperties, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { VillageUserInfo, DispatchProps, Feature, MapMode, TagInfo } from '../../types/types';
import { connect } from "react-redux";
import { storeGetter } from '../../util/props';
import { RootState } from '../../store';
import InfoAddEditDialog from '../infos/edit/InfoAddEditDialog';
import StateUtility from '../../util/StateUtility';
import * as dbAccessor from "../../util/DbAccessor";
import MenuBtn from '../menu/MenuBtn';
import styles from './RightSideArea.module.scss';
import FeatureAttrDialog from './FeatureAttrDialog';
import { FeatureInfo } from '../../types/info';
import * as operationActions from '../../store/operation/actions';
import * as infoThunks from '../../store/info/thunks';
import InfoCardList from './InfoCardList';

type OwnProps = {}

type StateProps = OwnProps & {
    user: VillageUserInfo | undefined;
    selectedFeatureId: string | undefined;
    selectedFeature: Feature | undefined;
    focusInfoId: number | undefined;
    featureCreatorName: (featureId: string) => string;
    tags: { [id: string]: TagInfo };
    isAuthableInfo: (info: FeatureInfo) => boolean;
    mapMode: MapMode;
    userInfo: VillageUserInfo | undefined; // 現在のユーザ情報
}

type Props = StateProps & DispatchProps;

const mapStateToProps = (state: RootState, ownProps: OwnProps): StateProps => ({
    ...ownProps,
    user: state.systemReducer.users[state.systemReducer.signInUserId as string],
    selectedFeatureId: state.operationReducer.selectFeatureId,
    selectedFeature: storeGetter(state).selectedFeature,
    focusInfoId: state.operationReducer.focusInfoId,
    featureCreatorName: storeGetter(state).featureCreatorName,
    tags: state.infoReducer.tags,
    isAuthableInfo: StateUtility.isAuthableInfo(state),
    mapMode: state.operationReducer.mode,
    userInfo: state.systemReducer.users[state.systemReducer.signInUserId!],
});

enum Mode {
    LOADING,                // 情報取得中
    NORMAL,
    ShowAttr,               // 建物属性情報表示モード
    AddInfo,           // 追加情報入力モード
}

function RightSideArea(props: Props) {
    const barPrevPoint = useRef({x: 0, y: 0});    // バー位置を動かす際の直前の座標

    const [isShow, setShow] = useState(false);
    const [mode, setMode] = useState(Mode.NORMAL);
    const [infoMap, setInfoMap] = useState({} as {[id: string]: FeatureInfo});
    const [barTranslate, setBarTranslate] = useState(0);
    const [unsetTransition, setUnsetTransition] = useState(false);
    const [infoOrderAsc, setInfoOrderAsc] = useState(false);
    const [focusInfoId, setFocusInfoId] = useState(undefined as number | undefined);

    const refreshFeatureInfo = useCallback(async() => {
        props.dispatch(infoThunks.checkPopupInfoDiff());
        if (props.selectedFeatureId === undefined) {
            setInfoMap({});
            return;
        }
        setMode(Mode.LOADING);

        const infos = await dbAccessor.getFeatureInfo(props.selectedFeatureId);
        console.log('refreshFeatureInfo', infos);
        const newInfoMap = {} as {[id: string]: FeatureInfo};
        infos.forEach((info) => {
            // 権限外の情報は除外
            if(!props.isAuthableInfo(info)) {
                return;
            }
            newInfoMap[info.id] = info;
        });

        setInfoMap(newInfoMap);
        setMode(Mode.NORMAL);
        // eslint-disable-next-line
    }, [props.selectedFeatureId]);

    /**
     * 情報表示対象の建物が変更された場合
     */
     useEffect(() => {
        setInfoMap({});
        if (props.selectedFeatureId === undefined || props.mapMode === MapMode.DRAW) {
            setShow(false);
            return;
        }
        console.log('selectedFeature', props.selectedFeatureId);

        // 情報取得
        refreshFeatureInfo();
        setShow(true);
        setFocusInfoId(props.focusInfoId);
    }, [props.selectedFeatureId, props.focusInfoId, props.mapMode, refreshFeatureInfo]);

    useEffect(() => {
        setUnsetTransition(false);
    }, [isShow]);

    const onFeatureAttrDialogClose = useCallback(() => {
        setMode(Mode.NORMAL);
        props.dispatch(operationActions.changeModeAction(MapMode.NORMAL));
    }, [props]);

    const onAddInfoModeFinish = useCallback(() => {
        setMode(Mode.NORMAL);
        // 情報再読み込み
        refreshFeatureInfo();
    }, [refreshFeatureInfo]);

    const mainPanelStyle = useMemo((): CSSProperties |undefined => {
        if (!isShow) {
             return undefined;
        }
        // 60vh = エリアのHeight, 20px = 調整バーの高さ
        const style = {
            transform: 'translateY(min(' + barTranslate + 'px, calc(60vh - 20px)))'
        } as CSSProperties;
        if (unsetTransition) {
            style.transition = 'unset';
        }
        return style;
    }, [isShow, barTranslate, unsetTransition]);

    let dialog = null;
    switch(mode) {
        case Mode.ShowAttr:
            dialog = <FeatureAttrDialog feature={props.selectedFeature as Feature} close={onFeatureAttrDialogClose} />
            break;
        case Mode.AddInfo:
            // 属性追加モード
            dialog = <InfoAddEditDialog addInfo={{
                    featureId: props.selectedFeatureId as string
                }}
                            close={onAddInfoModeFinish}
                            cancel={onAddInfoModeFinish}
                             />
            break;
    }

    const onAdjustBarTouchStart = useCallback((event: React.TouchEvent) => {
        barPrevPoint.current = {
            x: event.touches[0].clientX,
            y: event.touches[0].clientY,
        }
        setUnsetTransition(true);
    }, []);

    const onAdjustBarTouchMove = useCallback((event: React.TouchEvent) => {
        // 前回の位置との差分
        const dy = event.touches[0].clientY - barPrevPoint.current.y;
        let newValue = barTranslate + dy;
        if (newValue < 0) {
            newValue = 0;
        }
        setBarTranslate(newValue);

        barPrevPoint.current = {
            x: event.touches[0].clientX,
            y: event.touches[0].clientY,
        }
    }, [barTranslate]);

    const onInfoAddBtnClicked = useCallback(() => {
        setMode(Mode.AddInfo);
    }, []);

    const title = useMemo((): string => {
        if (props.selectedFeatureId === undefined) {
            return '';
        }
        const feature = props.selectedFeature;
        if (feature === undefined) {
            return '';
        }
        return feature.name;
    }, [props.selectedFeatureId, props.selectedFeature]);

    const onFeatureAttrDialogShow = useCallback(() => {
        if (props.selectedFeature === undefined) {
            return;
        }
        setMode(Mode.ShowAttr);
    }, [props.selectedFeature]);

    const onCloseBtnClicked = useCallback(() => {
        setShow(false);
    }, []);

    const bodyRef = useRef<HTMLDivElement>(null);
    const onFocusInfoMounted = useCallback((rect: DOMRect) => {
        setTimeout(() => {
            if (bodyRef.current !== null) {
                const myRect = bodyRef.current.getBoundingClientRect();
                console.log('rect', rect, myRect);
                bodyRef.current.scrollTop = rect.top - myRect.top - 10;
                console.log('after scroll', bodyRef.current.scrollTop);
                setFocusInfoId(undefined);
            }
        }, 100);
    }, []);

    const orderIconClass = infoOrderAsc ? 'icon-sort-amount-desc' : 'icon-sort-amount-asc';

    return (
        <React.Fragment>
            {dialog}
            <div style={mainPanelStyle} className={`${styles.MainPanel} glass-style ${!isShow ? styles.hidden : ''}`}>
                <div className={`${styles.AdjustBar} ${isShow ?  styles.show : styles.hide}`}
                    onTouchStart={onAdjustBarTouchStart}
                    onTouchMove={onAdjustBarTouchMove}>
                    <i></i>
                </div>
                {props.selectedFeatureId !== undefined &&
                    <div className={`${styles.AttrArea} card`}>
                        <div className={`${styles.AttrHeader} card-header`}>
                            {props.userInfo !== undefined && 
                                // 情報追加ボタン
                                <span className={styles.MenuBtn}>
                                    <MenuBtn iconClass="icon-plus" onClick={onInfoAddBtnClicked}></MenuBtn>
                                </span>
                            }
                            {/* 並び順切り替えボタン */}
                            <span className={`${styles.MenuBtn} ${styles.iconOnly}`}>
                                <button>
                                    <i className={orderIconClass}
                                        onClick={()=>{
                                            setInfoOrderAsc(!infoOrderAsc)
                                        }} />
                                </button>
                            </span>
                            {/* 建物名 */}
                            <h5>
                                {title}
                                <span className={styles.HeaderEditBtnArea}>
                                    <i className="icon-info" onClick={onFeatureAttrDialogShow} />
                                </span>
                            </h5>
                            {/* 閉じるボタン */}
                            <div className={styles.CloseBtn}><i className="icon-times" onClick={onCloseBtnClicked} /></div>
                        </div>
                        <div className={`${styles.AttrBody} card-body`} ref={bodyRef}>
                            <InfoCardList 
                                infoMap={infoMap} 
                                reload={refreshFeatureInfo}
                                orderAsc={infoOrderAsc}
                                focusInfoId={focusInfoId}
                                onFocusInfoMounted={onFocusInfoMounted}
                                />
                            {mode === Mode.LOADING &&
                                <div className={`${styles.spinner} spinner-border text-secondary`} role="status">
                                    <span className="sr-only">Loading...</span>
                                </div>
                            }
                        </div>
                    </div>
                }
            </div>
        </React.Fragment>
    );
}
export default connect(mapStateToProps)(RightSideArea);

