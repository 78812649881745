import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { DispatchProps, Feature, ImageInfo, StructureImageDefine } from '../../../types/types';
import ImageRegister from '../../common/ImageRegister';
import * as dbAccessor from '../../../util/DbAccessor';
import { Button } from 'react-bootstrap';
import { RootState } from '../../../store';
import { connect } from 'react-redux';
import * as operationThunks from '../../../store/operation/thunks';
import { DialogMode, DialogResult } from '../../../types/operation';

export enum Stage {
    NORMAL,
    SELECT_IMAGE,  // 画像選択中
    CERTAIN,       // 登録確認中
    UPDATING,      // APIアクセス中
}
enum Mode {
    REGIST,
    UPDATE,
    DELETE,
}
type OwnProps = {
    /** 親からもらうprops定義 */
    onSave?: () => void; // 登録後に呼び出すコールバック
    selectDefine: StructureImageDefine | null;
    onStageChanged?: (stage: Stage) => void;   // 登録or変更の開始or終了時に呼び出すコールバック
}

type StateProps = OwnProps & {
    /** ストアの内容から生成するprops定義 */
    features: { [id: string]: Feature };  // 全地物情報
}
type Props = StateProps & DispatchProps;

const mapStateToProps = (state: RootState, ownProps: OwnProps): StateProps => ({
    ...ownProps,
    features: state.featureReducer.features,
});

/**
 * 建物アイコン登録コントローラー
 * @param props 
 * @returns 
 */
function IconRegistController(props: Props) {
    const [stage, setStage] = useState(Stage.NORMAL);
    const [mode, setMode] = useState(Mode.REGIST);
    const [image, setImage] = useState({
        thumb: '',
        original: '',
    });
    const [warnMessage, setWarnMessage] = useState('');

    const canvasRef = useRef<HTMLCanvasElement>(null);

    const onRegistButtonClicked = useCallback(() => {
        setMode(Mode.REGIST);
        setStage(Stage.SELECT_IMAGE);
    }, []);

    const editable = useMemo(() => {
        if (props.selectDefine===null) {
            return false;
        }
        return props.selectDefine.original;
    }, [props.selectDefine]);

    const onUpdateButtonClicked = useCallback(() => {
        if (!editable) {
            return;
        }
        setMode(Mode.UPDATE);
        setStage(Stage.SELECT_IMAGE);
    }, [editable]);

    const onDeleteButtonClicked = useCallback(async () => {
        if (!editable || props.selectDefine === null) {
            return;
        }
        setMode(Mode.DELETE);
        // 使用中の建物がないか確認
        const isUse = Object.values(props.features).some((feature) => {
            if (props.selectDefine === null) {
                return false;
            }
            if (feature.geoJson.properties === undefined || feature.geoJson.properties === null) {
                return false;
            }
            return feature.geoJson.properties.iconId === props.selectDefine.id;
        });
        if (isUse) {
            // 削除できない旨を表示
            props.dispatch(operationThunks.confirmThunk({
                message: 'この建物は使用中なので削除できません。',
                mode: DialogMode.OkOnly,
            }));
            return;
        } else {
            const result = await props.dispatch(operationThunks.confirmThunk({
                message: '削除してよろしいですか。',
            }));
            if (result === DialogResult.CANCEL) {
                return;
            }
        }
        // 削除実行
        setStage(Stage.UPDATING);
        await dbAccessor.removeIcon(props.selectDefine.id);
        setStage(Stage.NORMAL);
    }, [editable, props]);

    useEffect(() => {
        if (props.onStageChanged) {
            props.onStageChanged(stage);
        }
    }, [props, stage]);

    const onRegistIcon = useCallback((img: ImageInfo) => {
        setStage(Stage.CERTAIN);
        setImage(img);
    }, []);

    useEffect(() => {
        if (image.thumb.length === 0) {
            return;
        }
        const img = new Image();
        img.onload = () => {
            const canvas = canvasRef.current;
            if (canvas === null) {
                return;
            }
            const width = img.width;
            const height = img.height;
            canvas.setAttribute('width', width + '');
            canvas.setAttribute('height', height + '');
            const ctx = canvas.getContext('2d');
            if (ctx !== null) {
                ctx.drawImage(img, 0, 0, width, height);
            }
        };
        img.src = image.thumb;
    
    }, [image.thumb]);

    const onCertainCancel = useCallback(() => {
        setImage({thumb: '', original: ''});
        setStage(Stage.NORMAL);
    }, []);

    const onCertainRegist = useCallback(async() => {
        try {
            if (mode===Mode.REGIST) {
                setStage(Stage.UPDATING);
                await dbAccessor.registIcon(image);
            } else {
                if(props.selectDefine === null){
                    console.warn('選択中のアイコンがありません');
                    return;
                }
                const id = props.selectDefine.id;
                setStage(Stage.UPDATING);
                await dbAccessor.updateIcon(id, image);
            }
            setStage(Stage.NORMAL);
            if (props.onSave) {
                props.onSave();
            }
        } catch(e) {
            setWarnMessage('登録に失敗しました。');
        }
    }, [image, props, mode]);

    switch(stage) {
        case Stage.NORMAL:
            return (
                <React.Fragment>
                    <Button variant="secondary" onClick={onRegistButtonClicked}>登録</Button>
                    <Button variant="outline-secondary" disabled={!editable} onClick={onUpdateButtonClicked}>変更</Button>
                    <Button variant="outline-secondary" disabled={!editable} onClick={onDeleteButtonClicked}>削除</Button>
                </React.Fragment>
            );
        case Stage.SELECT_IMAGE:
            return (
                <ImageRegister maxOriginalSize={192} maxThumbSize={192} onSelect={(img)=>onRegistIcon(img)} onCancel={()=>setStage(Stage.NORMAL)} />
            );
        case Stage.CERTAIN:
            return (
                <div>
                    <canvas ref={canvasRef} className="img-canvas" width="0" height="0" />
                    <Button variant="secondary" onClick={onCertainCancel}>Cancel</Button>
                    <Button variant="primary" onClick={onCertainRegist}>
                        {mode === Mode.REGIST ? '登録':'更新'}
                    </Button>
                    <div className="text-danger">{warnMessage}</div>
                </div>
            );
        case Stage.UPDATING:
            return null;
    }
}
export default connect(mapStateToProps)(IconRegistController);
