import { Dispatch } from 'react';
import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { RootState } from '..';
import { TagInfo } from '../../types/types';
import * as actions from './actions';
import * as dbAccessor from "../../util/DbAccessor";
import { TagsDocItem } from '../../types/feature';

let lastCheckDate = '';  // 最後にポップアップ差分をチェックした日時

/**
 * 看板情報ロード
 */
export function loadFeatureInfoPopupThunk(): ThunkAction<void, RootState, unknown, Action<string>> {
    return async (dispatch: Dispatch<any>, getState: () => RootState) => {
        const result = await dbAccessor.getPopupInfo();
        lastCheckDate = result.current;
        result.record.forEach((record) => {
            dispatch(actions.addFeaturePopupInfoAction(record));
        })
    }
}

/**
 * 看板情報差分チェック
 */
export function checkPopupInfoDiff(): ThunkAction<void, RootState, unknown, Action<string>> {
    return async (dispatch: Dispatch<any>, getState: () => RootState) => {
        const result = await dbAccessor.getPopupInfoDiff(lastCheckDate);
        if (result === undefined) {
            console.warn('ポップアップ情報取得エラー');
            return;
        }
        lastCheckDate = result.current;
        result.record.forEach((info) => {
            dispatch(actions.checkPopupDiffInfoAction(info));
        });
    }
}

/**
 * タグ定義情報をロードする
 */
export function loadTagsInfoThunk(): ThunkAction<void, RootState, unknown, Action<string>> {
    return async (dispatch: Dispatch<any>, getState: () => RootState) => {
        dbAccessor.getTagsCollection().onSnapshot((querySnapshot) => {
            querySnapshot.docChanges().forEach((change) => {
                const doc = change.doc;

                if (change.type === "removed") {
                    dispatch(actions.removeTagInfoAction(doc.id));
                    return;
                }

                const data = doc.data() as TagsDocItem;
                if(data.delete === true) {
                    dispatch(actions.removeTagInfoAction(doc.id));
                    return;
                }
                const tag = Object.assign({
                    id: doc.id,
                }, data);
                switch(change.type) {
                    case "added":
                        dispatch(actions.addTagInfoAction(tag));
                        break;
                    case "modified":
                        dispatch(actions.updateTagInfoAction(tag));
                        break;
                }
            });
        });
    }
}

/**
 * タグ情報を更新する
 *
 * @param {TagInfo} tag 更新対象
 */
export function updateTagsInfoThunk(tag: TagInfo): ThunkAction<void, RootState, unknown, Action<string>> {
    return async (dispatch: Dispatch<any>, getState: () => RootState) => {
        await dbAccessor.getTagsCollection().doc(tag.id).set(tag);
        dispatch(actions.updateTagInfoAction(tag));
    }
}

