import { Extent } from "ol/extent";
import { ConfirmDialogParam, DialogResult, KickCommand } from "../../types/operation";
import { MapMode } from "../../types/types";
import { ActionTypes, ReducerType } from "./types";

/**
 * 地図モード変更
 * @param mode 地図モード
 * @param addition 補助情報
 */
export function changeModeAction(mode: MapMode, addition?: any) {
    return {
        type: ReducerType.ChangeMode,
        payload: {
            mode,
            addition,
        },
    };
}

/**
 * 指定の建物等を選択状態にする
 * @param id 図形ID
 * @param infoId 指定した場合、情報パネルを開いた際にこの情報IDの情報が表示される位置にスクロールされる
 */
export function selectFeatureAction(id: string, infoId?: number): ActionTypes {
    return {
        type: ReducerType.SelectFeature,
        payload: {id, infoId},
    };
}

/**
 * 建物の選択状態を解除する
 */
export function unselectFeatureAction(): ActionTypes {
    return {
        type: ReducerType.SelectFeature,
        payload: {id:undefined},
    };
}

// ダイアログ表示
export function showConfirmDialogAction(param: ConfirmDialogParam): ActionTypes {
    return {
        type: ReducerType.ShowConfirmDialog,
        payload: param,
    };
}

/**
 * 確認ダイアログの結果を格納
 */
export function setConfirmDialogResultAction(result: DialogResult): ActionTypes {
    return {
        type: ReducerType.SetConfirmDialogResult,
        payload: result,
    };
}

/**
 * 確認ダイアログの結果をクリアする
 */
export function clearConfirmDialogReusltAction(): ActionTypes {
    return {
        type: ReducerType.SetConfirmDialogResult,
        payload: undefined,
    };
}

/**
 * 指定の処理コマンドを実行する
 * @param command 実行するコマンド
 * @param option 
 */
export function kickCommand(command: KickCommand, option?: any): ActionTypes {
    return {
        type: ReducerType.KickCommand,
        payload: {
            command,
            option,
        }
    }
}

/**
 * コマンド実行した際にコマンド実行側でこれを呼び出す
 */
export function commandKicked(): ActionTypes {
    return {
        type: ReducerType.KickCommand,
        payload: undefined,
    }
}

/**
 * 地図Viewのエクステント,ズームLvを更新する
 * @param extent 
 */
export function updateMapViewExtent(extent: Extent, zoom:number): ActionTypes {
    return {
        type: ReducerType.UpdateMapViewExtent,
        payload: {
            extent,
            zoom,
        }
    }
}

export function disabledPopupAction(): ActionTypes {
    return {
        type: ReducerType.DisabledPopup,
        payload: {
            disabled: true,
        }
    }
}

export function enabledPopupAction(): ActionTypes {
    return {
        type: ReducerType.DisabledPopup,
        payload: {
            disabled: false,
        }
    }
}

export function disabledLandNameAction(): ActionTypes {
    return {
        type: ReducerType.DisabledLandName,
        payload: {
            disabled: true,
        }
    }
}

export function enabledLandNameAction(): ActionTypes {
    return {
        type: ReducerType.DisabledLandName,
        payload: {
            disabled: false,
        }
    }
}
