import React from 'react';
import { DispatchProps } from '../../types/types';
import { RootState } from '../../store';
import { connect } from 'react-redux';
import { Popover, OverlayTrigger } from 'react-bootstrap';
import '../../styles/_icon.scss';
import styles from  './MenuBtn.module.scss';

type OwnProps = {
    /** 親からもらうprops定義 */
    children?: string;
    iconClass: string;
    onClick?: (param?: any) => void;
    badge?: 'develop' | 'manage';
    disabled?: boolean;

    guide?: string;

    isSubMenu?: boolean;
}
type StateProps = OwnProps & {
    /** ストアの内容から生成するprops定義 */
}
type Props = StateProps & DispatchProps;

const mapStateToProps = (state: RootState, ownProps: OwnProps): StateProps => ({
    ...ownProps,
});

let maxId: number = 0;

function MenuBtn(props: Props) {
    // private id: number;
    const id = ++maxId;

    const handleClick = () => {
        if (props.onClick === undefined || props.disabled) {
            return;
        }
        props.onClick();
    }

    const badge = ((): React.ReactElement|null => {
        switch(props.badge) {
            case 'develop':
                return <span className={`${styles.badge} badge badge-warning`}>開発中</span>;
            case 'manage':
                return <span className={`${styles.badge} badge badge-primary`}>管理者</span>;
            default:
                return null;
        }
    })();

    let btnClassName = 'btn btn-info ' + styles.MenuBtn;
    if (props.isSubMenu) {
        btnClassName += (' ' + styles.subMenu)
    }
    if (props.children === undefined || props.children.length === 0) {
        // キャプションがない場合は、小さめのボタン
        btnClassName += (" " + styles.small);
    }
    if (props.disabled) {
        // マウスイベントは検知したいので、クラスでdisabled指定している
        btnClassName += ' disabled';
    }
    const button = (
        <button className={btnClassName} onClick={()=>handleClick()}>
            <i className={props.iconClass}></i>
            {badge}
            <span className={styles.caption}>{props.children}</span>
        </button>
    );

    if (props.guide !== undefined) {
        const popover = (
            <Popover id={"popover-menubtn-" + id}>
                <Popover.Content>
                    <b>Click!</b><br/>{props.guide}
                </Popover.Content>
            </Popover>            
        );
        return (
            <OverlayTrigger trigger="click" defaultShow={true} placement="right" overlay={popover}>
                {button}
            </OverlayTrigger>
        );
    } else {
        return button;
    }

}
export default connect(mapStateToProps)(MenuBtn);
