import { VillageDocItem } from "../../types/feature";
import { GroupInfo, StructureImageDefine, VillageUserInfo } from "../../types/types";
import { ActionTypes, ReducerType } from "./types";

/**
 * 村情報登録
 * @param info 
 */
export function registVillageInfoAction(info: VillageDocItem): ActionTypes {
    return {
        type: ReducerType.RegistVillageInfo,
        payload: info,
    }
}

/**
 * サインインユーザ情報を登録
 * @param user 
 */
export function signInAction(uid: string): ActionTypes {
    return {
        type: ReducerType.SignIn,
        payload: uid,
    };
}

export function signOutAction(): ActionTypes {
    return {
        type: ReducerType.SignIn,
        payload: undefined,
    };
}

export function registUserInfoAction(user: VillageUserInfo): ActionTypes {
    return {
        type: ReducerType.RegistUserInfo,
        payload: user,
    };
}

export function registGroupInfoAction(group: GroupInfo): ActionTypes {
    return {
        type: ReducerType.RegistGroupInfo,
        payload: group,
    };
}

export function removeGroupInfoAction(groupId: string): ActionTypes {
    return {
        type: ReducerType.RemoveGroupInfo,
        payload: groupId,
    };
}

export function loadIconDefineAction(define: StructureImageDefine[]): ActionTypes {
    return {
        type: ReducerType.SetIconDefine,
        payload: define,
    };
}