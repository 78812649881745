import React, { Component } from 'react';
import { DispatchProps, FeatureType } from '../types/types';
import { RootState } from '../store';
import { connect } from 'react-redux';
import { Feature } from '../types/types';
import styles from './IslandList.module.scss';
import MapUtil from './map/MapUtil';

type OwnProps = {
    /** 親からもらうprops定義 */
}
type StateProps = OwnProps & {
    /** ストアの内容から生成するprops定義 */
    villageName: string;
    namedFeatures: Feature[];
}
type Props = StateProps & DispatchProps;

const mapStateToProps = (state: RootState, ownProps: OwnProps): StateProps => ({
    ...ownProps,
    villageName: state.systemReducer.village.name,
    namedFeatures:  Object.values(state.featureReducer.features).filter((feature: Feature) => {
        if (feature.type !== FeatureType.EARTH) {
            return false;
        }
        if (feature.name === undefined || feature.name.length === 0) {
            return false;
        }
        // 表示範囲内の地物に絞る
        const extent = state.operationReducer.mapViewExtent;
        const olFeature = MapUtil.createOlFeature(feature.geoJson);
        return olFeature.getGeometry().intersectsExtent(extent);
    }),
});

type State = {}

/**
 * 島名一覧
 */
class IslandList extends Component<Props, State> {
    render() {
        // const list = this.props.namedFeatures.map((feature: Feature) => {
        //     return <li key={feature.id} className="list-group-item">{feature.name}</li>
        // });
        return (
            <div className={styles.VillageName}>{this.props.villageName}</div>
            // <ul className="island-list list-group">
            //     {/* {list} */}
            // </ul>
        )
    }


}
export default connect(mapStateToProps)(IslandList);
