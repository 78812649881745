import { RootState } from "../store";
import { FeatureInfo } from "../types/info";
import { TagInfo } from "../types/types";

export default class StateUtility {
    public static tagInfo(state: RootState) {
        return (id: string): TagInfo => {
            return state.infoReducer.tags[id];
        }
    }

    public static idToTagInfo(state: RootState) {
        return (idArr: string[] | undefined): TagInfo[] => {
            if (idArr === undefined) {
                return [];
            }
            const tagInfos = [] as TagInfo[];
            idArr.forEach((id: string) => {
                const tagInfo = StateUtility.tagInfo(state)(id);
                // タグ削除した場合には、undefinedが返るので、削除されたものは除外
                if (tagInfo !== undefined) {
                    tagInfos.push(tagInfo);
                }
            });
            return tagInfos;
        }
    }

    public static sortedTagInfo(tags: TagInfo[]): TagInfo[] {
        const list = [] as TagInfo[];
        Array.prototype.push.apply(list, tags);
        list.sort((a, b) => {
            if (a.order !== undefined && b.order !== undefined) {
                return a.order - b.order;
            }
            if (a.order !== undefined && b.order === undefined) {
                return -1;
            }
            if (a.order === undefined && b.order !== undefined) {
                return 1;
            }
            return a.name.localeCompare(b.name);
        });
        return list;
    }

    /**
     * 主題図で使用するタグ情報を返す
     * @param state 
     */
    public static themeTags(state: RootState): TagInfo[] {
        const tags = Object.values(state.infoReducer.tags).filter((tag) => {
            return tag.useTheme === undefined ? true : tag.useTheme;
        });
        return this.sortedTagInfo(tags);
    }

    /**
     * 指定の情報が参照権限があるかどうか返す
     * @param info 
     */
    public static isAuthableInfo(state: RootState) {
        return (info: FeatureInfo): boolean => {
            // authGroupsを抽出する
            const authGroups = [] as string[];
            info.tags?.forEach((tagId) => {
                const tag = state.infoReducer.tags[tagId];
                if (tag === undefined) {
                    return;
                }
                tag.authGroups?.forEach((groupId) => {
                    if (authGroups.indexOf(groupId) === -1) {
                        authGroups.push(groupId);
                    }
                })
            });
            if (authGroups.length === 0) {
                // authGroupsを持つタグが存在しない場合は、参照可能
                return true;
            }
            // ユーザがauthGroupsのいずれかのグループに属していれば、参照可能
            const user = state.systemReducer.users[state.systemReducer.signInUserId as string];
            if (user === undefined) {
                return false;
            }
            return authGroups.some((groupId) => {
                const userGroups = user?.groups as string[];
                return userGroups.indexOf(groupId) !== -1;
            });
        }
    }
}