import * as firebase from 'firebase/app';
import "firebase/firestore";
import { TagInfo, VillageUserInfo, VillageUserInfoStore, ImageInfo, FacebookGroupEventInfoResult, SourceInfo, VillageInfo, UserInfoStore, StructureImageDefine, Feature, FeatureWithOperation } from '../types/types';
import { DEFAULT_TAG_COLOR } from '../styles/constants';
import axios from 'axios';
import { FeatureInfo, FeatureInfoContainDel } from '../types/info';
import { FeatureDocItem, FeatureDocItemStore, FeatureDocRegistItem, FeatureHistoryDocItemStore, TagsDocItem } from '../types/feature';
import * as CommonUtility from './CommonUtility';
import dayjs from 'dayjs';
import { MenuDefine } from '../components/menu/types';

const ROOT_COLLECTION = 'village';

export enum Operation {
    CREATE = 'CREATE',
    UPDATE = 'UPDATE',
    RENAME = 'RENAME',
    DELETE = 'DELETE',
    MOVE = 'MOVE',
}

let villageId = '';

export function setVillageId(id: string) {
    villageId = id;
}

/**
 * 指定の村が存在するか返す
 * @param id 村ID
 * @return 村情報。存在しない場合、undefined
 */
export async function existVillage(id: string): Promise<VillageInfo | undefined> {
    const doc = await firebase.firestore().collection(ROOT_COLLECTION).doc(id).get();
    if (!doc.exists) {
        return undefined;
    }
    const data = doc.data();
    return {
        disclosure: (data as any).disclosure === undefined ? 'hidden' : (data as any).disclosure,
        name: (data as any).name,
    };
}

export function getVillageDoc() {
    return firebase.firestore().collection(ROOT_COLLECTION).doc(villageId);
}

export function getUsersCollection() {
    return firebase.firestore().collection('users');
}

export function getFeatureCollection() {
    return getVillageDoc().collection('feature');
}

function getFeatureHistoryCollection(featureId: string) {
    return getFeatureCollection().doc(featureId).collection('history');
}

/**
 * Featureドキュメントに指定の情報を新規登録する
 * @param item 登録する情報
 * @return 登録したFeatureID
 */
export async function registFeature(item: FeatureDocRegistItem): Promise<string> {
    const geoJson = JSON.stringify(item.geoJson);
    const name = item.name;
    const date = firebase.firestore.FieldValue.serverTimestamp();
    const user = (firebase.auth().currentUser as firebase.User).uid;
    const ref = await getFeatureCollection().add({
        type: item.type,
        parent: item.parent,
        geoJson,
        name,
        date,
        user,
        delete: false,
    } as FeatureDocItemStore);

    // 履歴テーブルに登録
    await getFeatureHistoryCollection(ref.id).add({
        geoJson,
        name,
        date,
        user,
        operation: Operation.CREATE,
    });
    return ref.id;
}

export async function updateFeature(id: string, geoJson: GeoJSON.Feature | GeoJSON.Feature[]) {
    const doc = await getFeatureCollection().doc(id).get();
    if (!doc.exists) {
        console.warn('更新対象なし', id);
        return;
    }
    const data = doc.data();
    if (data === undefined) {
        console.warn('更新対象なし', id);
        return;
    }
    const myGeoJson = JSON.stringify(geoJson);
    const date = firebase.firestore.FieldValue.serverTimestamp();
    const user = (firebase.auth().currentUser as firebase.User).uid;
    data.geoJson = myGeoJson;
    data.date = date;
    data.user = user;

    // データ更新
    await getFeatureCollection().doc(id).set(data, {merge: true});

    // 履歴に情報追加
    await getFeatureHistoryCollection(id).add({
        geoJson: myGeoJson,
        name: data.name,
        date,
        user,
        operation: Operation.MOVE,
    });
}

/**
 * 指定の地物を削除する。属する情報、子地物も併せて削除する。
 * @param id 
 */
export async function removeFeature(id: string) {
    // 子地物を削除
    const children = await getFeatureCollection().where('parent', '==', id).get();
    children.forEach((child) => {
        removeFeature(child.id);
    });

    // 自身を削除
    const doc = await getFeatureCollection().doc(id).get();
    if (!doc.exists) {
        console.warn('削除対象なし', id);
        return;
    }
    const data = doc.data();
    if (data === undefined) {
        return;
    }
    const geoJson = data.geoJson;
    const date = firebase.firestore.FieldValue.serverTimestamp();
    const user = (firebase.auth().currentUser as firebase.User).uid;

    data.date = date;
    data.user = user;
    data.delete = true;
    await getFeatureCollection().doc(id).set(data);

    // 履歴テーブルに登録
    await getFeatureHistoryCollection(id).add({
        geoJson,
        name: data.name,
        date,
        user,
        operation: Operation.DELETE,
    });

    // 属する情報を削除
    doAction('delete_allinfo', {
        featureId: id,
    });
}

export function parseFeatureDocItemStore(storeItem: FeatureDocItemStore): FeatureDocItem {
    return {
        type: storeItem.type,
        parent: storeItem.parent,
        geoJson: JSON.parse(storeItem.geoJson),
        name: storeItem.name,
        user: storeItem.user,
        date: storeItem.date.toDate(),
    };
}

/**
 * 地物情報を返すクエリ
 */
export function getFeatureQuery() {
    return getFeatureCollection().where('delete', '==', false);
}

/**
 * 過去も含めた地物情報を返す
 */
export async function getAllHistoryFeature(): Promise<FeatureWithOperation[]> {
    const docs = await getFeatureCollection().get();
    let result = [] as FeatureWithOperation[];

    await Promise.all(docs.docs.map(async (doc) => {
        const data = doc.data() as FeatureDocItemStore;
        // const item = parseFeatureDocItemStore(data);
        // result.push(Object.assign({}, item, { id: doc.id }));
        // 履歴を新しいもの順に取得
        const historyDocs = await getFeatureHistoryCollection(doc.id).orderBy('date', 'desc').get();
        let beforeDate = null as firebase.firestore.Timestamp | null;
        await Promise.all(historyDocs.docs.map(async (historyDoc) => {
            const historyData = historyDoc.data() as FeatureHistoryDocItemStore;
            if (historyData.operation === Operation.DELETE) {
                beforeDate = historyData.date;
                return;
            }
            const featureData = {
                type: data.type,
                parent: data.parent,
                geoJson: historyData.geoJson,
                name: historyData.name,
                date: historyData.date,
            } as FeatureDocItemStore;
            const item = parseFeatureDocItemStore(featureData);
            result.push(Object.assign({}, item, { 
                id: doc.id,
                endDate: beforeDate === null ? undefined :beforeDate.toDate(),
                operation: historyData.operation,
            }));
            beforeDate = historyData.date;
        }));
    }));

    // 時間順にソート
    result = result.sort((a: FeatureWithOperation, b: FeatureWithOperation) => {
        return a.date.valueOf() - b.date.valueOf();
    });

    return result;
}

/**
 * 指定のタグを保持する地物情報を返す
 *
 * @static
 * @returns {{[featureId: string]: string[]}} key: 建物ID, value: 情報ID一覧
 * @memberof FirestoreUtil
 */
export async function getTaggedFeatureInfoMap(tag: TagInfo): Promise<{ [featureId: string]: number[] }> {
    const result = await doAction('get_tagged_feature', {
        tagId: tag.id
    });
    return result;
}

/**
 * 指定のキーワードを保持する地物情報を返す
 * @param keyword 
 */
export async function searchByKeyword(keyword: string): Promise<{ [featureId: string]: number[] }> {
    const result = await doAction('search_by_keyword', {
        keyword,
    });
    return result;
}

type FeatureInfoDB = FeatureInfo & {
    eventStartDate: string | null;
    eventEndDate: string | null;
}
/**
 * 指定の地物の情報を返すクエリ
 * @param featureId 地物ID
 */
export async function getFeatureInfo(featureId: string): Promise<FeatureInfo[]> {
    try {
        const infos = await doAction('get_info',{
            feature_id: featureId,
        }) as FeatureInfoDB[];
        // イベント日時型をconvert
        return infos.map((info): FeatureInfo => {
            const newInfo = Object.assign({}, info) as FeatureInfo;
            if (info.eventStartDate !== null) {
                newInfo.eventStartDate = CommonUtility.convertUtcDateTimeStrToLocaleDate(info.eventStartDate)
            } else {
                newInfo.eventStartDate = undefined;
            }
            if (info.eventEndDate !== null) {
                newInfo.eventEndDate = CommonUtility.convertUtcDateTimeStrToLocaleDate(info.eventEndDate)
            } else {
                newInfo.eventEndDate = undefined;
            }
            return newInfo;
        })
    } catch(e) {
        console.warn('情報取得に失敗しました', e);
        return [];
    }
}

/**
 * イベント情報を保持する情報IDを返す
 */
export async function getEventInfo(): Promise<{[date_str: string]: Array<{infoId: number, featureId: string}>}> {
    type ResultDB = {
        id: number;
        featureId: string;
        eventStartDate: string;
        eventEndDate: string | null;
    }

    try {
        const infos = await doAction('get_event_infos', {}) as ResultDB[];

        // イベント日付のマップに加工
        const result = {} as {[date_str: string]: Array<{infoId: number, featureId: string}>};
        const pushResultFunc = (d: Date, infoId: number, featureId: string) => {
            const dateStr = CommonUtility.convertDateToDateStr(d);
            if(result[dateStr] === undefined) {
                result[dateStr] = [];
            }
            result[dateStr].push({
                infoId,
                featureId,
            });
        }
        infos.forEach((info) => {
            // イベント日時型をconvert
            const start_date = CommonUtility.convertUtcDateTimeStrToLocaleDate(info.eventStartDate)
            let end_date = null;
            if (info.eventEndDate !== null) {
                end_date = CommonUtility.convertUtcDateTimeStrToLocaleDate(info.eventEndDate)
            } else {
                end_date = start_date;
            }

            if (start_date < end_date) {
                // 日付をまたがるイベントの場合は、期間内の日付全てと紐づける
                let current_date = start_date;
                while(current_date <= end_date) {
                    pushResultFunc(current_date, info.id, info.featureId);
                    current_date = dayjs(current_date).add(1, 'day').toDate();
                }
            } else {
                pushResultFunc(start_date, info.id, info.featureId);
            }
        });
        return result;

    } catch(e) {
        console.warn('イベント情報の取得に失敗');
        return {};
    }
}
/**
 * 指定の情報のオリジナル画像を取得する
 * @param infoId 情報ID
 */
export async function getOriginalImage(infoId: number): Promise<string> {
    try {
        const result = await doAction('get_original_image', {
            infoId,
        });
        if (result.image_original !== undefined) {
            return result.image_original;
        } else {
            console.warn('画像取得に失敗しました');
            return '';
        }
    } catch(e) {
        console.warn('画像取得に失敗しました', e);
        return '';
    }
}

/**
 * 指定の建物に登録されている情報数を取得する
 * @param featureIdArr 取得対象の建物ID配列
 */
export async function getInfoNum(featureIdArr: string[]): Promise<{[id: string]: number}> {
    try {
        const result = await doAction('get_infonum', {
            featureIdArr,
        }) as {[id: string]: number};
        return result;

    } catch(e) {
        console.warn('情報取得に失敗しました', e);
        return {};
    }
}

/**
 * Facebookのイベント情報を取得して返す
 */
export async function getFacebookGroupEventInfo(): Promise<FacebookGroupEventInfoResult> {
    try {
        const token = await CommonUtility.getFacebookAccessToken();
        console.log('token', token);
        const result = await doAction('get_fb', {
            token,
        }) as FacebookGroupEventInfoResult;
        result.token = token;
        return result;
    } catch(e) {
        throw Error('Facebookからの情報取得に失敗しました。');
    }
}

export async function getMenuDefine(): Promise<MenuDefine[]> {
    try {
        return doAction('get_menu', {});
    } catch(e) {
        throw Error('メニュー定義の取得に失敗しました。');
    }
}

async function doAction(actionName: string, param: any): Promise<any> {
    const token = await firebase.auth().currentUser?.getIdToken();
    const uid = firebase.auth().currentUser?.uid;
    if (token === undefined) {
        console.warn('トークンが取得できません');
        // return;
    }
    const result = await axios.post('api/action.py', {
        params: {
            token,
            uid,
            village: villageId,
            action: actionName,
            param,
        }
    });
    const data = result.data;
    if (data.ret !== 'ok') {
        throw Error(data.errmsg);
    }
    return data.result;
}

/**
 * 看板情報取得
 */
export async function getPopupInfo(): Promise<PopupInfoDB> {
    try {
        return await doAction('get_popupinfo', {}) as PopupInfoDB;

    } catch(e) {
        console.warn('看板情報取得処理に失敗しました', e);
        return {
            current: '',
            record: []
        };
    }
}

/**
 * 看板情報差分取得
 * @param lastUpdateDate {Date} この日時以降に更新された情報のみを返す
 */
export async function getPopupInfoDiff(lastUpdateDate: string): Promise<PopupInfoDB> {
    try {
        return await doAction('get_popupinfo_diff', {lastUpdateDate}) as PopupInfoDB;

    } catch(e) {
        console.warn('看板情報取得処理に失敗しました', e);
        return {
            current: '',
            record: []
        };
    }
}

export function getTagsCollection() {
    return getVillageDoc().collection('tags');
}

/**
 * 各タグの保持する情報数を返す
 */
export async function getTagsInfoNum(): Promise<{[id: string]: number}> {
    try {
        return await doAction('get_tag_infonum', {});

    } catch(e) {
        console.warn('タグの保持する情報数の取得に失敗しました。', e);
        return {};
    }
}

/**
 * 指定のタグ情報を削除する
 * @param tagIds 削除対象のタグID一覧
 */
export async function removeTag(tagIds: string[]): Promise<void> {
    try {
        // サーバから削除
        await doAction('delete_tags', {
            tag_id: tagIds,
        });
        // firebaseから削除
        await Promise.all(tagIds.map(async (tagId) => {
            await getTagsCollection().doc(tagId).update({delete: true});
        }));

    } catch(e) {
        console.warn('タグ削除に失敗しました。', e);
    }
}

/**
 * Publicの村情報を返す
 * @return 村情報マップ（key=id, value=村名)
 */
export async function getPublicVillages(): Promise<{ [id: string]: string }> {
    const collection = await firebase.firestore().collection(ROOT_COLLECTION).where("disclosure", "==", "public").get();
    const villages = {} as { [id: string]: string };
    await Promise.all(collection.docs.map(async (doc): Promise<any> => {
        const id = doc.id;
        const name = doc.data().name;
        villages[id] = name;
    }));
    return villages;
}

/**
 * ユーザが登録済みの村情報を返す
 * @return 村情報マップ（key=id, value=村名)
 */
export async function getRegistedVillages(): Promise<{ [id: string]: string }> {
    const uid = firebase.auth().currentUser?.uid;
    if (uid === undefined) {
        return {};
    }
    const collection = await firebase.firestore().collection(ROOT_COLLECTION).get();

    const villages = {} as { [id: string]: string };
    await Promise.all(collection.docs.map(async (doc): Promise<any> => {
        const userDoc = await doc.ref.collection('user').doc(uid).get();
        if (!userDoc.exists) {
            return;
        }
        const id = doc.id;
        const name = doc.data().name;
        villages[id] = name;
    }));
    return villages;
}

/**
 * 情報登録
 *
 * @param {string} featureId
 * @param {string} title
 * @param {string} contents
 * @param {TagInfo[]} tags
 */
export async function registFeatureInfo(featureId: string, info: FeatureInfoForRegist) {
    // タグ登録
    const tagInfos = await registTags(info.tags);
    const tagIds = tagInfos.map((tag: TagInfo) => {
        return tag.id;
    });
    // 情報登録
    try {
        const param = convertInfo(info, tagIds);
        if (info.image !== undefined) {
            Object.assign(param, {
                image_thumb: info.image.thumb,
                image_original: info.image.original,
            });
        }
        param.feature_id = featureId;
        await doAction('regist_info', param);

    } catch(e) {
        console.warn('情報登録に失敗しました', e);
    }
}

async function registTags(tags: TagInfo[]): Promise<TagInfo[]> {
    // 未登録のタグを登録
    const targetTags = await Promise.all(tags.map(async (tag: TagInfo): Promise<TagInfo> => {
        const newInfo = Object.assign({}, tag) as TagInfo;
        if (tag.id.length > 0) {
            return newInfo;
        }
        const ref = await getTagsCollection().add({
            name: tag.name,
            color: tag.color === undefined ? DEFAULT_TAG_COLOR : tag.color,
        } as TagsDocItem);
        newInfo.id = ref.id;
        return newInfo;
    }));

    return targetTags;

}

/**
 * タグ情報を更新する
 * @param tags 
 */
export async function updateTags(tags: TagInfo[]) {
    await Promise.all(tags.map(async (tag: TagInfo) => {
        const item = {
            name: tag.name,
            color: tag.color === undefined ? DEFAULT_TAG_COLOR : tag.color,
            authGroups: tag.authGroups === undefined ? [] : tag.authGroups,
            useTheme: tag.useTheme === undefined ? true : tag.useTheme,
        } as TagsDocItem;
        if (tag.order !== undefined) {
            item.order = tag.order;
        }
        return getTagsCollection().doc(tag.id).set(item);
    }))
}

function convertInfo(info: FeatureInfoForRegist, tagIds: string[]): any {
    const param = {
        title: info.title,
        contents: info.contents,
        tags: tagIds,
        source: info.source,
    };
    if (info.eventStartDate !== undefined) {
        Object.assign(param, {
            event_start_date: info.eventStartDate.toLocaleString(),
        });
    }
    if (info.eventEndDate !== undefined) {
        Object.assign(param, {
            event_end_date: info.eventEndDate.toLocaleString(),
        });
    }
    return param;
}
/**
 * 情報更新
 *
 * @static
 * @param {string} featureId
 * @param {number} infoId
 * @param {FeatureInfoForRegist} info
 * @memberof FirestoreUtil
 */
export async function updateFeatureInfo(featureId: string, infoId: number, info: FeatureInfoForRegist) {
    // タグ登録
    const tagInfos = await registTags(info.tags);

    const tagIds = tagInfos.map((tag: TagInfo) => {
        return tag.id;
    });

    // 情報更新
    const param = convertInfo(info, tagIds);
    param.info_id = infoId;
    if (info.image?.thumb === undefined || info.image.thumb.length === 0) {
        // 画像削除
        param.image_thumb = '';
        param.image_original = '';
    } else if(info.image?.original !== undefined && info.image?.original.length > 0) {
        // 画像更新
        param.image_thumb = info.image.thumb;
        param.image_original = info.image.original;
    }
    try {
        await doAction('update_info', param);

    } catch(e) {
        console.warn('情報更新に失敗しました', e);
    }
}

export async function deleteFeatureInfo(featureId: string, infoId: number) {
    try {
        await doAction('delete_info', {
            info_id: infoId,
        });

    } catch(e) {
        console.warn('情報削除に失敗しました', e);
    }

}

export async function updateFeatureName(id: string, name: string) {
    const doc = await getFeatureCollection().doc(id).get();
    if (!doc.exists) {
        console.warn('更新対象なし', id);
        return;
    }
    const data = doc.data();
    if (data === undefined) {
        console.warn('更新対象なし', id);
        return;
    }
    const date = firebase.firestore.FieldValue.serverTimestamp();
    const user = (firebase.auth().currentUser as firebase.User).uid;
    data.name = name;
    data.date = date;
    data.user = user;

    // データ更新
    await getFeatureCollection().doc(id).set(data);

    // 履歴に情報追加
    await getFeatureHistoryCollection(id).add({
        geoJson: data.geoJson,
        name,
        date,
        user,
        operation: Operation.RENAME,
    });
}

export function getVillageUserCollection() {
    return getVillageDoc().collection('user');
}

export function getGroupCollection() {
    return getVillageDoc().collection('group');
}

export function getIconHookDoc() {
    return getVillageDoc().collection('hooks').doc('icon');
}

export async function updateUserInfo(userInfo: VillageUserInfo) {
    await getVillageUserCollection().doc(userInfo.id).set({
        name: userInfo.name,
        groups: userInfo.groups === undefined ? [] : userInfo.groups,
        special: userInfo.special === undefined ? false : userInfo.special,
        view: userInfo.view === undefined ? [0, 0] : userInfo.view,
    } as VillageUserInfoStore);
}

/**
 * メッセージトークンを登録する
 * @param userId ユーザID
 * @param token メッセージトークン
 * @return 登録した場合、true。既に登録済みだった場合、false。
 */
export async function registMessagingToken(userId: string, token: string): Promise<boolean> {
    const userInfo = await getUsersCollection().doc(userId).get();
    if(!userInfo.exists) {
        await getUsersCollection().doc(userId).set({
            messagingToken: [token]
        });
        return true;
    }
    const data = userInfo.data() as UserInfoStore;
    const tokens = data.messagingToken === undefined ? [] : data.messagingToken;
    if (tokens.indexOf(token) !== -1) {
        // 登録済みの場合は何もしない
        return false;
    }
    await getUsersCollection().doc(userId).update({
        messagingToken: tokens.concat(token)
    });
    return true;
}

export async function isRegistedUser(uid: string): Promise<boolean> {
    const doc = await getVillageUserCollection().doc(uid).get();
    return doc.exists;
}

/**
 * 指定の情報を指定の情報の前後に移動する
 * @param featureId 情報格納feature
 * @param targetId 並べ替える対象
 * @param moveAtId この対象の前後にtargetを移動する
 */
export async function orderChange(featureId: string, targetId: number, moveAtId: number) {
    await doAction('update_order', {
        featureId,
        targetId,
        moveAtId,
    });
}

/**
 * 情報を指定の建物に移動する
 * @param infoId 移動対象の情報ID
 * @param toFeatureId 移動先の建物ID
 */
export async function moveInfo(infoId: number, toFeatureId: string) {
    await doAction('move_info', {
        infoId,
        toFeatureId,
    });
}

/**
 * 情報のタイトルを取得する
 * @param infoId 取得対象の情報ID
 */
export async function getInfoTitle(infoId: number) {
    const result = await doAction('get_info_title', {
        info_id: infoId,
    });
    if(result === null) {
        // 情報削除時の対応
        return 'deleted';
    }
    return result.title;
}

export async function getInfoFeatureId(infoId: number): Promise<string | undefined> {
    const result = await doAction('get_info_feature_id', {
        info_id: infoId,
    });
    if(result === null) {
        return undefined;
    }
    return result.feature_id;
}

export async function getUnreadInfoFeature(): Promise<{[featureId: string]: number[]}> {
    const result = await doAction('get_unreadinfo_feature', {});
    return result;
}

export async function registIcon(image: ImageInfo): Promise<void> {
    const result = await doAction('regist_icon', {
        image: image.original,
    });
    return result;
}

export async function updateIcon(id: string, image: ImageInfo): Promise<void> {
    const result = await doAction('update_icon', {
        id,
        image: image.original,
    });
    return result;
}

export async function removeIcon(id: string): Promise<void> {
    const result = await doAction('remove_icon', {
        id,
    });
    return result;
}

export async function getIconList(): Promise<StructureImageDefine[]> {
    const result = await doAction('get_icon_list', {});
    return (result as StructureImageDefine[]).map((item) => {
        item.original = true;
        return item;
    });
}

export function isMyVillageDoc(doc: firebase.firestore.DocumentSnapshot): boolean {
    // Villageコード取得
    let current = doc.ref;
    while(true) {
        const parent = current.parent;
        if (parent.id === 'village') {
            break;
        }
        if(parent.parent === null) {
            break;
        }
        current = parent.parent;
    }
    return current.id === villageId;
}

export async function getCurrentUserEmail(): Promise<string> {
    // Firestoreから取得
    const uid = firebase.auth().currentUser?.uid;
    if (uid === undefined) {
        return '';
    }
    const doc = await getUsersCollection().doc(uid).get();
    if (!doc.exists) {
        return '';
    }
    const data = doc.data();
    const email1 = (data as any).email as string | undefined;
    if (email1 !== undefined) {
        return email1;
    }

    // Firestoreに登録されていない場合は、認証情報を返す
    const email2 = firebase.auth().currentUser?.email;
    return email2 === undefined || email2 === null ? '' : email2;
}

export async function updateCurrentUserEmail(email: string): Promise<void> {
    const uid = firebase.auth().currentUser?.uid;
    if (uid === undefined) {
        return;
    }
    await getUsersCollection().doc(uid).set({
        email,
    }, {
        merge: true,
    });
}

// 処理内で持ちまわる型
/**
 * 情報（登録用）
 */
type FeatureInfoForRegist = {
    title: string;
    contents: string;
    image: ImageInfo | undefined;
    tags: TagInfo[];
    eventStartDate: Date | undefined;
    eventEndDate: Date | undefined;
    source: SourceInfo;
}
type PopupInfoDB = {
    current: string;    // ポップアップ情報取得時間
    record: FeatureInfoContainDel[];
}
