// 管理画面
import React, { useState } from 'react'
import { DispatchProps, VillageUserInfo, GroupInfo, TagInfo } from '../../types/types';
import { RootState } from '../../store';
import { connect } from 'react-redux';
import { Modal, Button, Tabs, Tab } from 'react-bootstrap';
import './ManageDialog.scss';
import * as dbAccessor from "../../util/DbAccessor";
import ManageAuthTab from './ManageAuthTab';
import ManageTagTab from './ManageTagTab';
import * as infoThunks from '../../store/info/thunks';
import { KickCommand } from '../../types/operation';
import { useCommandCatch } from '../../util/customHooks';

type OwnProps = {
    /** 親からもらうprops定義 */
}
type StateProps = OwnProps & {
    /** ストアの内容から生成するprops定義 */
    groups: GroupInfo[];
}
type Props = StateProps & DispatchProps;

const mapStateToProps = (state: RootState, ownProps: OwnProps): StateProps => ({
    ...ownProps,
    groups: Object.values(state.systemReducer.groups),
});

function ManageDialog(props: Props) {
    const [show,  setShow] = useState(false);
    const [showMessage, setShowMessage] = useState(false);
    const [userMap, setUserMap] = useState<{ [id: string]: VillageUserInfo }>({});
    const [tagMap, setTagMap] = useState<{ [id: string]: TagInfo }>({});

    useCommandCatch(KickCommand.ShowManageDialog, () => setShow(true));

    const handleClose = () => {
        setShow(false);
    }

    const onUserGroupChanged = (user: VillageUserInfo) => {
        const newUserMap = Object.assign({}, userMap, {
            [user.id]: user,
        });
        setUserMap(newUserMap);
    }

    const onTagChanged = (tag: TagInfo) => {
        const newTagMap = Object.assign({}, tagMap, {
            [tag.id]: tag,
        });
        setTagMap(newTagMap);
    }

    const onUpdateBtnClicked = async() => {
        // 更新したユーザ情報をDB登録
        await Promise.all(Object.values(userMap).map(async (user): Promise<void> => {
            await dbAccessor.updateUserInfo(user);
        }));
        // 更新したタグ情報をDB登録
        await Promise.all(Object.values(tagMap).map(async (tag): Promise<void> => {
            await props.dispatch(infoThunks.updateTagsInfoThunk(tag));
        }));
        setShowMessage(true);
        setTimeout(() => {
            setShowMessage(false);
        }, 1500);
    }


    return (
        <Modal className="AuthManageDialog" show={show} onHide={() => handleClose()}>
            <Modal.Header closeButton>
                <Modal.Title>管理画面</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Tabs defaultActiveKey="group" id="uncontrolled-tab-example">
                    <Tab eventKey="group" title="グループ">
                        <ManageAuthTab change={(user) => onUserGroupChanged(user)} />
                    </Tab>
                    <Tab eventKey="tag" title="タグ">
                        <ManageTagTab change={(tag) => onTagChanged(tag)} />
                    </Tab>
                </Tabs>

            </Modal.Body>
            <Modal.Footer>
                {showMessage &&
                    <span>更新しました</span>
                }
                <Button variant="secondary" onClick={() => handleClose()}>
                    Close
                </Button>
                <Button variant="primary" onClick={() => onUpdateBtnClicked()}>
                    更新
            </Button>
            </Modal.Footer>
        </Modal>
    );

}
export default connect(mapStateToProps)(ManageDialog);
