import React from 'react'
import { Modal, Button } from 'react-bootstrap';
import { connect } from "react-redux";
import { RootState } from '../../store';
import { ConfirmDialogParam, DialogMode, DialogResult } from '../../types/operation';
import { DispatchProps } from '../../types/types';
import * as operationActions from '../../store/operation/actions';

type OwnProps = {
    /** 親からもらうprops定義 */
}
type StateProps = OwnProps & {
    /** ストアの内容から生成するprops定義 */
    show: boolean;
    confirmParam: ConfirmDialogParam | undefined;
}
type Props = StateProps & DispatchProps;

const mapStateToProps = (state: RootState, ownProps: OwnProps): StateProps => ({
    ...ownProps,
    show: state.operationReducer.confirmDialogParam !== undefined,
    confirmParam: state.operationReducer.confirmDialogParam,
});

function ConfirmDialog(props: Props) {
    const title = ((): string => {
        if (props.confirmParam === undefined || props.confirmParam.title === undefined) {
            return '確認';
        } else {
            return props.confirmParam.title;
        }
    })();

    const message = props.confirmParam === undefined ? '' : props.confirmParam.message;

    const handleClose = (result: DialogResult) => {
        props.dispatch(operationActions.setConfirmDialogResultAction(result));
    }

    let btns = null;
    if (props.confirmParam !== undefined) {
        if (props.confirmParam.mode === DialogMode.OkOnly) {
            btns = (
                <Button variant="primary" onClick={() => handleClose(DialogResult.OK)}>
                    OK
                </Button>
            );
        } else {
            btns = (
                <React.Fragment>
                    <Button variant="primary" onClick={() => handleClose(DialogResult.OK)}>
                        OK
                    </Button>
                    <Button variant="secondary" onClick={() => handleClose(DialogResult.CANCEL)}>
                        Cancel
                    </Button>
                </React.Fragment>
            );
        }
    }

    return (
        <Modal show={props.show}>
            <Modal.Header>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {message}
            </Modal.Body>
            <Modal.Footer>
                {btns}
            </Modal.Footer>
        </Modal>
    );
}
export default connect(mapStateToProps)(ConfirmDialog);
