import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Modal } from 'react-bootstrap';
import styles from './InfoViewDialog.module.scss';
import * as dbAccessor from "../../../util/DbAccessor";
import { FeatureInfo } from '../../../types/info';
import { useInfoFormat } from '../../../hooks/InfoFormat';

type Props = {
    /** 親からもらうprops定義 */
    info: FeatureInfo;
    close:() => void;
}

export default function InfoViewDialog(props: Props) {
    const [imgSrc, setImgSrc] = useState(props.info.imageThumb);  // オリジナル画像取得するまではサムネイル表示

    const title = useMemo((): string => {
        return props.info.title;
    }, [props.info.title]);

    const description = useMemo((): string => {
        return props.info.contents;
    }, [props.info.contents]);

    const handleClose = useCallback(() => {
        props.close();
    }, [props]);

    useEffect(() => {
        // オリジナル画像取得
        dbAccessor.getOriginalImage(props.info.id)
        .then((myImgSrc) => {
            if (myImgSrc.length === 0) {
                return;
            }
            setImgSrc(myImgSrc);
        })
    }, [props.info.id]);

    const mydescription = useInfoFormat(description, 'view');
    return (
        <Modal className={styles.InfoViewDialog} show={true} onHide={handleClose} scrollable>
            <Modal.Header closeButton>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body className={styles.modalBody}>
                <div className={styles.imageArea}>
                    <div className={styles.image}>
                        {imgSrc.length > 0 &&
                            <img src={imgSrc} alt="infoimage" draggable={false} />
                        }
                    </div>
                </div>
                <div className={styles.description}>
                    {mydescription}
                </div>

            </Modal.Body>
        </Modal>
    );
}
