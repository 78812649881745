import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { FacebookEventInfo, FacebookGroupEventInfo } from '../../../types/types';
import * as dbAccessor from "../../../util/DbAccessor";
import FacebookEventGroupItem from './FacebookEventGroupItem';
import styles from './FacebookEventList.module.scss';

type Props = {
    /** 親からもらうprops定義 */
    cancel: () => void; // キャンセル時のコールバック
    select: (token: string, item: FacebookEventInfo) => void;  // アイテム選択した場合のコールバック
}

enum Stage {
    GetInfo,    // 情報取得中
    ShowInfo,   // 情報取得後
    Error,      // 情報取得失敗時
}

/**
 * Facebookのイベント情報一覧。
 * 取り込むイベントを選択したら呼び出し元に返す
 * @param props 
 */
export default function FacebookEventList(props: Props) {
    const [stage, setStage] = useState(Stage.GetInfo);
    const [token, setToken] = useState('');
    const [groups, setGroups] = useState([] as FacebookGroupEventInfo[]);
    const [selectItem, setSelectItem] = useState(null as FacebookEventInfo | null);

    useEffect(() => {
        dbAccessor.getFacebookGroupEventInfo()
        .then((result) => {
            setToken(result.token);
            if (result.groups !== undefined) {
                setGroups(result.groups);
            }
            setStage(Stage.ShowInfo);
        })
        .catch((e) => {
            // Facebookからの情報取得に失敗した場合、
            // 失敗した旨を表示して、OKボタン表示
            console.warn(e);
            setStage(Stage.Error);
        });
    }, []);
    
    const onClick = (item: FacebookEventInfo) => {
        setSelectItem(item);
    }

    const onCancel = () => {
        props.cancel();
    }

    const onSelectBtnClicked = () => {
        if (selectItem === null) {
            console.warn('selectItem is null');
            return;
        }
        props.select(token, selectItem);
    }

    let body;
    let footer;
    switch(stage) {
        case Stage.GetInfo:
            body = <div>Facebookから情報取得中...</div>
            footer = (
                <button className="btn btn-secondary" onClick={onCancel}>Cancel</button>
            );
            break;
        case Stage.ShowInfo:
            if (groups.length > 0) {
                body = (
                    <React.Fragment>
                        <p className="text-dark ">公開グループとあなたが管理している秘密グループのイベント情報を表示しています。</p>
                        <ul className={`list-group ${styles.mylist}`}>
                            {groups.map((group) => {
                                return (
                                    <React.Fragment key={group.id}>
                                        <FacebookEventGroupItem group={group} selectId={selectItem===null?null:selectItem.id} click={onClick} />
                                    </React.Fragment>
                                )
                            })}
                        </ul>
                    </React.Fragment>
                );
                footer = (
                    <React.Fragment>
                        <button className="btn btn-primary" disabled={selectItem===null} onClick={onSelectBtnClicked}>選択</button>
                        <button className="btn btn-secondary" onClick={onCancel}>Cancel</button>
                    </React.Fragment>
                );
            } else {
                body = (
                    <React.Fragment>
                        <p>イベント情報がありません。</p>
                        <p className="text-dark ">公開グループとあなたが管理している秘密グループのイベント情報のみ表示可能です。</p>
                    </React.Fragment>
                );
                footer = (
                    <React.Fragment>
                        <button className="btn btn-secondary" onClick={onCancel}>Cancel</button>
                    </React.Fragment>
                );
            }
            break;
        case Stage.Error:
            body = <div>Facebookからの情報取得に失敗しました。<br/>リトライしてうまく行かなければ管理者へ連絡してください。</div>
            footer = (
                <button className="btn btn-secondary" onClick={onCancel}>戻る</button>
            );
            break;
    }
    return (
        <React.Fragment>
            <Modal.Header>
                <Modal.Title>Facebookイベント選択</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {body}
            </Modal.Body>
            <Modal.Footer>
                {footer}
            </Modal.Footer>
        </React.Fragment>
    );
}