import React, {ReactElement} from 'react';
import MediaQuery from 'react-responsive';

type Props = {
    children: ReactElement[];
}

const MyMediaQuery = (props: Props) => {
    return (
        <React.Fragment>
            {props.children}
        </React.Fragment>
    )
}

type ChildProps = {
    children: ReactElement;
}

MyMediaQuery.LongHeightDevice = (props: ChildProps) => {
    return (
        <MediaQuery query="(min-height: 601px)">
            {props.children}
        </MediaQuery>
    );
}
MyMediaQuery.ShortHeightDevice = (props: ChildProps) => {
    return (
        <MediaQuery query="(max-height: 600px)">
            {props.children}
        </MediaQuery>
    );
}
export default MyMediaQuery;
