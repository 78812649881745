import { Map } from 'ol';
import GeometryType from 'ol/geom/GeometryType';
import { Draw } from 'ol/interaction';
import { DrawEvent } from 'ol/interaction/Draw';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import React, { useEffect, useState } from 'react';
import { FeatureType } from '../../../types/types';
import { drawingLayerStyle } from '../style';
import PromptMessageBox from './PromptMessageBox';
import OlFeature from 'ol/Feature';
import MapUtil from '../MapUtil';
import * as dbAccessor from "../../../util/DbAccessor";
import styles from './DrawTopographyController.module.scss';

type Props = {
    map: Map;   // コントロール対象の地図
    drawFeatureType: FeatureType.EARTH | FeatureType.FOREST;
    close: () => void;  // 作図完了時のコールバック
}

// 描画状況
enum DrawStage {
    SELECTING_FEATURE,
    DRAWING,
}

const drawingSource = new VectorSource();

/**
 * 島、緑地の作図コントローラ
 */
export default function DrawTopographyController(props: Props) {
    const [drawStage, setDrawStage] = useState(DrawStage.SELECTING_FEATURE);
    const [draw, setDraw] = useState(new Draw({type: GeometryType.POLYGON}));
    const [geometryType, setGeometryType] = useState(GeometryType.POLYGON);

    useEffect(() => {
        const style = drawingLayerStyle(props.drawFeatureType);
        const drawingLayer = new VectorLayer({
            source: drawingSource,
            style,
        });
        props.map.addLayer(drawingLayer);

        return () => {
            props.map.removeLayer(drawingLayer);
        }
    }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []);

    // 描画図形選択後
    const onSelectFeature = (type: GeometryType) => {
        setGeometryType(type);
        // Drawインタラクション用意
        const newDraw =new Draw({
            source: drawingSource,
            type,
            style: drawingLayerStyle(props.drawFeatureType),
            clickTolerance: 12,
        });
        props.map.addInteraction(newDraw);
        newDraw.on('drawend', (event: DrawEvent) => {
            onDrawEnd(event.feature);
            newDraw.on('drawstart', (event: DrawEvent) => {
                // 円描画でダブルクリックすると、再び描画が始まってしまうので、ここでキャンセル
                newDraw.abortDrawing();
            })
        });

        setDraw(newDraw);

        setDrawStage(DrawStage.DRAWING);
    }

    const onSelectFeatureCanceled = () => {
        props.close();
    }

    const onDrawingCanceled = () => {
        // 書きかけ削除
        drawingSource.clear();
        // DrawインタラクションOff
        props.map.removeInteraction(draw);
        setDrawStage(DrawStage.SELECTING_FEATURE);
    }

    const onDrawEnd = async (feature: OlFeature) => {
        props.map.removeInteraction(draw);

        // DB登録
        const geoJson = MapUtil.createGeoJson(feature);
        await dbAccessor.registFeature({
            type: props.drawFeatureType,
            parent: null,
            geoJson,
            name: '',
        });

        props.close();
    }
    
    switch(drawStage) {
        case DrawStage.SELECTING_FEATURE:
            return (
                <PromptMessageBox message='形を選んでください.'
                    cancel={onSelectFeatureCanceled}>
                        <div className={styles.buttonArea}>
                            <button className="btn btn-light" onClick={()=>onSelectFeature(GeometryType.POLYGON)}><span className="icon-hexagon"></span></button>
                            <button className="btn btn-light" onClick={()=>onSelectFeature(GeometryType.CIRCLE)}>○</button>
                        </div>
                </PromptMessageBox>
            );
        case DrawStage.DRAWING:
            // 円描画の場合は、シングルクリックで終了
            const clickMsg = geometryType === GeometryType.POLYGON ? 'ダブルクリック' : 'クリック';
            return (
                <PromptMessageBox message={'地図上で作図してください\n（完了ボタンまたは' + clickMsg + 'で完了）'}
                    okname="完了" ok={draw.finishDrawing} cancel={onDrawingCanceled}
                />
            )

    }
}
