import React, { Component } from 'react'
import { DispatchProps, VillageUserInfo, GroupInfo } from '../../types/types';
import { RootState } from '../../store';
import { connect } from 'react-redux';

type OwnProps = {
    /** 親からもらうprops定義 */
    change: (user: VillageUserInfo) => void;   // ユーザ情報の権限変更された場合のイベントハンドラ
}
type StateProps = OwnProps & {
    /** ストアの内容から生成するprops定義 */
    userMap: { [id: string]: VillageUserInfo };
    groups: GroupInfo[];
}
type Props = StateProps & DispatchProps;

const mapStateToProps = (state: RootState, ownProps: OwnProps): StateProps => ({
    ...ownProps,
    userMap: state.systemReducer.users,
    groups: Object.values(state.systemReducer.groups),
});

type EditUserInfo = VillageUserInfo & {
    update?: boolean;
}

type State = {
    userMap: { [id: string]: EditUserInfo };
};

class ManageAuthTab extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            userMap: props.userMap,
        }
    }
    render() {
        return (
            <table>
                <thead>
                    <tr>
                        <th>ユーザ名</th>
                        {this.props.groups.map((group) => {
                            return <th key={group.id}>{group.name}</th>
                        })}
                    </tr>
                </thead>
                <tbody>
                    {Object.values(this.state.userMap).map((user) => {
                        return <tr key={user.id}>
                            <td>{user.name}</td>
                            {this.props.groups.map((group) => {
                                return <td key={group.id}>
                                    <input type="checkbox" checked={user.groups !== undefined && user.groups.indexOf(group.id) !== -1} onChange={this.onChange.bind(this, user.id, group.id)}></input>
                                </td>
                            })}
                        </tr>
                    })}
                </tbody>
            </table>
        );
    }

    componentDidUpdate(prevProps: Props, prevState: State) {
        if (prevProps.userMap !== this.props.userMap) {
            this.setState({
                userMap: this.props.userMap,
            })
        }
    }

    private onChange(userId: string, groupId: string, event: React.ChangeEvent<HTMLInputElement>) {
        const newUserInfo = JSON.parse(JSON.stringify(this.state.userMap[userId])) as EditUserInfo;
        if (newUserInfo.groups === undefined) {
            newUserInfo.groups = [];
        }
        const index = newUserInfo.groups.indexOf(groupId);
        if (index === -1) {
            // 未設定の場合
            newUserInfo.groups.push(groupId);
        } else {
            // 設定済みの場合、はずす
            newUserInfo.groups.splice(index, 1);
        }
        newUserInfo.update = true;
        this.setState((state) => {
            return {
                userMap: Object.assign({}, state.userMap, {
                    [userId]: newUserInfo,
                }),
            }
        });
        this.props.change(newUserInfo);
    }
}
export default connect(mapStateToProps)(ManageAuthTab);
