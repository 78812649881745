import React, { useCallback, useEffect, useState } from 'react';
import { KickCommand } from '../../types/operation';
import { useCommandCatch } from '../../util/customHooks';
import DrawStructureController from './draw/DrawStructureController';
import { Map } from 'ol';
import StructureStyleFunctionCreator from './StructureStyleFunctionCreator';
import { useDispatch, useSelector } from 'react-redux';
import * as operationActions from '../../store/operation/actions';
import { FeatureType, MapMode } from '../../types/types';
import DrawTopographyController from './draw/DrawTopographyController';
import DrawRoadController from './draw/DrawRoadController';
import EditTopographyController from './draw/EditTopographyController';
import VectorLayer from 'ol/layer/Vector';
import RenameTopographyController from './draw/RenameTopographyController';
import RemoveStructureController from './draw/RemoveStructureController';
import RemoveTopographyController from './draw/RemoveTopographyController';
import MoveController from './draw/MoveController';
import { RootState } from '../../store';
import { storeGetter } from "../../util/props";
import ChangeStructureIconController from './draw/ChangeStructureIconController';
import InfoMoveController from './draw/InfoMoveController';
import { FeatureInfo } from '../../types/info';
import Select from 'ol/interaction/Select';
import CreateNewIdeaController from './draw/CreateNewIdeaController';
import MapHistoryController from './history/MapHistoryController';

type Props = {
    /** 親からもらうprops定義 */
    map: Map,
    styleCreator: StructureStyleFunctionCreator,
    topographyLayer: VectorLayer,
    structureLayer: VectorLayer,
    select: Select | undefined;                       // 地図クリック制御
}

export default function DrawController(props: Props) {
    const [drawController, setDrawController] = useState(undefined as JSX.Element | undefined);
    const dispatch = useDispatch();

    const terminate = useCallback(() => {
        setDrawController(undefined);
        dispatch(operationActions.changeModeAction(MapMode.NORMAL));
    }, [dispatch]);

    useCommandCatch(KickCommand.DrawStructure, () => {
        setDrawController(
            <DrawStructureController map={props.map} structureStyleCreator={props.styleCreator} 
            close={terminate} />
        );
    });

    useCommandCatch(KickCommand.DrawEarth, () => {
        // 「島作成」メニュー kick
        setDrawController(
            <DrawTopographyController map={props.map} drawFeatureType={FeatureType.EARTH} 
            close={terminate} />
        );
    });

    useCommandCatch(KickCommand.DrawForest, () => {
        // 「緑地化」メニュー kick
        setDrawController(
            <DrawTopographyController map={props.map} drawFeatureType={FeatureType.FOREST} 
            close={terminate} />
        );
    });

    useCommandCatch(KickCommand.DrawRoad, () => {
        // 「道作成」メニュー kick
        setDrawController(
            <DrawRoadController map={props.map} close={terminate} />
        );
    });

    useCommandCatch(KickCommand.EditTopography, () => {
        // 「土木変更」メニュー kick
        setDrawController(
            <EditTopographyController map={props.map} topographyLayers={[props.topographyLayer]}
            close={terminate} />
        );
    });

    useCommandCatch(KickCommand.RenameEarth, ()=> {
        setDrawController(
            <RenameTopographyController 
                map={props.map} topographyLayers={[props.topographyLayer]} 
                close={terminate}
            />
        );
    });

    useCommandCatch(KickCommand.RemoveStructure, () => {
        setDrawController(
            <RemoveStructureController
                map={props.map} structureLayer={props.structureLayer}
                close={terminate}
            />
        );
    });

    useCommandCatch(KickCommand.RemoveTopography, () => {
        // 「土木解体」メニュー kick
        setDrawController(
            <RemoveTopographyController map={props.map} topographyLayers={[props.topographyLayer]} 
            close={terminate} />
        );
    });

    useCommandCatch(KickCommand.MoveStructure, ()=> {
        // 「移築」メニュー kick
        setDrawController(
            <MoveController map={props.map} structureLayer={props.structureLayer} 
            close={terminate} />
        );
    });

    const selectedFeature = useSelector((state: RootState) => storeGetter(state).selectedFeature);
    useCommandCatch(KickCommand.ChangeStructure, ()=> {
        if (selectedFeature !== undefined) {
            // 建物アイコン変更
            setDrawController(
                <ChangeStructureIconController target={selectedFeature} close={terminate} />
            );
        }
    });

    useCommandCatch(KickCommand.InfoMove, (option: any) => {
        // 情報移動
        const afterClose = (featureId: string) => {
            terminate();

            // 移動先の建物の情報を表示する
            const feature = props.structureLayer.getSource().getFeatureById(featureId);
            props.select?.getFeatures().removeAt(0);
            props.select?.getFeatures().push(feature);
            dispatch(operationActions.selectFeatureAction(featureId));
        }
        setDrawController(
            <InfoMoveController
                map={props.map} structureLayer={props.structureLayer} target={option.info as FeatureInfo}
                close={afterClose}
            />
        );
    });

    useCommandCatch(KickCommand.CreateNewIdea, (option: any) => {
        // ひらめき情報作成
        const afterClose = (featureId: string) => {
            terminate();

            // 移動先の建物の情報を表示する
            const feature = props.structureLayer.getSource().getFeatureById(featureId);
            props.select?.getFeatures().removeAt(0);
            props.select?.getFeatures().push(feature);
            dispatch(operationActions.selectFeatureAction(featureId));
        }
        setDrawController(
            <CreateNewIdeaController
                map={props.map} structureLayer={props.structureLayer} target={option.info as FeatureInfo}
                close={afterClose}
            />
        );
    });

    useCommandCatch(KickCommand.ShowMapHistory, () => {
        // 過去地図表示モード
        setDrawController(
            <MapHistoryController close={terminate} structureLayer={props.structureLayer} topographyLayer={props.topographyLayer} />
        );
    });

    useEffect(() => {
        if (drawController !== undefined) {
            dispatch(operationActions.changeModeAction(MapMode.DRAW));
            dispatch(operationActions.unselectFeatureAction());
        }
    }, [drawController, dispatch]);

    if (drawController === undefined) {
        return null;
    } else {
        return drawController;
    }
}