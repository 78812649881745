import { Dispatch } from "react";
import { Action } from "redux";
import { ThunkAction } from "redux-thunk";
import { RootState } from "..";
import { ConfirmDialogParam, DialogResult } from "../../types/operation";
import * as actions from './actions';

/**
 * 確認ダイアログを表示する
 * @param param ダイアログに表示するメッセージ等
 */
export function confirmThunk(param: ConfirmDialogParam): ThunkAction<Promise<DialogResult>, RootState, unknown, Action<string>> {
    return async (dispatch: Dispatch<any>, getState: () => RootState): Promise<DialogResult> => {
        // ダイアログ表示
        dispatch(actions.showConfirmDialogAction(param));

        const result = await waitDialogResult(getState);

        // 結果リセット
        dispatch(actions.clearConfirmDialogReusltAction());
        return result;
    }
}

function waitDialogResult(getState: () => RootState): Promise<DialogResult> {
    // 結果ボタンが押下されるまで待つ
    return new Promise<DialogResult>((resolve) => {
        const f = () => {
            const result = getState().operationReducer.confirmDialogResult;
            if (result === undefined) {
                setTimeout(f, 500);
            } else {
                resolve(result);
            }
        }
        f();
    });
}
