import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import "firebase/firestore";
import rootReducer from '.';

const configureStore = (initialState: any) => {
    return createStore(
        rootReducer,
        initialState,
        applyMiddleware(thunk)
    );
}

export default configureStore

