import { Fill, Stroke, Style } from 'ol/style';
import OlFeature from 'ol/Feature';
import { StyleFunction } from 'ol/style/Style';
import { ROAD_COLOR,  EARTH_COLOR, FOREST_COLOR, SELECT_COLOR } from '../../styles/constants';
import { FeatureType } from '../../types/types';
import MultiPoint from 'ol/geom/MultiPoint';
import Polygon from 'ol/geom/Polygon';
import Point from 'ol/geom/Point';
import CircleStyle from 'ol/style/Circle';
import Circle from 'ol/geom/Circle';
import LineString from 'ol/geom/LineString';

// 地形、道路
export const topographyStyleFunction = function (feature: OlFeature, resolution: number) {
    const type = feature.getProperties().type;
    let color;
    let zIndex;
    switch (type) {
        case FeatureType.EARTH:
            color = EARTH_COLOR;
            zIndex = 1;
            break;
        case FeatureType.FOREST:
            color = FOREST_COLOR;
            zIndex = 2;
            break;
        case FeatureType.ROAD:
            color = ROAD_COLOR;
            zIndex = 3;
            break;
    }
    const style = new Style({
        stroke: new Stroke({
            color: color,
            width: 1
        }),
        fill: new Fill({
            color: color
        }),
        zIndex,
    });
    return style;
} as StyleFunction;

export const topographySelectStyleFunction = function (feature: OlFeature, resolution: number) {
    const style = topographyStyleFunction(feature, resolution) as Style;
    style.setStroke(new Stroke({
        color: SELECT_COLOR,
        width: 3
    }));
    return style;
} as StyleFunction;

/** 
 * 描画レイヤ用スタイル（建物以外）
 */
export const drawingLayerStyle = (type: FeatureType) => {
    let color;
    let width = 1;
    switch (type) {
        case FeatureType.ROAD:
            color = ROAD_COLOR;
            width = 3;
            break;
        case FeatureType.EARTH:
            color = EARTH_COLOR;
            break;
        case FeatureType.FOREST:
            color = FOREST_COLOR;
            break;
    }

    return [
        new Style({
            stroke: new Stroke({
                color,
                width,
            }),
            fill: new Fill({
                color,
            }),
        }),
        new Style({
            image: new CircleStyle({
                radius: 5,
                stroke: new Stroke({
                    color: 'orange',
                    width,
                }),
            }),
            geometry: function (feature) {
                const geometry = feature.getGeometry();
                let coordinates;
                if (geometry instanceof Point) {
                    coordinates = geometry.getCoordinates();
                } else {
                    coordinates = (geometry as Polygon | Circle |LineString).getCoordinates();
                    if (coordinates === null) {
                        coordinates = [];
                    } else {
                        coordinates = coordinates[0];
                    }
                }
                return new MultiPoint(coordinates);
            },
        })
    ];
}
