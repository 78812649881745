// タグ一覧コンポーネント
import React, { Component } from 'react'
import { DispatchProps, TagInfo } from '../../types/types';
import { RootState } from '../../store';
import { connect } from 'react-redux';
import TagBadge from '../common/TagBadge';

type OwnProps = {
    /** 親からもらうprops定義 */
    exclude?: TagInfo[];    // 一覧に表示しないタグ情報
    keyword?: string;       // このキーワードを含むタグを表示する
    select: (tag: TagInfo) => void; // タグが選択された場合に選択したタグを返す
}
type StateProps = OwnProps & {
    /** ストアの内容から生成するprops定義 */
    tags: { [id: string]: TagInfo };    // 登録済みのタグ情報
}
type Props = StateProps & DispatchProps;

const mapStateToProps = (state: RootState, ownProps: OwnProps): StateProps => ({
    ...ownProps,
    tags: state.infoReducer.tags,
});

type State = {};

class TagList extends Component<Props, State> {
    render() {
        return (
            <ul className="list-group">
                {this.taglist.map((tag) => {
                    return (
                        <TagBadge key={tag.id} tag={tag} click={this.onClick.bind(this, tag)} />
                    );
                })}
            </ul>
        );
    }

    private get taglist(): TagInfo[] {
        const taglist = Object.values(this.props.tags).filter((tag: TagInfo) => {
            let exclude = this.props.exclude;
            if (exclude === undefined) {
                exclude = [];
            }
            // 除外対象は除く
            const exist = exclude.some((exclude) => {
                return exclude.id === tag.id;
            });
            if (exist) {
                return false;
            }

            // キーワードを含むものに絞る
            if (this.props.keyword !== undefined) {
                return tag.name.indexOf(this.props.keyword) !== -1;
            }
            return true;
        });
        taglist.sort((a, b) => {
            return a.name.localeCompare(b.name);
        });
        return taglist;
    }

    private onClick(tag: TagInfo) {
        this.props.select(tag);
    }
}
export default connect(mapStateToProps)(TagList);
