import React, { useEffect, useState } from "react";
import * as firebase from 'firebase/app';
import 'firebase/auth';
import * as firebaseui from 'firebaseui';
import 'firebaseui/dist/firebaseui.css';
import styles from './FirebaseAuth.module.scss';

let ui: any = null

export default function FirebaseAuth() {
    // const [mounted, setMounted] = useState(false);

    const [isSignIn, setSignIn] = useState(false);

    useEffect(() => {
        // setMounted(true);
        console.log('Auth Ui New');
        ui = new firebaseui.auth.AuthUI(firebase.auth());
        firebase.auth().onAuthStateChanged((user) => {
            if (user !== null && ui !== null) {
                console.log('Auth Ui Delete');
                ui.delete();
                ui = null;
            }
            setSignIn(user !== null);
        });
        ui.start('#firebaseui-auth-container', {
            signInFlow: 'popup',
            signInOptions: [
                firebase.auth.EmailAuthProvider.PROVIDER_ID,
                firebase.auth.FacebookAuthProvider.PROVIDER_ID,
            ],
            callbacks: {
                signInSuccessWithAuthResult: () => {
                    return false;
                }
            }
            // Other config options...

        });

        return () => {
            if (ui! !== null) {
                console.log('Auth Ui Delete');
                ui.delete();
                ui = null;
            }
        };
    }, []);

    if (isSignIn) {
        return null;
    } else {
        return (
            <React.Fragment>
                <div id="firebaseui-auth-container" />
                <iframe title="AuthFrame" className={styles.PrivacyPolicyArea} src="./privacy_policy.html" />
            </React.Fragment>
        )
    }

}