import { FeatureDocItem } from "../../types/feature";
import { Feature, ThemeDefine } from "../../types/types";
import { State, ActionTypes, ReducerType, RegistFeatureAction, RemoveFeatureAction, UpdateThemeDefineAction } from "./types";

const initialState = {
    features: {},
    themeDefines: [],
} as State;

export const reducer = (state = initialState, action: ActionTypes): State => {
    switch (action.type) {
        case ReducerType.RegistFeature:
            return registFeatureReducer(state, (action as RegistFeatureAction).payload);

        case ReducerType.RemoveFeature:
            return removeFeatureReducer(state, (action as RemoveFeatureAction).payload);

            case ReducerType.UpdateThemeDefine:
            return updateThemeDefine(state, (action as UpdateThemeDefineAction).payload);
    
        default:
            return state;
        }
};

/**
 * 地物情報の追加・更新
 *
 * @param {FeatureState} state
 * @param {*} action
 * @returns
 */
function registFeatureReducer(state: State, payload: Array<{id: string; item: FeatureDocItem}>) {
    const features = Object.assign({}, state.features);
    payload.forEach((item) => {
        const feature =  Object.assign({
            id: item.id,
        }, item.item);
        features[item.id] = feature;
    });

    return Object.assign({}, state, {
        features,
    });
}

/**
 * 地物情報を削除する
 *
 * @param {FeatureState} state
 * @param {*} action
 * @returns
 */
function removeFeatureReducer(state: State, payload: string[]) {
    // 指定のFeatureを除く
    const newFeatures = {} as { [id: string]: Feature };

    Object.keys(state.features).forEach((id) => {
        if (payload.indexOf(id) === -1) {
            // 削除されていないもののみでFeatures情報を再生成
            if (state.features[id] !== undefined) {
                newFeatures[id] = state.features[id];
            }
        }
    });

    return Object.assign({}, state, {
        features: newFeatures,
    });
}

/**
 * 強調表示対象のタグを更新する
 * @param state 
 * @param payload 
 */
function updateThemeDefine(state: State, payload: ThemeDefine[]) {
    return Object.assign({}, state, {
        themeDefines: payload,
    });
}