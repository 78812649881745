import React, { Component } from "react";
import * as firebase from 'firebase/app';
import 'firebase/auth';
import * as firebaseui from 'firebaseui';
import 'firebaseui/dist/firebaseui.css';
import * as operationThunks from '../../store/operation/thunks';
import { DispatchProps } from "../../types/types";
import { DialogMode } from "../../types/operation";
import { RootState } from "../../store";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";

type RouteParam = {
}
type OwnProps = RouteComponentProps<RouteParam>;
type StateProps = OwnProps & {
    /** ストアの内容から生成するprops定義 */
}
type Props = StateProps & DispatchProps;
const mapStateToProps = (state: RootState, ownProps: OwnProps): StateProps => ({
    ...ownProps,
});

type State = {
};
/**
 * アカウント削除
 */
class DeleteUser extends Component<Props, State> {
    private ui: any = null
    private mounted: boolean = false;

    render() {
        return (
            <React.Fragment>
                <h2>アカウント削除</h2>
                <p>
                    <button className="btn btn-primary" onClick={this.toRoot.bind(this)}>元の画面に戻る</button>
                </p>
                <hr />
                <p className="text-danger">
                アカウント削除すると、参加している全ての村での村人情報が消えます。<br/>
                （登録した建物や情報は残ります）
                </p>
                <p>
                削除するアカウントでログインしてください。
                </p>
                <div id="firebaseui-auth-container" />
            </React.Fragment>
        )
    }

    componentDidMount() {
        this.mounted = true;
        this.ui = new firebaseui.auth.AuthUI(firebase.auth());
        this.ui.start('#firebaseui-auth-container', {
            signInOptions: [
                firebase.auth.EmailAuthProvider.PROVIDER_ID,
                firebase.auth.FacebookAuthProvider.PROVIDER_ID,
            ],
            callbacks: {
                signInSuccessWithAuthResult: () => {
                    if (this.ui !== null) {
                        this.ui.delete();
                        this.ui = null;
                    }
                    this.deleteUser();
                    return false;
                }
            },

            // Other config options...
        });

    }

    componentWillUnmount() {
        this.mounted = false;
        if (this.ui !== null) {
            this.ui.delete();
            this.ui = null;
        }
    }

    private async deleteUser() {
        try {
            const user = firebase.auth().currentUser;
            if (user === null) {
                await this.props.dispatch(
                        operationThunks.confirmThunk({
                        message: 'アカウント削除に失敗しました。',
                        mode: DialogMode.OkOnly,
                    })
                );
                return;
            }
            await user.delete();
            await this.props.dispatch(
                operationThunks.confirmThunk({
                    message: 'アカウント削除完了しました。',
                    mode: DialogMode.OkOnly,
                })
            );
            this.props.history.push('/');
    
        } catch(e) {
            console.warn('delete account failed.', e);
            await this.props.dispatch(
                operationThunks.confirmThunk({
                    message: 'アカウント削除に失敗しました。ページリロードして、再度試してください。',
                    mode: DialogMode.OkOnly,
                })
            );

        }
    }

    private toRoot() {
        this.props.history.push('/');
    }
}
export default withRouter(connect(mapStateToProps)(DeleteUser));
