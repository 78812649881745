import React, { useEffect, useMemo, useState } from 'react';
import { Map } from 'ol';
import VectorLayer from 'ol/layer/Vector';
import PromptMessageBox from './PromptMessageBox';
import { Select } from 'ol/interaction';
import StructureStyleFunctionCreator from '../StructureStyleFunctionCreator';
import { singleClick } from 'ol/events/condition';
import { FeatureInfo } from '../../../types/info';
import InfoAddEditDialog from '../../infos/edit/InfoAddEditDialog';
import { DispatchProps, StructureImageDefine } from '../../../types/types';
import { RootState } from '../../../store';
import { connect } from 'react-redux';

/**
 * ひらめき情報を置く建物を選択して、新規情報登録するコントローラ
 */

type OwnProps = {
    /** 親からもらうprops定義 */
    map: Map;
    target: FeatureInfo;    // 移動する情報
    structureLayer: VectorLayer;
    close: (featureId: string) => void;  // 移動完了時のコールバック。移動先の建物IDをparamに入れて返す
}
type StateProps = OwnProps & {
    /** ストアの内容から生成するprops定義 */
    iconDefine: StructureImageDefine[],
}
type Props = StateProps & DispatchProps;

const mapStateToProps = (state: RootState, ownProps: OwnProps): StateProps => ({
    ...ownProps,
    iconDefine: state.systemReducer.iconDefine,
});

enum Stage {
    SelectFeature,
    CreateInfo,
};

function CreateNewIdeaController(props: Props) {
    const [okable, setOkable] = useState(false);
    const [stage, setStage] = useState(Stage.SelectFeature);
    const [featureId, setFeatureId] = useState(undefined as undefined | string);

    const styleCreator = useMemo(() => {
        return new StructureStyleFunctionCreator(props.structureLayer.getSource(), props.iconDefine);
    }, [props.structureLayer, props.iconDefine]);

    const select = useMemo(() => {
        return new Select({
            layers: [props.structureLayer],
            style: styleCreator.getStructureStyleFunction(true),
            condition: singleClick,
        });
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        select.on("select", () => {
            // 単一選択にする
            if (select.getFeatures().getLength() > 1) {
                select.getFeatures().removeAt(0);
            }
            setOkable(
                select.getFeatures().getLength() > 0
            );
        })
        props.map.addInteraction(select);

        return () => {
            props.map.removeInteraction(select);
        }
    }, [props.map, select]);

    const onSelectFeature = async() => {
        const featureId = select.getFeatures().item(0).getId() as string;
        setFeatureId(featureId);
        setStage(Stage.CreateInfo);
    }

    const onCancelClicked = () => {
        props.close(props.target.featureId);
    }

    const onClose = () => {
        props.close(featureId as string);
    }

    if(stage === Stage.SelectFeature) {
        return (
            <PromptMessageBox message={"新規情報を置く建物を選択してください。"} ok={onSelectFeature} cancel={onCancelClicked} okdisabled={!okable} />
    
        )
    } else {
        // 情報登録
        const addInfo = {
            featureId: featureId as string,
            linkInfoId: props.target.id,
        }
        return (
            <InfoAddEditDialog addInfo={addInfo} close={onClose} cancel={onCancelClicked} />
        )
    }
}
export default connect(mapStateToProps)(CreateNewIdeaController);
