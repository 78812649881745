// 住民情報変更
import React, { useEffect, useState } from 'react'
import { DispatchProps, VillageUserInfo, GroupInfo } from '../../types/types';
import { RootState } from '../../store';
import { connect } from 'react-redux';
import { Modal, Button } from 'react-bootstrap';
import * as dbAccessor from "../../util/DbAccessor";
import { KickCommand } from '../../types/operation';
import { useCommandCatch } from '../../util/customHooks';

type OwnProps = {
    /** 親からもらうprops定義 */
}
type StateProps = OwnProps & {
    /** ストアの内容から生成するprops定義 */
    userInfo: VillageUserInfo; // 現在のユーザ情報
    groupInfoArr: GroupInfo[];  // 登録されているグループ情報
}
type Props = StateProps & DispatchProps;

const mapStateToProps = (state: RootState, ownProps: OwnProps): StateProps => ({
    ...ownProps,
    userInfo: state.systemReducer.users[state.systemReducer.signInUserId as string] as VillageUserInfo,
    groupInfoArr: Object.values(state.systemReducer.groups),
});

function UpdateUserInfo(props: Props) {
    const [show, setShow] = useState(false);
    const [name, setName] = useState(props.userInfo.name); // 現在のユーザ名を初期値に設定
    const [email, setEmail] = useState('');  
    const [updated, setUpdated] = useState(false);

    useEffect(() => {
        // 現在の通知先メアドを初期値に設定
        dbAccessor.getCurrentUserEmail()
        .then((email: string) => {
            setEmail(email);
        })
    }, [show]);

    useCommandCatch(KickCommand.ShowUserInfoDialog, () => setShow(true));

    const onChangeHandlerName = (event: React.ChangeEvent<HTMLInputElement>) => {
        setName(event.target.value);
    }

    const onChangeHandlerEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEmail(event.target.value);
    }

    const onUpdateBtnClicked = async() => {
        if (updated) {
            return;
        }
        // ユーザ情報更新
        await dbAccessor.updateUserInfo(Object.assign({}, props.userInfo, {
            name,
        }));
        await dbAccessor.updateCurrentUserEmail(email);
        setUpdated(true);
        setTimeout(() => {
            setUpdated(false);
            setShow(false);
        }, 1000);
    }
    return (
        <Modal show={show} onHide={()=>setShow(false)}>
            <Modal.Header closeButton>
                <Modal.Title>住民情報変更</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="form-group row">
                    <label className="col-sm-2 col-form-label">氏名</label>
                    <div className="col-sm-10">
                        <input value={name} type="text" name="name" className="form-control" onChange={onChangeHandlerName} />
                    </div>
                </div>
                <div className="form-group row">
                    <label className="col-sm-2 col-form-label">通知先</label>
                    <div className="col-sm-10">
                        <input value={email} type="text" name="name" className="form-control" onChange={onChangeHandlerEmail} />
                    </div>
                </div>
                {/* {this.props.groupInfoArr.map((group) => {
                    return group.name;
                })} */}
            </Modal.Body>
            <Modal.Footer>
                {updated &&
                    <span>住民情報を変更しました</span>
                }
                <Button variant="secondary" onClick={()=>setShow(false)}>
                    Cancel
                </Button>
                <Button variant="primary" onClick={onUpdateBtnClicked}>
                    更新
                </Button>
            </Modal.Footer>
        </Modal>
        );
}
export default connect(mapStateToProps)(UpdateUserInfo);
