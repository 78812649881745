import React, { useCallback, useEffect, useState } from 'react';
import { FeatureInfo } from '../../types/info';
import InfoPanel from './view/InfoPanel';
import styles from './InfoCardList.module.scss';
import { connect } from 'react-redux';
import { DispatchProps } from '../../types/types';
import { RootState } from '../../store';
import * as operationActions from '../../store/operation/actions';
import { KickCommand } from '../../types/operation';
import InfoAttrDialog from './InfoAttrDialog';
import InfoAddEditDialog from './edit/InfoAddEditDialog';
import * as dbAccessor from "../../util/DbAccessor";
import * as CommonUtility from '../../util/CommonUtility';

type OwnProps = {
    /** 親からもらうprops定義 */
    infoMap: {[id: string]: FeatureInfo};   // 表示対象の情報マップ
    focusInfoId?: number;               // フォーカスする情報ID
    onFocusInfoMounted?: (rect: DOMRect) => void;  // focusInfoIdを指定すると、その情報パネルのRECTが返る
    reload: ()=>{};                     // 情報再読み込み用Function
    orderAsc: boolean;                  // 昇順の場合,true
}
type StateProps = OwnProps & {
}

type Props = StateProps & DispatchProps;

const mapStateToProps = (state: RootState, ownProps: OwnProps): StateProps => ({
    ...ownProps,
});

enum Mode {
    NORMAL,
    ShowInfoAttr,       // 情報属性情報表示モード
    EditInfo,           // 情報編集モード
}

/**
 * 右サイドパネルの情報リスト
 * @param props 
 * @returns 
 */
function InfoCardList(props: Props) {
    const [mode, setMode] = useState(Mode.NORMAL);
    const [targetInfo, setTargetInfo] = useState(undefined as FeatureInfo | undefined);
    // 表示対象の情報一覧（一時的な並び順変更を可能とするため、infoMapの内容をここに格納する）
    const [infos, setInfos] = useState([] as FeatureInfo[]);

    useEffect(() => {
        const infos = Object.values(props.infoMap);
        infos.sort(CommonUtility.featureInfoCompartorCreator(props.orderAsc));
        setInfos(infos);
    }, [props.infoMap, props.orderAsc]);

    // フォーカスを充てる情報
    const onFocusInfoMounted = useCallback((param: DOMRect) => {
        if (props.onFocusInfoMounted === undefined) {
            return;
        }
        // 情報にフォーカスを充てる
        props.onFocusInfoMounted(param);
        // eslint-disable-next-line
    }, [props.onFocusInfoMounted]);


    const onInfo = (info: FeatureInfo) => {
        setMode(Mode.ShowInfoAttr);
        setTargetInfo(info);
    }
    const onInfoClosed = () => {
        setMode(Mode.NORMAL);
        setTargetInfo(undefined);
    }

    const onEdit = (info: FeatureInfo) => {
        setMode(Mode.EditInfo);
        setTargetInfo(info);
    }
    const onInfoEditModeFinish = () => {
        setMode(Mode.NORMAL);
        setTargetInfo(undefined);

        // 情報再読み込み
        props.reload();
    }

    // 情報引っ越し
    const onMove = (info: FeatureInfo) => {
        props.dispatch(operationActions.kickCommand(KickCommand.InfoMove, {info}));
    }

    // ひらめき情報作成
    const onNewIdea = (info: FeatureInfo) => {
        props.dispatch(operationActions.kickCommand(KickCommand.CreateNewIdea, {info}));
    }

    const [orderChangeTargetId, setOrderChangeTargetId] = useState(undefined as number | undefined);
    const [orderChangeMoveAtId, setOrderChangeMoveAtId] = useState(undefined as number | undefined);
    /**
     * 指定の情報の並び順を仮入れ替えする
     * @param targetId 並べ替える対象
     * @param moveAtId この対象の前後にtargetを移動する。
     */
    const onInfoOrderTemporarySwitched = (targetId: number, moveAtId: number) => {
        if (orderChangeTargetId === undefined) {
            setOrderChangeTargetId(targetId);
        }
        setOrderChangeMoveAtId(moveAtId);
        // moveAt < target の並び順の場合は、moveAtの直前にtargetを移動
        // target < moveAt の並び順の場合は、moveAtの直後にtargetを移動
        const tempinfos = [] as FeatureInfo[];
        Array.prototype.push.apply(tempinfos, infos);

        const targetIndex = tempinfos.findIndex((info) => {
            return info.id === targetId;
        });
        if (targetIndex === -1) {
            console.warn('該当情報なし', targetId);
            return;
        }
        const moveAtIndex = tempinfos.findIndex((info) => {
            return info.id === moveAtId;
        });
        if (moveAtIndex === -1) {
            console.warn('該当情報なし', moveAtId);
            return;
        }
        const first = Math.min(targetIndex, moveAtIndex);
        const second = Math.max(targetIndex, moveAtIndex);
        const firstArr = tempinfos.slice(0, first);
        const secondArr = tempinfos.slice(first + 1, second);
        const thirdArr = tempinfos.slice(second + 1);
        firstArr.push(tempinfos[second]);
        secondArr.push(tempinfos[first]);
        const newInfos = firstArr.concat(secondArr);
        Array.prototype.push.apply(newInfos, thirdArr);
        setInfos(newInfos);
    }

    /**
     * 現在の並び順で位置移動を確定する
     */
    const onCommitOrder = () => {
        if (orderChangeTargetId === undefined || orderChangeMoveAtId === undefined) {
            return;
        }
        const featureId = infos[0].featureId;
        dbAccessor.orderChange(featureId, orderChangeTargetId, orderChangeMoveAtId);
        setOrderChangeTargetId(undefined);
        setOrderChangeMoveAtId(undefined);
    }

    
    const body = infos.length > 0 ?
    (
        <div className={styles.InfoArea}>
            {infos.map((info) => {
                return <InfoPanel info={info} key={info.id} 
                        onInfo={() => onInfo(info)} 
                        onEdit={() => onEdit(info)} 
                        onMove={() => onMove(info)} 
                        onNewIdea={() => {onNewIdea(info)}}
                        switchOrder={onInfoOrderTemporarySwitched} 
                        orderChangeCommit={onCommitOrder} 
                        onMounted={info.id===props.focusInfoId?onFocusInfoMounted:()=>{}}
                        />
            })}
        </div>
    ) : (
        <div>
            登録されている情報がありません。<br/>
            追加ボタンから、情報登録しましょう。
        </div>
    );

    const dialog = mode === Mode.ShowInfoAttr ?
                                <InfoAttrDialog info={targetInfo as FeatureInfo} close={onInfoClosed} />
                                : mode === Mode.EditInfo ?
                                    <InfoAddEditDialog info={targetInfo} 
                                    close={onInfoEditModeFinish}
                                    cancel={onInfoEditModeFinish}
                                     />
                                    : null;

    return (
        <React.Fragment>
            {dialog}
            {body}
        </React.Fragment>
    );
}
export default connect(mapStateToProps)(InfoCardList);
