export default {
    // 建物ラベルを表示するresolution境界値（これ以下の値の時に表示）
    StructureLabelResolution: 1.5,

    // ポップアップ表示するズームLv.境界値（これ以上の値の時に表示）
    PopupShowZoomLv: 17,

    // 島名を常時表示するズームLv.境界値（この値よりも小さい場合に、常時表示）
    LandNameShowZoomLv: 16.8
};
