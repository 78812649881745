import React, { useEffect, useMemo, useState } from 'react';
import { Map } from 'ol';
import VectorLayer from 'ol/layer/Vector';
import PromptMessageBox from './PromptMessageBox';
import { Select } from 'ol/interaction';
import StructureStyleFunctionCreator from '../StructureStyleFunctionCreator';
import { singleClick } from 'ol/events/condition';
import StructureListDialog from './StructureListDialog';
import { DispatchProps, StructureImageDefine } from '../../../types/types';
import { RootState } from '../../../store';
import { connect } from 'react-redux';

enum Stage {
    Selecting,  // 対象選択中
    Confirm,    // 確認中
}
type OwnProps = {
    /** 親からもらうprops定義 */
    map: Map;
    structureLayer: VectorLayer;
    close: () => void;  // 編集完了時のコールバック
}
type StateProps = OwnProps & {
    /** ストアの内容から生成するprops定義 */
    iconDefine: StructureImageDefine[],
}
type Props = StateProps & DispatchProps;

const mapStateToProps = (state: RootState, ownProps: OwnProps): StateProps => ({
    ...ownProps,
    iconDefine: state.systemReducer.iconDefine,
});

function RemoveStructureController(props: Props) {
    const [okable, setOkable] = useState(false);
    const [stage, setStage] = useState(Stage.Selecting);

    const styleCreator = useMemo(() => {
        return new StructureStyleFunctionCreator(props.structureLayer.getSource(), props.iconDefine);
    }, [props.structureLayer, props.iconDefine]);
    const select = useMemo(() => {
        return new Select({
            layers: [props.structureLayer],
            style: styleCreator.getStructureStyleFunction(true),
            // features: new VectorSource(),
            toggleCondition: singleClick,
        });
    }, [props.structureLayer, styleCreator]);

    useEffect(() => {
        select.on("select", () => {
            setOkable(
                select.getFeatures().getLength() > 0
            );
        })
        props.map.addInteraction(select);

        return () => {
            props.map.removeInteraction(select);
        }
    }, [props.map, select]);

    const onSelectFinishClicked = () => {
        setStage(Stage.Confirm);
    }

    const onCancelClicked = () => {
        props.close();
    }

    const onConfirmCanceled = () => {
        setStage(Stage.Selecting);
    }

    if (stage === Stage.Selecting) {
        return (
            <PromptMessageBox message={"解体したい建物を選択してください。"} ok={onSelectFinishClicked} okname="完了" cancel={onCancelClicked} okdisabled={!okable} />
    
        )
    } else {
        const ids = [] as string[];
        select.getFeatures().forEach((feature) => {
            ids.push(feature.getId() as string);
        })
        return (
            <StructureListDialog featureIdArr={ids} cancel={onConfirmCanceled} finish={props.close} />
        )
    }
}
export default connect(mapStateToProps)(RemoveStructureController)