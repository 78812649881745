import React, { Component } from 'react';
import { DispatchProps } from '../../types/types';
import { RootState } from '../../store';
import { connect } from 'react-redux';
import { Modal } from 'react-bootstrap';
import { storeGetter } from '../../util/props';
import { FeatureInfo } from '../../types/info';

type OwnProps = {
    /** 親からもらうprops定義 */
    info: FeatureInfo;
    close: () => void;  // ダイアログ閉じた際のコールバック
}
type StateProps = OwnProps & {
    /** ストアの内容から生成するprops定義 */
    userName: (id: string) => string;
}
type Props = StateProps & DispatchProps;

const mapStateToProps = (state: RootState, ownProps: OwnProps): StateProps => ({
    ...ownProps,
    userName: storeGetter(state).userName,
});

type State = {}

class InfoAttrDialog extends Component<Props, State> {
    render() {
        return (
            <Modal show={true} onHide={this.handleClose.bind(this)}>
                <Modal.Header closeButton>{this.props.info.title}</Modal.Header>
                <Modal.Body>
                    <div className="form-group row">
                        <label className="col-sm-4 col-form-label">最終更新者</label>
                        <div className="col-sm-8">
                            <input type="text" readOnly className="form-control-plaintext" value={this.lastUpdateUserName} />
                        </div>
                    </div>
                    <div className="form-group row">
                        <label className="col-sm-4 col-form-label">最終更新日時</label>
                        <div className="col-sm-8">
                            <input type="text" readOnly className="form-control-plaintext" value={this.lastUpdate} />
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-secondary" onClick={this.handleClose.bind(this)}>閉じる</button>
                </Modal.Footer>
            </Modal>
        );
    }

    private handleClose() {
        this.props.close();
    }

    private get lastUpdateUserName(): string {
        return this.props.userName(this.props.info.lastUpdateUser);
    }

    private get lastUpdate(): string {
        return this.props.info.lastUpdateDate.toLocaleString();
    }

}
export default connect(mapStateToProps)(InfoAttrDialog);
