import React, { Component } from 'react';
import { DispatchProps, TagInfo } from '../../../types/types';
import { RootState } from '../../../store';
import { connect } from 'react-redux';
import styles from './TagListItem.module.scss';
import TagBadge from '../../common/TagBadge';
import "react-toggle/style.css";
import Toggle from 'react-toggle';
import { DragSource, DragSourceMonitor, DragSourceConnector, DragSourceSpec, DropTargetSpec, DropTargetMonitor, DropTargetConnector, DropTarget } from 'react-dnd';

type OwnProps = {
    /** 親からもらうprops定義 */
    tag: TagInfo;
    onToggleClicked: (event: React.ChangeEvent<HTMLInputElement>) => void;
    switchOrder: (aId: string, bId: string) => void;    // 一時的にAとBの位置を入れ替えてもらうように親に依頼
    orderChangeCommit: () => void;
}
type StateProps = OwnProps & {
    /** ストアの内容から生成するprops定義 */
}
type DragCollectProps = {
    connectDragSource: any;
    isDragging: boolean;
}
type DropCollectProps = {
    isOver: boolean;
    canDrop: boolean;
    connectDropTarget: any;
}

type Props = StateProps & DispatchProps & DragCollectProps & DropCollectProps;

const mapStateToProps = (state: RootState, ownProps: OwnProps): StateProps => ({
    ...ownProps,
});

type State = {}

class TagListItem extends Component<Props, State> {
    render() {
        const useTheme = this.props.tag.useTheme === undefined ? true : this.props.tag.useTheme;
        return this.props.connectDropTarget(this.props.connectDragSource(
            <div className="list-group-item">
                <div className={styles.TagBadgeArea}>
                    <TagBadge tag={this.props.tag} />
                </div>
                <div className={styles.ToggleArea}>
                    <Toggle id={'toggle-' + this.props.tag.id} defaultChecked={useTheme} 
                        icons={{
                            unchecked: null,
                        }}
                        className='my-toggle'
                    onChange={this.props.onToggleClicked} />
                </div>
            </div>
        ));
    }
}
const dragSpec: DragSourceSpec<Props, { id: string }> = {
    canDrag(props: Props) {
        return true;
    },

    beginDrag(props: Props, monitor: DragSourceMonitor, component: any) {
        return { id: props.tag.id };
    }
}
const dragCollect = (connect: DragSourceConnector, monitor: DragSourceMonitor): DragCollectProps => ({
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging()
});
const dropSpec: DropTargetSpec<Props> = {
    hover(props: Props, monitor: DropTargetMonitor, component: any) {
        // 位置を一時的に入れ替える
        if (monitor.getItem().id === undefined || props.tag.id === monitor.getItem().id) {
            return;
        }
        props.switchOrder(monitor.getItem().id, props.tag.id);
    },
    canDrop(props: Props, monitor: DropTargetMonitor) {
			return true;
    },
    drop(props: Props, monitor: DropTargetMonitor, component: any) {
        props.orderChangeCommit();
    }
}
const dropCollect = (connect: DropTargetConnector, monitor: DropTargetMonitor): DropCollectProps => ({
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver(),
    canDrop: monitor.canDrop(),
});
export default DropTarget('TagItem', dropSpec, dropCollect)(DragSource('TagItem', dragSpec, dragCollect)(connect(mapStateToProps)(TagListItem)));
