import { combineReducers } from 'redux';
import { reducer as systemReducer } from './system/reducers';
import { reducer as featureReducer } from './feature/reducers';
import { reducer as infoReducer } from './info/reducers';
import { reducer as operationReducer } from './operation/reducers';

const rootReducer = combineReducers({
    operationReducer,
    systemReducer,
    featureReducer,
    infoReducer,
});
export default rootReducer;
export type RootState = ReturnType<typeof rootReducer>
