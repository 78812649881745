import { FeatureInfo } from "../types/info";
import { ImageInfo } from "../types/types";
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import FacebookConfig from '../config/facebook-config.json';

export const featureInfoCompartorCreator = (asc: boolean) => {
    const multi = asc ? 1 : -1;
    return (a: FeatureInfo, b: FeatureInfo) => {
        if (a.order_num=== undefined && b.order_num !== undefined) {
            return 1 * multi;
        }
        if (a.order_num !== undefined && b.order_num === undefined) {
            return -1 * multi;
        }
        if (a.order_num === undefined || b.order_num === undefined) {
            return 0;
        }
        return (a.order_num - b.order_num) * multi;
    }
}

/**
 * 縮小画像作成
 * @param org {HTMLImageElement} 元画像
 * @param longSize {number} 縮小後の長辺サイズ. 元画像サイズよりも大きい場合は縮小しない。
 * @returns 縮小後画像のBase64文字列
 */
export const createImageShrink = (org: HTMLImageElement, longSize: number, kind: 'jpeg' | 'png'): string => {
    const width = org.width;
    const height = org.height;
    let cnvsH;
    let cnvsW;

    if (width < longSize && height < longSize) {
        // 指定の長辺サイズよりもサイズが小さい場合は縮小しない
        cnvsW = width;
        cnvsH = height;
    } else {
        if (width > height) {
            cnvsW = longSize;
            cnvsH = height * cnvsW / width;
        } else {
            cnvsH = longSize;
            cnvsW = width * cnvsH / height;
        }
    }

    const canvas = document.createElement('canvas') as HTMLCanvasElement;
    canvas.setAttribute('width', cnvsW + '');
    canvas.setAttribute('height', cnvsH + '');
    const ctx = canvas.getContext('2d');
    if (ctx === null) {
        console.warn('create image failed.');
        return '';
    }
    ctx.drawImage(org, 0, 0, cnvsW, cnvsH);
    return canvas.toDataURL('image/' + kind);
}

enum ScriptType {
    YT = 'YT',
    FB = 'FB',
}
const loadingAPI = {YT: 0, FB: 0};  // 0->未ロード, 1->ロード中, 2->ロード完了

export function loadYoutubeAPI(): Promise<any> {
    const api_url = 'https://www.youtube.com/iframe_api';
    const promiseCreater = () => {
        return new Promise<void>((resolve) =>{
            (window as any).onYouTubeIframeAPIReady = () => {
                console.log('onYouTubeIframeAPIReady', (window as any).YT.Player)
                resolve();
            }
        });
    };
    return createLoadPromise(ScriptType.YT, api_url, promiseCreater);
}
function loadFacebookAPI(): Promise<void> {
    const api_url = 'https://connect.facebook.net/en_US/sdk.js';
    const promiseCreater = () => {
        return new Promise<void>((resolve) =>{
            (window as any).fbAsyncInit = () => {
                (window as any).FB.init(FacebookConfig);
                resolve();
            }
        });
    };
    return createLoadPromise(ScriptType.FB, api_url, promiseCreater);
}
/**
 * Facebookのアクセストークン取得
 */
export function getFacebookAccessToken(): Promise<string> {
    const scope = 'groups_access_member_info';

    const checkConnectPromise = () => {
        return new Promise<string>((resolve) => {
            (window as any).FB.getLoginStatus((response: any) => {
                if (response.status !== 'connected') {
                    resolve('');
                }
                // 接続済みの場合、必要ば権限が付与されているかチェック
                console.log('connected')
                const scopes = scope.split(',');
                (window as any).FB.api('/me/permissions', (res: {data: {permission: string, status: string}[]}) => {
                    if ('error' in res) {
                        resolve('');
                        return;
                    }
                    console.log('permission', res);
                    const granted = scopes.every((target) => {
                        const hit = res.data.find((data) => {
                            return data.permission === target;
                        });
                        if (hit === undefined) {
                            return false;
                        }
                        return hit.status === 'granted';
                    });
                    console.log('granted', granted);
                    if (granted) {
                        resolve(response.authResponse.accessToken);
                    }else{
                        resolve('');
                    }
                });
            });
        });
    };

    return new Promise<string>((resolve, reject) => {
        loadFacebookAPI()
        .then(()=>{
            return checkConnectPromise();
        })
        .then((token: string) => {
            if (token.length > 0) {
                resolve(token);
            } else {
                console.log('get new token');
                (window as any).FB.login((response: any) => {
                    if (response.authResponse?.accessToken === undefined) {
                        reject();
                        return;
                    }
                    resolve(response.authResponse.accessToken);
                }, {scope})
            }
        });
    });
}

/**
 * スクリプトロード
 * @param api_url スクリプトタグに埋め込むURL
 * @param createAfterTagPromise  スクリプトタグ生成後に実行する非同期処理。この処理の実行完了したら、ロード完了とみなす。
 */
function createLoadPromise(kind: ScriptType, api_url: string, createAfterTagPromise: () => Promise<void>):Promise<any> {
    return new Promise((resolve) =>{
        if (loadingAPI[kind] === 2) {
            resolve((window as any)[kind]);
            return;
        }
        if (loadingAPI[kind] !== 0) {
            // 既に別コンポーネントでロード開始している場合は待つ
            const checkFunc = () => {
                if (loadingAPI[kind] === 2) {
                    resolve((window as any)[kind]);
                } else {
                    setTimeout(checkFunc, 100);
                }                        
            }
            checkFunc();
            return;
        }
        loadingAPI[kind] = 1;
        createScriptTag(api_url);
        createAfterTagPromise()
        .then(() => {
            loadingAPI[kind] = 2;
            return waitScriptObjectCreated(kind);
        }).then(()=> {
            resolve((window as any)[kind]);
        })
    })
}
function createScriptTag(url: string) {
    const tag = document.createElement('script');
    tag.src = url;

    const firstScriptTag = document.getElementsByTagName('script')[0];
    if (firstScriptTag.parentNode !== null) {
        firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
    }

}
function waitScriptObjectCreated(kind: ScriptType): Promise<void> {
    return new Promise<void>((resolve) => {
        const chekFunc = () => {
            if ((window as any)[kind] !== undefined) {
                resolve();
            } else {
                setTimeout(chekFunc, 100);
            }
        };
        chekFunc();
    });
}
/**
 * 画像ロード用のImageオブジェクトを生成して返す
 * @param callback 画像ロード完了時のコールバック
 * @param errorCallback 画像ロード失敗時のコールバック
 */
export function createImageForLoad(param: {
    callback: (image: ImageInfo) => void, 
    errorCallback?: () => void,
    maxOriginalSize?:number,    // 大サイズ画像長辺サイズ
    maxThumbSize?: number,      // サムネイル長辺サイズ
}): HTMLImageElement {
    const img = new Image();
    img.crossOrigin = 'anonymous';
    img.onload = () => {
        const LARGE_LONG_SIZE = param.maxOriginalSize ? param.maxOriginalSize : 1200;   // 大サイズ画像長辺サイズ
        const THUMB_LONG_SIZE = param.maxThumbSize ? param.maxThumbSize : 150;   // サムネイル長辺サイズ
        const kind = img.src.indexOf('image/png') !== -1 ? 'png' : 'jpeg';

        const image = {
            original: createImageShrink(img, LARGE_LONG_SIZE, kind),
            thumb: createImageShrink(img, THUMB_LONG_SIZE, kind),
        };
        param.callback(image);
    };
    img.onerror = (err) => {
        console.warn('err', err);
        if (param.errorCallback !== undefined) {
            param.errorCallback();
        }
    };
    return img;
}
/**
 * グリニッジ標準日時の日時文字列をDate型に変換する
 * @param dateStr 
 */
export function convertUtcDateTimeStrToLocaleDate(dateStr: string): Date {
    dayjs.extend(utc);
    dayjs.extend(timezone);
    return dayjs.tz(dateStr, "GMT").toDate();
}
export function convertDateToStr(d: Date): string {
    return dayjs(d).format('YYYY/MM/DD HH:mm');
}
export function convertDateToDateStr(d: Date): string {
    return dayjs(d).format('YYYY-MM-DD');
}
export function convertDateToTimeStr(d: Date): string {
    return dayjs(d).format('HH:mm');
}
/**
 * targetの日時の日付をdateで差し替えたものを返す
 * @param target 
 * @param date 
 */
export function replaceDate(target: Date, date: Date): Date {
    const dd = dayjs(date);
    const dt = dayjs(target);
    const result = dt.year(dd.year()).month(dd.month()).date(dd.date());
    return result.toDate();
}
/**
 * targetの日時の時間をtで差し替えたものを返す
 * @param target 
 * @param t hh:mm形式の時刻文字列
 */
export function replaceTime(target: Date, t: string): Date {
    const dt = dayjs(target);
    const result = dayjs(dt.format('YYYY-MM-DD ') + t, 'YYYY-MM-DD HH:mm');
    return result.toDate();
}