import { Extent } from 'ol/extent';
import { ConfirmDialogParam, DialogResult, KickCommand } from '../../types/operation';
import { MapMode } from '../../types/types';
import {ReducerType, State, SelectFeatureAction, ActionTypes, ChangeModeAction, ConfirmAction, SetConfirmDialogResultAction, KickCommandAction, UpdateMapViewExtentAction, DisabledPopupAction, DisabledLandNameAction} from './types';

const initialState = {
    mode: MapMode.LOADING,
    selectFeatureId: undefined,
    focusInfoId: undefined,
    confirmDialogParam: undefined,
    confirmDialogResult: undefined,
    kickCommand: undefined,
    mapViewExtent: [0, 0, 0, 0] as Extent,
    zoom: 0,
    disabledPopup: false,
    disabledLandName: false,
} as State;

export const reducer = (state = initialState, action: ActionTypes): State => {
    switch (action.type) {
        case ReducerType.ChangeMode:
            return changeModeReducer(state, (action as ChangeModeAction).payload);

        case ReducerType.SelectFeature:
            return selectFeatureReducer(state, (action as SelectFeatureAction).payload);

        case ReducerType.ShowConfirmDialog:
            return showConfirmDialogReducer(state, (action as ConfirmAction).payload);

        case ReducerType.SetConfirmDialogResult:
            return setConfirmDialogResultReducer(state, (action as SetConfirmDialogResultAction).payload);

        case ReducerType.KickCommand:
            return kickCommandReducer(state, (action as KickCommandAction).payload);

        case ReducerType.UpdateMapViewExtent:
            return updateMapViewExtentReducer(state, (action as UpdateMapViewExtentAction).payload);
    
        case ReducerType.DisabledPopup:
            return disabledPopup(state, (action as DisabledPopupAction).payload);

        case ReducerType.DisabledLandName:
            return disabledLandName(state, (action as DisabledLandNameAction).payload);
            
        default:
            return state;
    }
}

/**
 * 地図の状態を変更する
 * @param state 
 * @param payload 変更後の地図モード。必要に応じてオプション情報。
 */
function changeModeReducer(state: State, payload: {mode: MapMode}) {
    return Object.assign({}, state, {
        mode: payload.mode,
    });
}

/**
 * 選択図形IDを変更する
 * @param state 
 * @param payload 選択図形ID
 */
function selectFeatureReducer(state: State, payload: {id: string | undefined, infoId?: number}): State {
    return Object.assign({}, state, {
        selectFeatureId: payload.id,
        focusInfoId: payload.infoId,
    });
}

function showConfirmDialogReducer(state: State, payload: ConfirmDialogParam): State {
    return Object.assign({}, state, {
        confirmDialogParam: payload,
    });
}

function setConfirmDialogResultReducer(state: State, payload: DialogResult | undefined): State {
    return Object.assign({}, state, {
        confirmDialogParam: undefined,
        confirmDialogResult: payload,
    });
}

function kickCommandReducer(state: State, payload: {command: KickCommand, option?: any}): State {
    return Object.assign({}, state, {
        kickCommand: payload,
    })
}

function updateMapViewExtentReducer(state: State, payload: {
    extent: Extent,
    zoom: number
}) {
    return Object.assign({}, state, {
        mapViewExtent: payload.extent,
        zoom: payload.zoom,
    });
}

function disabledPopup(state: State, payload: {disabled: boolean}): State {
    return Object.assign({}, state, {
        disabledPopup: payload.disabled,
    });
}

function disabledLandName(state: State, payload: {disabled: boolean}): State {
    return Object.assign({}, state, {
        disabledLandName: payload.disabled,
    });
}
