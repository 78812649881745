import { VillageDocItem } from "../../types/feature";
import { GroupInfo, StructureImageDefine, VillageUserInfo } from "../../types/types";

export type State = {
    village: VillageDocItem;        // 村情報
    signInUserId: string | undefined;     // サインインユーザID
    users: { [id: string]: VillageUserInfo };   // ユーザ情報
    groups: { [id: string]: GroupInfo };    // グループ情報
    iconDefine: StructureImageDefine[];  // 建物アイコン画像定義
}

export enum ReducerType {
    RegistVillageInfo = 'RegistVillageInfo',
    SignIn = 'SignIn',
    RegistUserInfo = 'RegistUserInfo',
    RegistGroupInfo = 'RegistGroupInfo',
    RemoveGroupInfo = 'RemoveGroupInfo',
    SetIconDefine = 'SetIconDefine',
}

export interface RegistVillageInfoAction {
    type: ReducerType;
    payload: VillageDocItem;
}

export interface SingInAction {
    type: ReducerType;
    /** ユーザID */
    payload: string | undefined;
}

export interface RegistUserAction {
    type: ReducerType;
    payload: VillageUserInfo;
}

export interface RegistGroupAction {
    type: ReducerType;
    payload: GroupInfo;
}

export interface RemoveGroupAction {
    type: ReducerType;
    /** グループID */
    payload: string;
}

export interface LoadIconDefineAction {
    type: ReducerType;
    payload: StructureImageDefine[];
}
export type ActionTypes = RegistVillageInfoAction | SingInAction | RegistUserAction | RegistGroupAction | RemoveGroupAction | LoadIconDefineAction;
