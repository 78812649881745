import React, { useState } from 'react'
import { DispatchProps } from '../../types/types';
import { RootState } from '../../store';
import { connect } from 'react-redux';
import styles from './PopupControlPanel.module.scss';
import '../../styles/_common.scss';
import * as operationActions from '../../store/operation/actions';
import { KickCommand } from '../../types/operation';
import "react-toggle/style.css";
import Toggle from 'react-toggle';
import * as featureThunks from '../../store/feature/thunks';
import * as featureActions from '../../store/feature/actions';

type OwnProps = {
    /** 親からもらうprops定義 */
}
type StateProps = OwnProps & {
    /** ストアの内容から生成するprops定義 */
}
type Props = StateProps & DispatchProps;

const mapStateToProps = (state: RootState, ownProps: OwnProps): StateProps => ({
    ...ownProps,
});

function PopupControlPanel(props: Props) {
    const [waiting, setWaiting] = useState(false);

    const onAllClose = () => {
        props.dispatch(operationActions.kickCommand(KickCommand.AllPopupClose));
    }

    const onAllOpen = () => {
        props.dispatch(operationActions.kickCommand(KickCommand.AllPopupOpen));
    }

    const onUnreadInfoFeatureThemeChanged = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const checked = event.target.checked;
        
        if (checked) {
            // 強調表示On
            // スピナー表示
            setWaiting(true);
            await props.dispatch(featureThunks.unreadInfoFeatureThemeThunk());
            setWaiting(false);
        } else {
            // 強調表示Off
            props.dispatch(featureActions.updateThemeDefinesAction([]));
        }
    }

    return (
        <div className={styles.PopupControlPanel}>
            <h6>看板操作</h6>
            全看板を<br/>
            <button className="btn btn-light btn-sm" onClick={onAllOpen}>開く</button>
            <button className="btn btn-light btn-sm" onClick={onAllClose}>閉じる</button>
            <h6>最終訪問後に変化のあった建物</h6>
            <Toggle id={'toggle-unread'} defaultChecked={false} 
                        icons={{
                            unchecked: null,
                        }}
                        className='my-toggle'
                    onChange={onUnreadInfoFeatureThemeChanged} />

            {waiting &&
                <div className={`${styles.spinnerArea} spinner-border text-secondary`} role="status">
                    <span className="sr-only">Loading...</span>
                </div>
            }

        </div>
    );
}
export default connect(mapStateToProps)(PopupControlPanel);
