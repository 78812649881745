import { KickCommand } from "../types/operation";
import * as operationActions from '../store/operation/actions';
import { useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../store";

export function useCommandCatch(command: KickCommand, func: (option: any)=>void) {
    const dispatch = useDispatch();
    const kickCommand = useSelector((state: RootState) => state.operationReducer.kickCommand?.command);
    const kickCommandOption = useSelector((state: RootState) => state.operationReducer.kickCommand?.option);

    useEffect(() => {
        if (kickCommand === command) {
            func(kickCommandOption);
            dispatch(operationActions.commandKicked());
        }
    })
}

export function usePrevious<T>(value: T): T {
    const ref = useRef(null as any);
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
}