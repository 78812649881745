import React from 'react';
import { DispatchProps, Feature, StructureImageDefine } from '../../../types/types';
import SelectStructureDialog from './SelectStructureDialog';
import * as dbAccessor from "../../../util/DbAccessor";
import { RootState } from '../../../store';
import { connect } from 'react-redux';

/**
 * 建物アイコン変更コントローラ
 */
 type OwnProps = {
    /** 親からもらうprops定義 */
    target: Feature;    // 変更対象の建物
    close: () => void;  // 編集完了時のコールバック
}

type StateProps = OwnProps & {
    /** ストアの内容から生成するprops定義 */
    iconDefine: StructureImageDefine[],
}

type Props = StateProps & DispatchProps;

const mapStateToProps = (state: RootState, ownProps: OwnProps): StateProps => ({
    ...ownProps,
    iconDefine: state.systemReducer.iconDefine,
});

function ChangeStructureIconController(props: Props) {
    const properties = (props.target.geoJson as GeoJSON.Feature).properties;
    const currentIcon = props.iconDefine.find((def) => {
        if (properties === null) {
            return false;
        }
        if (properties.iconId === def.id) {
            return true;
        }
        // TODO: 全てがidに置き換わったら、この式は消す
        if (properties.iconPath === def.imagePath) {
            return true;
        }
        return false;
    });

    const onOk = (iconDefine: StructureImageDefine) => {
        const id = props.target.id;
        const geoJson = JSON.parse(JSON.stringify(props.target.geoJson)) as GeoJSON.Feature;
        if (geoJson.properties === null) {
            geoJson.properties = {};
        }
        if (geoJson.properties.iconPath) {
            delete geoJson.properties.iconPath;
        }
        geoJson.properties.iconId = iconDefine.id;

        // DB更新
        dbAccessor.updateFeature(id, geoJson);
        props.close();
    }

    return (
        <SelectStructureDialog currentIconId={currentIcon?.id} ok={onOk} cancel={props.close} />
    );
}
export default connect(mapStateToProps)(ChangeStructureIconController);
