import VectorSource from "ol/source/Vector";
import { FeatureLike } from 'ol/Feature';
import { Fill, Stroke, Style, Text, Icon } from 'ol/style';
import { StyleFunction } from "ol/style/Style";
import ImageStyle from "ol/style/Image";
import GeometryType from "ol/geom/GeometryType";
import { STRUCTURE_COLOR, SELECT_COLOR } from '../../styles/constants';
import { StructureImageDefine, ThemeDefine } from "../../types/types";
import MapDefine from './map_define';

/**
 * 建物のスタイル生成
 *
 * @export
 * @class StructureStyleFunctionCreator
 */
export default class StructureStyleFunctionCreator {
    private structureSource: VectorSource;
    private themeDefines: ThemeDefine[];
    private iconDefine: StructureImageDefine[];

    /**
     * 
     * @param structureSource 
     */
    constructor(structureSource: VectorSource, iconDefine: StructureImageDefine[]) {
        this.structureSource = structureSource;
        this.themeDefines = [];
        this.iconDefine = iconDefine;
    }

    public setThemeDefines(themeDefines: ThemeDefine[]) {
        this.themeDefines = themeDefines;
    }

    public setIconDefine(iconDefine: StructureImageDefine[]) {
        this.iconDefine = iconDefine;
    }

    /**
     * 建物用のスタイルFunctionを返す
     * @param force 色強調する場合、true
     */
    public getStructureStyleFunction(force?: boolean): StyleFunction {
        return (feature: FeatureLike, resolution: number) => {
            const iconDef = this.iconDefine.find((def) => {
                if (feature.getProperties().iconId) {
                    return feature.getProperties().iconId === def.id;
                } else {
                    return feature.getProperties().iconPath === def.imagePath;
                }
            });
            if (iconDef === undefined) {
                console.warn('該当アイコン情報が見つかりません', feature.getId(), feature.getProperties());
            }
            
            // 主題図対象の建物の場合、色を設定
            let color;
            const hitDef = this.themeDefines.find((def) => {
                return def.featureInfoMap[feature.getId() as string] !== undefined;
            });
            if (hitDef !== undefined) {
                color = hitDef.color;
            }
            // 強調する
            if (force) {
                color = '#0000ff';
            }

            // 地図上でY座標が下のものほど手前に表示するようにする
            const zIndex = this.getZindex(feature);

            const iconPath = iconDef?.imagePath;
            const style = iconPath ?
             new Style({
                image: this.getImageStyle(iconPath, resolution, color),
                zIndex,
            })
            :
            new Style({
                zIndex,
            });


            if (resolution <= MapDefine.StructureLabelResolution) {
                // ラベル設定
                const text = this.createLabel(feature);
                style.setText(text);
            }
            
            return style;

        }
    }

    public get selectedFeatureStyleFunction(): StyleFunction {
        return (feature: FeatureLike, resolution: number) => {
            console.log('style func', feature.getId());
            // 建物の強調表示
            const style = this.getStructureStyleFunction()(feature, resolution) as Style;
            style.getImage().load();
            const image = style.getImage().getImage(1);

            // 選択線をつける
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d') as CanvasRenderingContext2D;
            const offset = 1;

            canvas.width = image.width + offset * 2;
            canvas.height = image.height + offset * 2;

            ctx.lineWidth = 10;
            ctx.strokeStyle = SELECT_COLOR;
            ctx.strokeRect(0, 0, canvas.width, canvas.height);

            ctx.drawImage(image, offset, offset, image.width, image.height);

            const selectedImage = new Icon(/** @type {olx.style.IconOptions} */({
                src: undefined,
                img: canvas,
                imgSize: canvas ? [canvas.width, canvas.height] : undefined,
                scale: 0.5 / resolution,
            }));

            style.setImage(selectedImage);

            let width = 3;

            style.setStroke(
                new Stroke({
                    color: SELECT_COLOR,
                    width,
                }),
            );
            return style;
        }
    }

    // 建物描画用スタイル
    public drawStructureStyle(iconDefine: StructureImageDefine): StyleFunction {
        return (feature: FeatureLike, resolution: number) => {
            if (feature.getGeometry().getType() === GeometryType.POINT) {
                const iconPath = iconDefine.imagePath;
                return new Style({
                    image: this.getImageStyle(iconPath, resolution),
                });
            }
            return new Style({
                stroke: new Stroke({
                    color: STRUCTURE_COLOR,
                    width: 1
                }),
                fill: new Fill({
                    color: STRUCTURE_COLOR,
                }),
                zIndex: 1,
            });
        };
    }

    /**
     * 建物名ラベルを生成して返す
     * @param feature 
     */
    private createLabel(feature: FeatureLike): Text {
        // ラベル設定
        let name = feature.getProperties().name;
        if (name === undefined) {
            name = '';
        }

        const text = new Text({
            textAlign: 'center',
            textBaseline: 'middle',
            text: name,
            overflow: true,
            backgroundFill: new Fill({ color: '#ffffffaa' }),
            font: '1rem Calibri,sans-serif',
        });

        return text;
    }

    private getZindex(feature: FeatureLike): number {
        // 地図上でY座標が下のものほど手前に表示するようにする
        const allExtent = this.structureSource.getExtent();
        const maxY = Math.max(allExtent[1], allExtent[3]);
        const extent = feature.getGeometry().getExtent();
        const zIndex = Math.round(Math.abs(extent[1] - maxY));

        return zIndex;
    }

    private getImageStyle(iconPath: string, resolution: number, color?: string): ImageStyle {
        return new Icon({
                    src: iconPath,
                    color,
                    scale: 0.5 / resolution,
                });
    }
    
}
