import * as jsts from 'jsts';
import OlFeature from 'ol/Feature';
import MultiLineString from 'ol/geom/MultiLineString';
import GeometryType from 'ol/geom/GeometryType';
import GeoJSON from 'ol/format/GeoJSON';
import Circle from 'ol/geom/Circle';
import Geometry from 'ol/geom/Geometry';
import Point from 'ol/geom/Point';
import LineString from 'ol/geom/LineString';
import LinearRing from 'ol/geom/LinearRing';
import Polygon from 'ol/geom/Polygon';
import MultiPoint from 'ol/geom/MultiPoint';
import MultiPolygon from 'ol/geom/MultiPolygon';
import { FeatureType, Feature, RoadWidth } from '../../types/types';

const radiusRatio = 100000;

export default class MapUtil {
    public static getRoadWidth(width: RoadWidth): number {
        switch(width) {
            case RoadWidth.S:
                return 3;
            case RoadWidth.M:
                return 6;
            case RoadWidth.L:
                return 18;
            default:
                console.warn('other', width);
                return 0;
        }
    }

    /**
     * 地物レイヤを生成
     *
     * @private
     * @param {Feature} feature
     * @memberof MapOL
     */
    public static createOlFeatureFromFeature(feature: Feature): OlFeature<Geometry> {
        const geoJson = feature.geoJson;

        const myFeature = MapUtil.createOlFeature(geoJson);
        myFeature.setProperties({
            type: feature.type,
            name: feature.name,
        });
        myFeature.setId(feature.id);

        if (feature.type === FeatureType.ROAD) {
            let widthType = RoadWidth.M;
            if (feature.geoJson.properties !== undefined && feature.geoJson.properties !== null) {
                const width = feature.geoJson.properties.width as RoadWidth;
                if (width !== undefined) {
                    widthType = width;
                }
            } 
            const distance = MapUtil.getRoadWidth(widthType);
            MapUtil.convertLineToPolygon(myFeature, distance);
        }
        return myFeature;
    }

    public static createOlFeature(geoJson: GeoJSON.Feature): OlFeature<Geometry> {
        let circleR = undefined;
        if (geoJson.properties === null || geoJson.properties === undefined) {
            geoJson.properties = {};
        }

        // 円は手動対応
        circleR = geoJson.properties.radius;
        if (circleR !== undefined) {
            circleR = circleR / radiusRatio;
        }

        let myFeature;
        if (circleR !== undefined) {
            const coordinates = (geoJson.geometry as any).coordinates as number[];
            myFeature = new OlFeature(new Circle(coordinates, circleR));
        } else {
            myFeature = (new GeoJSON()).readFeatures(geoJson)[0];
        }
        return myFeature;
    }

    /**
     * ラインジオメトリをポリゴンに変換する
     * @param lineFeature 変換対象のラインジオメトリ
     * @param distance ポリゴンに変換する際の幅
     */
    public static convertLineToPolygon(lineFeature: OlFeature<Geometry>, distance: number) {
        // 元のJSONを格納しておく
        const lineJson = this.createGeoJson(lineFeature);
        lineFeature.setProperties({
            lineJson,
        });
        try {
            const ol3Parser = new jsts.io.OL3Parser();
            (ol3Parser as any).inject(Point, LineString, LinearRing, Polygon, MultiPoint, MultiLineString, MultiPolygon);
            var jstsGeom = ol3Parser.read(lineFeature.getGeometry());
            var buffered = jstsGeom.buffer(distance, 2, 2);
            lineFeature.setGeometry(ol3Parser.write(buffered));

        } catch (e) {
            console.warn('jsts error', e);
        }
    }

    /** 
     * 指定のポリゴンがLineから生成されたものの場合、元のLineを返す
     */
    public static getOriginalLine(lineFeature: OlFeature<Geometry>): OlFeature<Geometry> | undefined {
        const lineJson = lineFeature.getProperties().lineJson;
        if (lineJson === undefined) {
            return undefined;
        }
        return this.createOlFeature(lineJson);
    }

    public static createGeoJson(feature: OlFeature): GeoJSON.Feature {
        const type = feature.getGeometry().getType();
        let geoJson;
        if (type === GeometryType.CIRCLE) {
            const circle = feature.getGeometry() as Circle;
            const radius = circle.getRadius() * radiusRatio;
            const coordinates = circle.getCenter();
            geoJson = {
                type: 'Feature',
                properties: {
                    radius,
                },
                geometry: {
                    type: "Point",
                    coordinates
                }
            }
        } else {
            geoJson = JSON.parse(new GeoJSON().writeFeature(feature));
        }
        return geoJson;
    }
}