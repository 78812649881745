import React from 'react';
import { FacebookEventInfo, FacebookInfoType, SourceInfo } from '../../../types/types';
import FacebookEventList from './FacebookEventList';
import * as CommonUtility from '../../../util/CommonUtility';

export type EventInfo = {
    title: string,
    contents: string,
    imgUrl: string | undefined,
    eventStartDate: Date,
    eventEndDate: Date | undefined,
    source: SourceInfo,
};

type Props = {
    /** 親からもらうprops定義 */
    cancel: () => void;
    imported: (param: EventInfo) => void;
}

export default function FacebookEventImport(props: Props) {
    const onFacebookEventImport = (token: string, item: FacebookEventInfo) => {
        const eventStartDate = CommonUtility.convertUtcDateTimeStrToLocaleDate(item.start_time);
        const eventEndDate = item.end_time === undefined ? undefined : CommonUtility.convertUtcDateTimeStrToLocaleDate(item.end_time);

        props.imported({
            title: item.name,
            contents: item.description,
            imgUrl: item.cover?.source,
            eventStartDate,
            eventEndDate,
            source: {
                source_type: 'facebook',
                id: item.id,
                info_type: FacebookInfoType.Event,
                token,
            },
        })
    }

    return (
        <FacebookEventList select={onFacebookEventImport} cancel={props.cancel} />
    );
}