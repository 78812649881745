import React, { useEffect, useMemo, useState } from 'react'
import { DispatchProps } from '../types/types';
import { RootState } from '../store';
import { connect } from 'react-redux';
import FirebaseAuth from '../components/auth/FirebaseAuth';
import * as dbAccessor from "../util/DbAccessor";
import styles from './SelectVillage.module.scss';
import * as firebase from 'firebase/app';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Modal } from 'react-bootstrap';

type RouteParam = {
}
type OwnProps = RouteComponentProps<RouteParam>;
type StateProps = OwnProps & {
    /** ストアの内容から生成するprops定義 */
    signInUserId: string | undefined;   // firebaseの認証が通ったか
}
type Props = StateProps & DispatchProps;

const mapStateToProps = (state: RootState, ownProps: OwnProps): StateProps => ({
    ...ownProps,
    signInUserId: state.systemReducer.signInUserId,
});

/**
 * 村選択画面
 */
function SelectVillage(props: Props) {
    // Public村情報
    const [publicVillages, setPublicVillages]  = useState({} as { [id: string]: string });
    // 登録済みの村情報
    const [registVillages, setRegistVillages] = useState({} as { [id: string]: string });
    // 一覧に表示する村一覧
    const villages = useMemo(() => {
        return Object.assign({}, registVillages, publicVillages);
    }, [publicVillages, registVillages]);

    const [showAuthDialog, setShowAuthDialog] = useState(false);

    // Public村情報を取得
    const getPublicVillages = async() => {
        const publicVillages = await dbAccessor.getPublicVillages();
        setPublicVillages(publicVillages);
    }
    const afterSignIn = async() => {
        const myVillages = await dbAccessor.getRegistedVillages();
        setRegistVillages(myVillages);
    }

    const signOut = () => {
        firebase.auth().signOut();
        setRegistVillages({});
    }

    const deleteAccount = () => {
        props.history.push('/delete');
    }

    useEffect(() => {
        getPublicVillages();
    }, []);

    useEffect(() => {
        if (props.signInUserId !== undefined) {
            afterSignIn();
        }else{
            setShowAuthDialog(false);
        }

    }, [props.signInUserId]);

    const footer = props.signInUserId === undefined ?
                (
                    showAuthDialog ?
                        <Modal show={true} onHide={()=>{setShowAuthDialog(false)}}>
                            <Modal.Header closeButton>
                                <Modal.Title>認証</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <FirebaseAuth />
                            </Modal.Body>
                        </Modal>
                    :
                        // Firebase認証待ち
                        <div className={styles.footer}>
                            <button className="btn btn-secondary" onClick={()=>{setShowAuthDialog(true)}}>Sign In</button>
                        </div>
                )
                :
                (
                    <React.Fragment>
                        <div className={styles.footer}>
                            <button className="btn btn-secondary" onClick={signOut}>Sign Out</button>
                        </div>
                        <div className={styles.DeleteAccount}>
                            <a href="./privacy_policy.html">プライバシーポリシー</a>
                            <button className="btn btn-outline-secondary" onClick={deleteAccount}>アカウント削除</button>
                        </div>
                    </React.Fragment>
                );

    // 登録済みの村一覧を表示
    const content = Object.values(villages).length > 0 ?
                    (
                        <React.Fragment>
                            <h3>入村可能な村</h3>
                            <div className="list-group">
                                {Object.keys(villages).map((id: string) => {
                                    return <a href={'./' + id} className="list-group-item list-group-item-action" key={id}>{villages[id]}</a>
                                })}
                            </div>
                        </React.Fragment>
                    )
                    :
                    (
                        <React.Fragment>
                            <p>村を登録してください。</p>
                        </React.Fragment>
                    );

    return (
        <div className={styles.SelectVillage}>
            <h2>関心村マップ</h2>
            <hr />
            {content}
            {footer}
        </div>
    )
}
export default withRouter(connect(mapStateToProps)(SelectVillage));
