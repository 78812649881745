import React, { Component } from 'react';
import { DispatchProps } from '../../types/types';
import { RootState } from '../../store';
import { connect } from 'react-redux';
import '../../styles/_common.scss';
import styles from './MainControlPanel.module.scss';
import ThemeSettingPanel from './ThemeSettingPanel';
import PopupControlPanel from '../map/PopupControlPanel';
import SearchPanel from './SearchPanel';
import CalendarControlPanel from './CalendarControlPanel';

/**
 * 地図の表示状態を変更する機能を載せたパネル
 */
type TabDefine = {
    iconClass: string;
    content: JSX.Element;
}
// MainControlPanel.module.scssの$tab-countも併せて変更すること！
const tabDefines = [
    {
        iconClass: 'icon-search',
        content: <SearchPanel />,
    },
    {
        iconClass: 'icon-price-tag',
        content: <ThemeSettingPanel />,
    },{
        iconClass: 'icon-calendar',
        content: <CalendarControlPanel />,
    },
    {
        iconClass: 'icon-chat_bubble_outline',
        content: <PopupControlPanel />,
    // },{
    //     iconClass: 'icon-target',
    //     content: <MapControlPanel />,
    }
] as TabDefine[];

type OwnProps = {
    /** 親からもらうprops定義 */
    close?: boolean;     // 初期状態を閉じた状態にする場合、true
}
type StateProps = OwnProps & {
    /** ストアの内容から生成するprops定義 */
}
type Props = StateProps & DispatchProps;

const mapStateToProps = (state: RootState, ownProps: OwnProps): StateProps => ({
    ...ownProps,
});

type State = {
    isOpen: boolean;
    currentTab: TabDefine;
}

class MainControlPanel extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            isOpen: props.close === undefined ? true : !props.close,
            currentTab: tabDefines[0],
        }
    }

    render() {
        const tabBtns = this.state.isOpen ? (
            <React.Fragment>
                {tabDefines.map((def, index) => {
                    let className = 'btn';
                    let onClick = ()=>this.setState({currentTab: def});
                    if (this.state.currentTab !== def) {
                        // カレントタブ以外
                        className += (' ' + styles.uncurrent);
                    } else {
                        // カレントタブの場合は、開閉ボタンの挙動
                        onClick=this.onOpenBtnClicked.bind(this);
                    }
                    return (
                        <button key={index} className={className} onClick={onClick}>
                            <i className={def.iconClass}></i>
                        </button>
                    );
                })}
            </React.Fragment>
        ) : (
            <button className="btn" onClick={this.onOpenBtnClicked.bind(this)}>
                <i className={this.state.currentTab.iconClass}></i>
            </button>
        );

        const content = this.state.currentTab.content;
        let frameClass = styles.Frame;
        if (!this.state.isOpen) {
            frameClass += (' ' + styles.close);
        }
        return (
            <div className={frameClass}>
                <div className={`${styles.TabBtnArea} glass-style`}>
                    {tabBtns}
                </div>
                <div className={styles.MainArea}>
                    <div className={`${styles.MainPanel} glass-style`}>
                        {this.state.isOpen && content}
                    </div>
                </div>
            </div>
        );
    }

    private onOpenBtnClicked() {
        this.setState((state) => {
            return {
                isOpen: !state.isOpen,
            }
        });
    }

}
export default connect(mapStateToProps)(MainControlPanel);
