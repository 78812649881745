import React, { Component } from 'react';
import { DispatchProps, Feature, FeatureType } from '../../types/types';
import { RootState } from '../../store';
import { connect } from 'react-redux';
import { Modal } from 'react-bootstrap';
import { storeGetter } from '../../util/props';
import * as dbAccessor from "../../util/DbAccessor";
import styles from './FeatureAttrDialog.module.scss';

/**
 * 地物の属性情報ダイアログ
 */
type OwnProps = {
    /** 親からもらうprops定義 */
    feature: Feature;
    close: () => void;  // ダイアログ閉じた際のコールバック
}
type StateProps = OwnProps & {
    /** ストアの内容から生成するprops定義 */
    userName: (id: string) => string;
}
type Props = StateProps & DispatchProps;

const mapStateToProps = (state: RootState, ownProps: OwnProps): StateProps => ({
    ...ownProps,
    userName: storeGetter(state).userName,
});

enum Mode {
    Normal,
    NameEdit,
}
type State = {
    mode: Mode;
    name: string;
    showSpinner: boolean;
}

class FeatureAttrDialog extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            mode: Mode.Normal,
            name: props.feature.name,
            showSpinner: false,
        }
    }

    render() {
        let nameComponent;
        switch(this.state.mode) {
            case Mode.Normal:
                nameComponent = (
                    <div className="col-sm-8">
                        <input type="text" readOnly className="form-control-plaintext" value={this.props.feature.name} />
                    </div>
                );
                break;
            case Mode.NameEdit:
                nameComponent = (
                    <React.Fragment>
                        <div className="col-sm-4">
                            <input type="text" className="form-control" value={this.state.name} onChange={this.onNameChanged.bind(this)} />
                            {this.state.showSpinner &&
                                <div className={`${styles.Spinner} spinner-border text-secondary`} role="status">
                                    <span className="sr-only">Loading...</span>
                                </div>
                            }
                        </div>
                        <div className="col-sm-4">
                            <button className="btn btn-primary" onClick={this.onNameEditOk.bind(this)} disabled={!this.isNameOkable}>更新</button>
                            <button className="btn btn-secondary" onClick={this.onNameEditCancel.bind(this)}>Cancel</button>
                        </div>
                    </React.Fragment>
                )
                break;
        }
        return (
            <Modal show={true} onHide={this.handleClose.bind(this)}>
                <Modal.Header closeButton>{this.kind}情報</Modal.Header>
                <Modal.Body>
                    <div className="form-group row">
                        <label className={`col-sm-4 col-form-label ${styles.Label}`}>
                            {this.kind}名
                            {this.state.mode === Mode.Normal &&
                                <i className="icon-edit" onClick={this.onNameEdit.bind(this)}></i>
                            }
                        </label>
                        {nameComponent}
                    </div>
                    <div className="form-group row">
                        <label className="col-sm-4 col-form-label">最終更新者</label>
                        <div className="col-sm-8">
                            <input type="text" readOnly className="form-control-plaintext" value={this.lastUpdateUserName} />
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-secondary" onClick={this.handleClose.bind(this)}>閉じる</button>
                </Modal.Footer>
            </Modal>
        );
    }

    private get kind(): string {
        switch(this.props.feature.type) {
            case FeatureType.STRUCTURE:
                return '建物';
            case FeatureType.EARTH:
                return '土地';
            case FeatureType.FOREST:
                return '緑地';
            case FeatureType.ROAD:
                return '道路';
            default:
                return '';
        }
    }

    private get lastUpdateUserName(): string {
        if (this.props.feature.user === undefined) {
            return '';
        }
        return this.props.userName(this.props.feature.user);
    }

    private handleClose() {
        this.props.close();
    }

    private onNameEdit() {
        this.setState({
            mode: Mode.NameEdit,
        });
    }

    private onNameChanged(event: React.ChangeEvent<HTMLInputElement>) {
        this.setState({
            name: event.target.value,
        });
    }

    private async onNameEditOk() {
        this.setState({
            showSpinner: true,
        });
        // name更新
        await dbAccessor.updateFeatureName(this.props.feature.id, this.state.name);
        this.setState({
            mode: Mode.Normal,
            showSpinner: false,
        });
    }

    private onNameEditCancel() {
        if (this.state.showSpinner) {
            return;
        }
        this.setState({
            mode: Mode.Normal,
        });
    }

    // OKボタンを有効にするか
    private get isNameOkable(): boolean {
        if (this.state.showSpinner) {
            return false;
        }
        return this.props.feature.name !== this.state.name;
    }
}
export default connect(mapStateToProps)(FeatureAttrDialog);
