import { FeatureInfo, FeatureInfoContainDel } from "../../types/info";
import { TagInfo } from "../../types/types";
import { ReducerType, ActionTypes } from "./types";

/**
 * 看板情報を登録する
 * @param info 登録情報
 */
export function addFeaturePopupInfoAction(info: FeatureInfo): ActionTypes {
    return {
        type: ReducerType.AddFeaturePopupInfo,
        payload: info,
    }
}

/**
 * 看板情報を更新する
 * @param info 更新情報
 */
export function updateFeaturePopupInfoAction(info: FeatureInfo): ActionTypes {
    return {
        type: ReducerType.UpdateFeaturePopupInfo,
        payload: info,
    };
}

/**
 * 看板情報を削除する
 * @param featureId 図形ID
 * @param infoId 情報ID
 */
export function removeFeaturePopupInfoAction(featureId: string, infoId: number): ActionTypes {
    return {
        type: ReducerType.RemoveFeaturePopupInfo,
        payload: {
            featureId,
            infoId,
        },
    };
}

/**
 * ポップアップ情報の差分をチェックして、必要に応じて既存のポップアップを追加・更新・削除する。
 * @param info 差分として受け取った情報
 */
export function checkPopupDiffInfoAction(info: FeatureInfoContainDel): ActionTypes {
    return {
        type: ReducerType.FlushFeaturePopupInfoDiff,
        payload: info,
    }
}

/**
 * タグ情報を追加する
 * @param tag 追加するタグ情報
 */
export function addTagInfoAction(tag: TagInfo): ActionTypes {
    return {
        type: ReducerType.AddTagInfo,
        payload: tag,
    };
}

/**
 * タグ情報を更新する
 * @param tag 更新後のタグ情報
 */
export function updateTagInfoAction(tag: TagInfo): ActionTypes {
    return {
        type: ReducerType.UpdateTagInfo,
        payload: tag,
    };
}

/**
 * タグ情報を削除する
 * @param tagId 削除するタグID
 */
export function removeTagInfoAction(tagId: string) {
    return {
        type: ReducerType.RemoveTagInfo,
        payload: tagId,
    };
}