import React, { useState } from 'react';
import { DispatchProps } from '../../types/types';
import { RootState } from '../../store';
import { connect } from 'react-redux';
import { MenuDefine } from './types';
import MenuBtn from './MenuBtn';
import styles from './LineMenu.module.scss';
import * as operationActions from '../../store/operation/actions';
import { KickCommand } from '../../types/operation';

/**
 * 画面サイズが大きい場合のメニュー
 */
type OwnProps = {
    /** 親からもらうprops定義 */
    define: MenuDefine[];
}
type StateProps = OwnProps & {
    /** ストアの内容から生成するprops定義 */
}
type Props = StateProps & DispatchProps;

const mapStateToProps = (state: RootState, ownProps: OwnProps): StateProps => ({
    ...ownProps,
});

function LineMenu(props: Props) {
    const [openedParent, setOpenedParent] = useState<MenuDefine|null>(null);

    const handleClick = (def: MenuDefine) => {
        if (openedParent === def) {
            setOpenedParent(null);
        } else {
            setOpenedParent(def);
        }
    }

    const createMenuBtnFunc = (define: MenuDefine, isParent: boolean = false) => {
        // 子が非表示の場合、子のガイドを表示する
        let guide;
        if (isParent && openedParent !== define) {
            const guideChild = define.children?.find((def) => {
                return def.guide !== undefined;
            });
            if (guideChild !== undefined) {
                guide = guideChild.guide;
            }
        } else {
            guide = define.guide;
        }

        const onClick = () => {
            if (isParent) {
                handleClick(define);
            } else {
                props.dispatch(operationActions.kickCommand(define.command as KickCommand));
                setOpenedParent(null);
            }
        }
    
        return (
            <MenuBtn isSubMenu={!isParent} iconClass={define.iconClass} onClick={() => onClick()} 
                guide={guide} disabled={define.disalbed} badge={define.badge}>{define.caption}</MenuBtn>
        );
    }

    const createMenuGroupFunc = (parentDefine: MenuDefine) => {
        const parent = createMenuBtnFunc(parentDefine, true);
        const children = parentDefine.children === undefined ? null :
                            parentDefine.children.map((def, index) => {
                                return (
                                    <React.Fragment key={index}>
                                        {createMenuBtnFunc(def)}
                                    </React.Fragment>
                                )
                            });    
        return (
            <div className={styles.menuCategory}>
                {parent}
                {openedParent === parentDefine &&
                    <span className={styles.subMenu}>
                        {children}
                    </span>
                }
            </div>
        );
    }

    return (
        <div className={styles.MenuArea}>
            {props.define.map((def, index) => {
                return (
                    <React.Fragment key={index}>
                        {createMenuGroupFunc(def)}
                    </React.Fragment>
                )
            })}
        </div>
    );

}
export default connect(mapStateToProps)(LineMenu);
