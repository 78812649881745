import { Dispatch } from "react";
import { Action } from "redux";
import { ThunkAction } from "redux-thunk";
import { RootState } from "..";
import * as dbAccessor from '../../util/DbAccessor';
import * as firebase from 'firebase/app';
import * as actions from './actions';
import { GroupInfoStore, VillageUserInfoStore, VillageInfo, StructureImageDefine } from "../../types/types";
import { VillageDocItem } from "../../types/feature";

/**
 * サインインorサインアウトの監視を開始する
 */
export function startWatchSignInOutThunk(): ThunkAction<void, RootState, unknown, Action<string>> {
    return async (dispatch: Dispatch<any>, getState: () => any) => {
        firebase.auth().onAuthStateChanged(async (user) => {
            if (user === null) {
                // サインアウト
                dispatch(actions.signOutAction());
            } else {
                dispatch(actions.signInAction(user.uid));
            }
        });
    }
}

/**
 * 入村する村IDを更新する.
 * @param villageId 村ID
 * @result 村情報
 */
export function updateVillageIdThunk(villageId: string): ThunkAction<Promise<VillageInfo>, RootState, unknown, Action<string>> {
    return async (dispatch: Dispatch<any>, getState: () => any) => {
        return new Promise<VillageInfo>((resolve, reject) => {
            dbAccessor.existVillage(villageId).then((villageInfo: VillageInfo | undefined) => {
                if (villageInfo === undefined) {
                    reject();
                }else{
                    // 村ID更新
                    dbAccessor.setVillageId(villageId);
                    resolve(villageInfo);
                }
            });
        });
    }
}

/**
 * ユーザ情報、その他情報読込。
 * 村に関するシステム情報の初期化が完了したら、Promise復帰する.
 */
export function loadSystemInfoThunk() {
    return async (dispatch: Dispatch<any>, getState: () => RootState) => {
        // 情報初期化
        let userLoaded = false;
        let groupLoaded = false;
        let villageLoaded = false;
        dbAccessor.getVillageUserCollection().onSnapshot((querySnapshot) => {
            querySnapshot.docChanges().forEach((change) => {
                const userDoc = change.doc;
                switch(change.type) {
                    case "added":
                    case "modified":
                        const data = userDoc.data() as VillageUserInfoStore;
                        dispatch(actions.registUserInfoAction({
                            id: userDoc.id,
                            name: data.name,
                            groups: data.groups,
                            special: data.special,
                            view: data.view,
                        }));
                        break;
                    case "removed":
                        // 現時点でユーザ削除契機なし
                        break;
                }
            });
            userLoaded = true;
        });
        dbAccessor.getGroupCollection().onSnapshot((querySnapshot) => {
            querySnapshot.docChanges().forEach((change) => {
                const groupDoc = change.doc;
                switch(change.type) {
                    case "added":
                    case "modified":
                        const data = groupDoc.data() as GroupInfoStore;
                        dispatch(actions.registGroupInfoAction({
                            id: groupDoc.id,
                            name: data.name,
                        }));
                        break;
                    case "removed":
                        dispatch(actions.removeGroupInfoAction(groupDoc.id));
                        break;
                }
            });
            groupLoaded = true;
        });
        dbAccessor.getVillageDoc().onSnapshot((doc) => {
            const data = doc.data() as VillageDocItem;
            dispatch(actions.registVillageInfoAction(data));
            villageLoaded = true;
        })

        return new Promise<void>((resolve) => {
            const f = () => {
                if (!(userLoaded && groupLoaded && villageLoaded)) {
                    setTimeout(f, 500);
                } else {
                    resolve();
                }
            }
            f();
        });
    }
}

/**
 * 最新の建物アイコン定義を読み込む
 */
export function loadIconDefineThunk() {
    return async (dispatch: Dispatch<any>, getState: () => RootState) => {
        dbAccessor.getIconHookDoc().onSnapshot(async(querySnapshot) => {
            const originalIconDefine = await dbAccessor.getIconList();
            console.log('load icon define', originalIconDefine);

            // TODO: jsonの置き場所変更
            const systemIconDefine = (require('../../structure_icon.json') as StructureImageDefine[])
                .map((def, index) => {
                    def.original = false;
                    return def;
                });
    
            const iconDefine = [] as StructureImageDefine[];
            Array.prototype.push.apply(iconDefine, systemIconDefine);
            Array.prototype.push.apply(iconDefine, originalIconDefine);
        
            dispatch(actions.loadIconDefineAction(iconDefine));
        });
    }
}