import { Extent } from "ol/extent";
import { DialogResult, KickCommand } from "../../types/operation";
import { MapMode } from "../../types/types";

export type State = {
    mode: MapMode;      // 地図の状態
    // modeAddtion: any;   // MapModeのオプション情報
    selectFeatureId: string | undefined;    // 選択中の建物or道
    focusInfoId: number | undefined; // selectFeatureId内の情報ID。情報パネル表示時にフォーカスを充てる。
    confirmDialogParam: {title?: string; message: string} | undefined;
    confirmDialogResult: DialogResult | undefined;
    kickCommand: {command: KickCommand, option?: any} | undefined;     // 特定の処理をキックする際に処理種別を格納。処理起動したらundefinedになる。
    mapViewExtent: Extent;  // 現在の地図の表示範囲
    zoom: number;
    disabledPopup: boolean;     // ポップアップ非表示にする場合、true
    disabledLandName: boolean;  // 島名表示機能をOffにする場合、true
}
export enum ReducerType {
    ChangeMode = 'ChangeMode',
    SelectFeature = 'SelectFeature',
    ShowConfirmDialog = 'ShowConfirmDialog',
    SetConfirmDialogResult = 'SetConfirmDialogResult',
    KickCommand = 'KickCommand',
    UpdateMapViewExtent = 'UpdateMapViewExtent',
    DisabledPopup = 'DisabledPopup',
    DisabledLandName = 'DisabledLandName',
}

export interface ChangeModeAction {
    type: ReducerType;
    payload: {
        mode: MapMode;
        addition: any;
    }
}

export interface SelectFeatureAction {
    type: ReducerType;
    payload: {
        id: string | undefined;  // 選択図形ID
        infoId?: number; // 情報ID
    }
}

export interface ConfirmAction {
    type: ReducerType;
    payload: {
        title?: string;
        message: string;
    }
}

export interface SetConfirmDialogResultAction {
    type: ReducerType;
    payload: DialogResult | undefined;
}

export interface KickCommandAction {
    type: ReducerType;
    payload: {
        command: KickCommand,
        option?: any,
    }
}

export interface UpdateMapViewExtentAction {
    type: ReducerType;
    payload: {
        extent: Extent,
        zoom: number
    };
}

export interface DisabledPopupAction {
    type: ReducerType,
    payload: {
        disabled: boolean,
    };
}

export interface DisabledLandNameAction {
    type: ReducerType,
    payload: {
        disabled: boolean,
    };
}

export type ActionTypes = SelectFeatureAction | ChangeModeAction | ConfirmAction | SetConfirmDialogResultAction | KickCommandAction | UpdateMapViewExtentAction | DisabledPopupAction | DisabledLandNameAction;
