import React from 'react';
import styles from './PromptMessageBox.module.scss';

type Props = {
    /** 親からもらうprops定義 */
    message: string;
    okname?: string,
    okdisabled?: boolean,   // trueの場合、ok無効
    cancel?: () => void;
    ok?: () => void;
    children?: JSX.Element;
}

/**
 * 描画等の操作指示メッセージボックス
 */
export default function PromptMessageBox(props:  Props) {
    let okBtn = null;

    const okCaption = props.okname !== undefined ? props.okname : 'OK';

    if (props.ok !== undefined) {
        if (props.okdisabled) {
            okBtn = <button className="btn btn-primary disabled">{okCaption}</button>
        } else {
            okBtn = <button className="btn btn-primary" onClick={props.ok}>{okCaption}</button>
        }
    }
    return (
        <div className={styles.PromptMessageBox}>
            <div className={styles.message}>
                {props.message}
            </div>
            {props.children !== undefined &&
                <div>{props.children}</div>
            }
            {okBtn}
            {props.cancel !== undefined &&
                <button className="btn btn-secondary" onClick={props.cancel}>Cancel</button>
            }
        </div>
    );
}
