import React, { useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import { MapMode, FeatureType, DispatchProps, Feature, VillageUserInfo } from "../../types/types";
import { storeGetter } from '../../util/props';
import { RootState } from "../../store";
import * as dbAccessor from "../../util/DbAccessor";
import { MenuDefine } from "./types";
import LineMenu from "./LineMenu";
import CircleMenu from "./CircleMenu";
import MyMediaQuery from "../common/MyMediaQuery";
import styles from './Menu.module.scss';

type OwnProps = {
    /** 親からもらうprops定義 */
}
type StateProps = OwnProps & {
    /** ストアの内容から生成するprops定義 */
    currentMapMode: MapMode;
    selectedFeature: Feature | undefined;
    specialUser: boolean;
    features: { [id: string]: Feature };    // 地物情報
    mapMode: MapMode;
    userInfo: VillageUserInfo | undefined; // 現在のユーザ情報
};

const mapStateToProps = (state: RootState, ownProps: OwnProps): StateProps => ({
    ...ownProps,
    currentMapMode: state.operationReducer.mode,
    selectedFeature: storeGetter(state).selectedFeature,
    specialUser: state.systemReducer.users[state.systemReducer.signInUserId as string]?.special === true ? true : false,
    features: state.featureReducer.features,
    mapMode: state.operationReducer.mode,
    userInfo: state.systemReducer.users[state.systemReducer.signInUserId!],
});

type Props = StateProps & DispatchProps;

const guideStructure = '建物を建ててみましょう';
const guideEarth = '最初に島を作りましょう';
function Menu(props: Props) {
    const menuStyle = useMemo(() => {
        return props.mapMode === MapMode.NORMAL ? styles.enabled : styles.disabled;
    }, [props.mapMode]); 

    /**
     * 選択中の地物種別を返す
     */
    const selectFeatureType = useMemo((): FeatureType | undefined => {
        const feature = props.selectedFeature;
        if (feature === undefined) {
            return undefined;
        }
        return feature.type;
    },[props.selectedFeature]);


    const structureCount = useMemo((): number => {
        const structures = Object.values(props.features).filter((feature) => {
            return feature.type === FeatureType.STRUCTURE;
        });
        return structures.length;
    }, [props.features]);

    const earthCount = useMemo((): number => {
        const earthes = Object.values(props.features).filter((feature) => {
            return feature.type === FeatureType.EARTH;
        });
        return earthes.length;
    }, [props.features]);

    const showEarthGuide = useMemo((): boolean => {
        if (props.mapMode === MapMode.LOADING) {
            return false;
        }
        if (props.currentMapMode !== MapMode.NORMAL) {
            return false;
        }
        return earthCount === 0;
    }, [props.mapMode, props.currentMapMode, earthCount]);

    const showStructureGuide = useMemo((): boolean => {
        if (props.mapMode === MapMode.LOADING) {
            return false;
        }
        if (props.currentMapMode !== MapMode.NORMAL) {
            return false;
        }
        return earthCount > 0 && structureCount === 0;
    }, [props.mapMode, props.currentMapMode, earthCount, structureCount]);

    const [menuDefine, setMenuDefine] = useState([] as MenuDefine[]);

    const menuDefineExt = useMemo(() => {
        // 挙動追加
        const menuExtMap = {
            'DrawStructure': {
                guide: showStructureGuide ? guideStructure : undefined,
            },
            'ChangeStructure': {
                disalbed: selectFeatureType!==FeatureType.STRUCTURE,
            },
            'DrawEarth': {
                guide: showEarthGuide ? guideEarth : undefined,
            }
        } as {[command: string]: {guide?: string; disabled?: boolean}};
    
        const addExt = (menuItem: MenuDefine): MenuDefine => {
            const newMenuItem = Object.assign({}, menuItem);
            newMenuItem.children = menuItem.children?.map((child) => {
                return addExt(child);
            });

            if (menuItem.command === undefined) {
                return newMenuItem;
            }
            if (!(menuItem.command in menuExtMap)) {
                return newMenuItem;
            }
            const ext = menuExtMap[menuItem.command];
            return Object.assign(newMenuItem, ext);
        }

        return menuDefine.map((menuItem) => {
            return addExt(menuItem);
        });
    }, [menuDefine, selectFeatureType, showStructureGuide, showEarthGuide]);

    useEffect(() => {
        dbAccessor.getMenuDefine()
        .then((def: MenuDefine[]) => {
            console.log('getmenu', def);
            setMenuDefine(def);
        });
        // ログイン状態が切り替わったら再取得
    }, [props.userInfo]);

    return (
        <React.Fragment>
            <div className={menuStyle}>
                <MyMediaQuery>
                    <MyMediaQuery.LongHeightDevice>
                        <LineMenu define={menuDefineExt} />
                    </MyMediaQuery.LongHeightDevice>
                    <MyMediaQuery.ShortHeightDevice>
                        <CircleMenu define={menuDefineExt} />
                    </MyMediaQuery.ShortHeightDevice>
                </MyMediaQuery>
            </div>
        </React.Fragment>
    );


}
export default connect(mapStateToProps)(Menu);
