import React from 'react';
import styles from './ContentEditor.module.scss';
import { useInfoFormat } from '../../../hooks/InfoFormat';
import ReactDOMServer from 'react-dom/server';

/**
 * 情報コンテンツ編集用のリッチテキストエディタ
 */
type Props = {
    /** 親からもらうprops定義 */
    content: string;
    onChange: (content: string) => void;
}

/**
 * HTMLをStringに変換
 * @param div 
 * @returns 
 */
function convert(div: HTMLDivElement): string {
    const mynodes = div.childNodes;
    console.log('convert before', mynodes);

    const convertChilderen = (nodes: NodeListOf<ChildNode>) => {
        let text = '';
        let beforeNodeName = '';
        nodes.forEach((node) => {
            switch(node.nodeName) {
                case '#text':
                    text += node.nodeValue;
                    break;
                case 'BR':
                    text += '\n';
                    break;
                case 'DIV':
                case 'P':
                    if (beforeNodeName !== 'DIV' && beforeNodeName !== 'P') {
                        text += '\n';
                    }
                    text += convertChilderen(node.childNodes);
                    text += '\n';
                    break;
                case 'SPAN':
                    // 情報ID取得
                    const attr = (node as HTMLSpanElement).attributes;
                    const dataInfo = attr.getNamedItem('data-info');
                    if (dataInfo !== null) {
                        text += '[[info:' + dataInfo.value + ']]';
                    } else {
                        text += convertChilderen(node.childNodes);
                    }
                    break;
                default:
                    text += convertChilderen(node.childNodes);
            }
            beforeNodeName = node.nodeName;
        });
        return text;
    }
    return convertChilderen(mynodes);
}
export default function ContentEditor(props: Props) {
    const defaultContent = useInfoFormat(props.content, 'edit');

    /**
     * 詳細欄の値が更新された場合
     */
    const onChange = (event: React.ChangeEvent<HTMLDivElement>) => {
        const newContent = convert(event.target);
        console.log('change', newContent);
        props.onChange(newContent);
    }

    return (
        <div className={`form-control ${styles.textArea}`} contentEditable 
            dangerouslySetInnerHTML={
                {__html: ReactDOMServer.renderToString(defaultContent)}
            }
            onInput={onChange}
        />
    );
}