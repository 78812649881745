import React, { Component } from 'react';
import { DispatchProps, FeatureType, Feature } from '../../../types/types';
import { RootState } from '../../../store';
import { connect } from 'react-redux';
import OlFeature from 'ol/Feature';
import { Map } from 'ol';
import VectorLayer from 'ol/layer/Vector';
import { Select } from 'ol/interaction';
import { click } from 'ol/events/condition';
import { topographySelectStyleFunction } from '../style';
import { SelectEvent } from 'ol/interaction/Select';
import AttrEdit from '../../AttrEdit';
import * as dbAccessor from "../../../util/DbAccessor";
import PromptMessageBox from './PromptMessageBox';

enum RenameStage {
    SELECTING_FEATURE,  // 対象地形選択
    INPUT_NAME,             // 名称入力
}

type OwnProps = {
    /** 親からもらうprops定義 */
    map: Map;
    topographyLayers: VectorLayer[];    // リネーム対象となるレイヤ
    close: () => void;  // 編集完了時のコールバック
}
type StateProps = OwnProps & {
    /** ストアの内容から生成するprops定義 */
    features: { [id: string]: Feature };
}
type Props = StateProps & DispatchProps;

const mapStateToProps = (state: RootState, ownProps: OwnProps): StateProps => ({
    ...ownProps,
    features: state.featureReducer.features,
});

type State = {
    stage: RenameStage,
    selectedFeature: OlFeature | null;
    promptMessage: string;
}

/**
 * 地形名称変更コントロールクラス
 */
class RenameTopographyController extends Component<Props, State> {
    private topographySelect: Select;

    constructor(props: Props) {
        super(props);
        this.state = {
            stage: RenameStage.SELECTING_FEATURE,
            selectedFeature: null,
            promptMessage: '',
        }

        this.topographySelect = new Select({
            condition: click,
            layers: props.topographyLayers,
            style: topographySelectStyleFunction,
        });
        this.topographySelect.on('select', (evt: SelectEvent) => {
            let selectedFeature;
            if (evt.selected.length === 0) {
                selectedFeature = null;
            } else {
                selectedFeature = evt.selected[0];
            }
            this.setState({
                selectedFeature,
            });
        });

    }

    render() {
        switch (this.state.stage) {
            case RenameStage.SELECTING_FEATURE:
                return <PromptMessageBox message={this.state.promptMessage} ok={this.onSelectTargetOkClicked.bind(this)} cancel={this.onSelectTargetCancelClicked.bind(this)} okdisabled={this.state.selectedFeature === null} />
            case RenameStage.INPUT_NAME:
                if (this.state.selectedFeature === null) {
                    console.warn('選択地物なし')
                    return;
                }
                const id = this.state.selectedFeature.getId() as string;
                const feature = this.props.features[id];
                return <AttrEdit name={feature.name} type={FeatureType.EARTH} onOk={this.onAttrEditOkClicked.bind(this)} onCancel={this.onCancelAttrEdit.bind(this)} />
        }
    }

    componentDidUpdate(prevProps: Props, prevState: State) {
        if (prevState.stage !== this.state.stage) {
            this.onStageChanged(prevState.stage);
        }
    }

    componentDidMount() {
        this.onStageChanged();
    }

    componentWillUnmount() {
        this.props.map.removeInteraction(this.topographySelect);
        // this.modifySource.clear();
        // this.props.map.removeLayer(this.modifyLayer);
        // this.props.map.removeInteraction(this.modify);
    }

    // 編集対象選択時
    private onSelectTargetOkClicked() {
        this.setState({
            stage: RenameStage.INPUT_NAME,
        });
    }

    private onSelectTargetCancelClicked() {
        // 終了
        this.props.close();
    }

    private onStageChanged(prevStage?: RenameStage) {
        switch (prevStage) {
            case RenameStage.SELECTING_FEATURE:
                break;
            case RenameStage.INPUT_NAME:
                break;
        }

        switch (this.state.stage) {
            case RenameStage.SELECTING_FEATURE:
                // 地形を選択可能にする。
                this.props.map.addInteraction(this.topographySelect);
                this.setState({
                    promptMessage: '島名変更する地形を選択して、OKボタンを押下してください。',
                });
                break;

        }
    }

    /**
     * 名称登録
     * @param name 新名称
     */
    private async onAttrEditOkClicked(name: string) {
        if (this.state.selectedFeature === null) {
            console.warn('選択ジオメトリなし');
            return;
        }
        const id = this.state.selectedFeature.getId() as string;
        await dbAccessor.updateFeatureName(id, name);
        this.props.close();
    }

    /**
     * 属性入力キャンセル時
     *
     * @memberof MapOL
     */
    onCancelAttrEdit() {
        this.props.close();
    }

}
export default connect(mapStateToProps)(RenameTopographyController);
