import React, { useEffect, useState } from 'react';
import * as dbAccessor from "../util/DbAccessor";
import {InfoLink, InfoLinkWithStore} from '../components/infos/InfoLink';
import reactStringReplace from 'react-string-replace';
import YoutubePlayer from '../components/common/YoutubePlayer';

/**
 * 情報コンテンツを表示用に加工するフック
 * @param content コンテンツ文字列
 * @returns 
 */
export function useInfoFormat(content: string, mode: 'edit' | 'view'): React.ReactElement {
    const [defaultContent, setDefaultContent] = useState(<React.Fragment/> as React.ReactElement);
    // 情報タイトル(編集画面ではInfoLink内のuseEffectgが効かないので、このカスタムフック内でタイトル取得している)
    const [infoTitleMap, setInfoTitleMap] = useState({} as {[id: number]: string});

    useEffect(() => {
        // 改行
        let i=0;
        let newContent = reactStringReplace(content, '\n', () => {
            return <br key={'br-' + i++}/>;
        });

        // 情報IDリンク -> 専用タグ
        const regex = /\[\[info:([0-9]*)\]\]/g;
        i=0;
        newContent = reactStringReplace(newContent, regex, (match) => {
            const infoId = Number(match);
            if (mode === 'view') {
                return <InfoLinkWithStore infoId={infoId} key={'infolink-'+ i++} />;
            }

            const infoTitle = infoTitleMap[infoId] === undefined ? 'wait...' : infoTitleMap[infoId];
            if (infoTitleMap[infoId] === undefined) {
                dbAccessor.getInfoTitle(infoId)
                .then((title: string) => {
                    const newMap = Object.assign({}, infoTitleMap);
                    newMap[infoId] = title;
                    console.log('setInfoTitleMap', newMap);
                    setInfoTitleMap(newMap);
                });
            }
            console.log('title', infoTitle);
            return <InfoLink infoId={infoId} title={infoTitle} key={'infolink-'+ i++} />;
        });

        // Youtube -> 埋め込み動画
        if (mode === 'view') {
            //https://www.youtube.com/watch?v=qJ758lvFfMo
            //https://youtu.be/qJ758lvFfMo
            const youtubeRegExp = /(https?:\/\/www.youtube.com\/watch\?v=\S+|https?:\/\/youtu.be\/\S+)/g;
            i = 0;
            newContent = reactStringReplace(newContent, youtubeRegExp, (match) => {
                // VIDEO_IDを抽出
                let index = match.indexOf("v=");
                if (index === -1) {
                    index = match.lastIndexOf("/");
                } else {
                    index = index + 1;
                }
                const videoId = match.substring(index+1);
                return (
                    <YoutubePlayer videoId={videoId} isContiueInPopup={true} key={'yt-' + i++} />
                )
            });
        }

        // URL -> リンクタグ
        if (mode === 'view') {
            const regExp = /(https?:\/\/\S+)/g;
            i = 0;
            newContent = reactStringReplace(newContent, regExp, (match) => {
                return (
                    <a href={match} rel="noopener noreferrer" key={'a-' + i++} target="_blank">
                        リンク
                        <i className="icon-external-link" />
                    </a>
                );
            }
            );
        }
    
        setDefaultContent(
            <React.Fragment>{newContent}</React.Fragment>
            );
    }, [content, infoTitleMap, mode]);

    return defaultContent;
}

