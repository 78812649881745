import React, { Component } from 'react'
import { DispatchProps, FeatureType, StructureImageDefine } from '../../../types/types';
import { RootState } from '../../../store';
import { connect } from 'react-redux';
import { Map } from 'ol';
import OlFeature from 'ol/Feature';
import VectorSource from 'ol/source/Vector';
import VectorLayer from 'ol/layer/Vector';
import GeometryType from 'ol/geom/GeometryType';
import Draw, { DrawEvent } from "ol/interaction/Draw";
import StructureStyleFunctionCreator from '../StructureStyleFunctionCreator';
import MapUtil from '../MapUtil';
import * as dbAccessor from "../../../util/DbAccessor";
import AttrEdit from '../../AttrEdit';
import SelectStructureDialog from './SelectStructureDialog';
import PromptMessageBox from './PromptMessageBox';

// 描画状況
enum DrawStage {
    SELECTING_FEATURE,
    DRAWING,
    ATTR_INPUT, // 建物情報入力（建物描画時のみ）
}

type OwnProps = {
    /** 親からもらうprops定義 */
    map: Map;
    structureStyleCreator: StructureStyleFunctionCreator;
    close: () => void;  // 作図完了時のコールバック
}
type StateProps = OwnProps & {
    /** ストアの内容から生成するprops定義 */
}
type Props = StateProps & DispatchProps;

const mapStateToProps = (state: RootState, ownProps: OwnProps): StateProps => ({
    ...ownProps,
});

type State = {
    drawStage: DrawStage;
};

class DrawStructureController extends Component<Props, State> {
    private drawingFeature: OlFeature | undefined;  // 描画中のFeature
    private drawingSource: VectorSource = new VectorSource();
    private drawingLayer: VectorLayer = new VectorLayer({
        source: this.drawingSource,
    });

    private drawingIcon: StructureImageDefine | null = null;            // 作図するアイコン画像（drawingGeometryTypeがPOINTの場合に指定される）

    private draw = null as Draw | null;

    private registing: boolean = false;     // 登録処理中にtrue

    constructor(props: Props) {
        super(props);
        this.state = {
            drawStage: DrawStage.SELECTING_FEATURE,
        }

    }

    render() {
        let option = null;
        if (this.state.drawStage === DrawStage.ATTR_INPUT) {
            option = <AttrEdit name="" type={FeatureType.STRUCTURE} onOk={this.onAttrEditOkClicked.bind(this)} onCancel={this.onCancelAttrEdit.bind(this)} />

        } else if (this.state.drawStage === DrawStage.SELECTING_FEATURE) {
            // 建物選択ダイアログ
            option = <SelectStructureDialog ok={this.onSelectedStructure.bind(this)} cancel={this.onSelecteDrawFeatureMenuCanceled.bind(this)} />

        } else if (this.state.drawStage === DrawStage.DRAWING) {
            // 建物配置の場合は、OKボタン不要
            option = <PromptMessageBox message={'地図上に配置してください。'} cancel={this.onDrawMenuCanceled.bind(this)} />
        }
        return (
            <React.Fragment>
                {option}
            </React.Fragment >
        );
    }

    componentDidMount() {
        this.registing = false;
        this.drawingSource.clear();
        this.props.map.addLayer(this.drawingLayer);

        this.setState({
            drawStage: DrawStage.SELECTING_FEATURE,
        });
    }

    componentDidUpdate(prevProps: Props, prevState: State) {
        if (prevState.drawStage !== this.state.drawStage) {
            this.onDrawStageChanged(prevState.drawStage);
        }
    }

    componentWillUnmount() {
        this.drawReset();
        this.drawingSource.clear();
        this.props.map.removeLayer(this.drawingLayer);
    }

    /**
     * 作図フェーズ変更
     *
     * @private
     * @memberof MapOL
     */
    public onDrawStageChanged(prevStage?: DrawStage) {
        switch (prevStage) {
            case DrawStage.SELECTING_FEATURE:
                break;
            case DrawStage.DRAWING:
                break;
            case DrawStage.ATTR_INPUT:
                break;

        }
        switch (this.state.drawStage) {
            case DrawStage.SELECTING_FEATURE:
                break;

            case DrawStage.DRAWING:
                this.drawReset();
                let type = GeometryType.POLYGON;
                let style;
                type = GeometryType.POINT;
                style = this.props.structureStyleCreator.drawStructureStyle(this.drawingIcon as StructureImageDefine);
                this.drawingSource.clear();
                this.drawingLayer.setStyle(style);
                this.draw = new Draw({
                    source: this.drawingSource,
                    type,
                    style,
                    clickTolerance: 12,
                });
                this.props.map.addInteraction(this.draw);
                this.draw.on('drawend', (event: DrawEvent) => {
                    this.onDrawEnd(event.feature);
                });
                break;
            case DrawStage.ATTR_INPUT:
        }
    }

    private drawReset() {
        if (this.draw !== null) {
            this.draw.abortDrawing();
            this.drawingFeature = undefined;
            this.drawingSource.clear();
            this.props.map.removeInteraction(this.draw);
            this.draw = null;
        }
    }

    private onSelectedStructure(iconDefine: StructureImageDefine) {
        this.drawingIcon = iconDefine;
        this.setState({
            drawStage: DrawStage.DRAWING,
        });
    }

    /**
     * ジオメトリ種別選択キャンセル時
     *
     * @memberof MapOL
     */
    private onSelecteDrawFeatureMenuCanceled() {
        this.props.close();
    }

    /**
     * 描画終了メニューボタンが押された場合
     *
     * @memberof MapOL
     */
    private onDrawingFinishBtnClicked() {
        if (this.draw !== null) {
            this.draw.finishDrawing();
        }
    }

    // 描画中にキャンセルボタンが押された場合
    private onDrawMenuCanceled() {
        this.setState({
            drawStage: DrawStage.SELECTING_FEATURE,
        });
    }

    /**
     * 作図完了時
     *
     * @private
     * @param {OlFeature} feature
     * @memberof MapOL
     */
    private onDrawEnd(feature: OlFeature) {
        if (this.draw !== null) {
            this.props.map.removeInteraction(this.draw);
        }

        this.drawingFeature = feature;

        // 属性入力させる
        this.setState({
            drawStage: DrawStage.ATTR_INPUT,
        });

    }

    private async onAttrEditOkClicked(name: string) {
        this.registStructure(name);
    }

    /**
     * 建物情報登録
     * @param {*} name 建物名
     */
    private async registStructure(name: string) {
        if (this.drawingFeature === undefined) {
            console.warn('描画アイテムがありません');
            return;
        }
        // 二重登録防止
        if (this.registing) {
            console.log('二重登録防止');
            return;
        }
        this.registing = true;

        // シンボル画像
        const geoJson1 = MapUtil.createGeoJson(this.drawingFeature);
        if (geoJson1.properties === null) {
            geoJson1.properties = {};
        }
        geoJson1.properties['iconId'] = this.drawingIcon?.id;
        geoJson1.properties['size'] = 1;

        // DB登録
        await this.registFeatureDB(FeatureType.STRUCTURE, geoJson1, name);

        this.props.close();
    }

    /** 
     * 建物情報をDBに登録する
     * @return 図形ID
     */
    private async registFeatureDB(type: FeatureType, geoJson: GeoJSON.Feature, name?: string): Promise<string> {
        let parent = null;
        return await dbAccessor.registFeature({
            type,
            parent,
            geoJson,
            name: name === undefined ? '' : name,
        });
    }

    /**
     * 属性入力キャンセル時
     *
     * @memberof MapOL
     */
    onCancelAttrEdit() {
        this.props.close();
    }

}
export default connect(mapStateToProps)(DrawStructureController);
