import React, { Component, CSSProperties } from 'react';
import { DispatchProps, TagInfo } from '../../types/types';
import { RootState } from '../../store';
import { connect } from 'react-redux';
import { DEFAULT_TAG_COLOR } from '../../styles/constants';
import styles from './TagBadge.module.scss';
import * as infoThunks from '../../store/info/thunks';

/**
 * タグバッジ
 */
type OwnProps = {
    /** 親からもらうprops定義 */
    tag: TagInfo;
    colorChangeable?: boolean;  // trueの場合、色変更可能
    outline?: boolean;      // trueの場合、アウトライン表現
    click?: () => void;    // クリック時のコールバック。
    delete?: () => void;    // これが設定されている場合、×ボタンを表示し、×ボタン押下時にコールバックする
    colorChange?: (newColor: string) => void;   // タグ色が変更された場合に呼び出される
}
type StateProps = OwnProps & {
    /** ストアの内容から生成するprops定義 */
}
type Props = StateProps & DispatchProps;

const mapStateToProps = (state: RootState, ownProps: OwnProps): StateProps => ({
    ...ownProps,
});

type State = {
    color: string;
    colorSettingMode: boolean;  // タグ色変更中はtrue
}

class TagBadge extends Component<Props, State> {
    static _instanceArr = [] as TagBadge[]; // カラーピッカーを１つのタグバッジに対してのみ表示するためにインスタンス管理

    constructor(props: Props) {
        super(props);
        this.state = {
            color: props.tag.color === undefined ? DEFAULT_TAG_COLOR : props.tag.color,
            colorSettingMode: false,
        }
    }

    render() {
        return (
            <div className={styles.MainFrame}>
                <div className={`${styles.TagBadge} ${this.props.click!==undefined?styles.clickable:''} ${this.props.outline ? styles.outline : ''}`} style={this.style} onClick={this.onClick.bind(this)}>
                    <span>
                        {this.props.tag.name}
                    </span>
                    {this.props.delete !== undefined &&
                        <span className="delete" onClick={this.onDeleteClicked.bind(this)}><i className="icon-times"></i></span>
                    }
                </div>
                {this.state.colorSettingMode &&
                    <div className={styles.ColorPicker}>
                        <input type="color" value={this.state.color} onChange={this.onColorChanged.bind(this)} onBlur={this.onColorInputBlur.bind(this)} />
                    </div>
                }
            </div>
            );
    }

    componentDidMount() {
        TagBadge._instanceArr.push(this);
    }

    componentWillUnmount() {
        const index = TagBadge._instanceArr.findIndex((instance) => {
            return instance === this;
        });
        TagBadge._instanceArr.splice(index, 1);
    }

    componentDidUpdate(prevProps: Props, prevState: State) {
        if(prevProps.tag !== this.props.tag) {
            this.setState({
                color: this.props.tag.color === undefined ? DEFAULT_TAG_COLOR : this.props.tag.color,
            })
        }
    }

    private get style(): CSSProperties {
        if (this.props.outline) {
            return {
                borderColor: this.state.color,
                color: this.state.color,
            }
        } else {
            return {
                borderColor: this.state.color,
                backgroundColor: this.state.color,
            }
        }
    }

    private onDeleteClicked() {
        if (this.props.delete !== undefined) {
            this.props.delete();
        }
    }

    // タグがクリックされた場合
    private onClick(event: React.MouseEvent<HTMLInputElement>) {
        if (this.props.click !== undefined) {
            this.props.click();
        }
        if (!this.props.colorChangeable) {
            return;
        }
        // 色変更モードにする
        // 他のタグバッジのカラーピッカーは閉じる
        TagBadge._instanceArr.forEach((instance) => {
            const mode = (instance === this);
            instance.setState({
                colorSettingMode: mode,
            });
        });
        // event.stopPropagation();
    }

    private onColorChanged(event: React.ChangeEvent<HTMLInputElement>) {
        const value = event.target.value;

        // タグの色更新
        this.setState({
            color: value,
        });

        if (this.props.tag.id.length > 0) {
            // 登録済みタグの場合はDB更新
            const newTag = Object.assign({}, this.props.tag, {
                color: value,
            }) as TagInfo;

            this.props.dispatch(infoThunks.updateTagsInfoThunk(newTag));
        }
        
        if (this.props.colorChange !== undefined) {
            this.props.colorChange(value);
        }
    }

    /**
     * カラーピッカーから離れた場合
     */
    private onColorInputBlur() {
        if (!this.state.colorSettingMode) {
            return;
        }
        this.setState({
            colorSettingMode: false,
        });
    }

}
export default connect(mapStateToProps)(TagBadge);
