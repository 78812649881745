import { Dispatch } from "react";
import { Action } from "redux";
import { ThunkAction } from "redux-thunk";
import { RootState } from "..";
import { DEFAULT_TAG_COLOR, UNREAD_THEME_COLOR } from "../../styles/constants";
import { MapMode, TagInfo, ThemeDefine, ThemeKind } from "../../types/types";
import * as dbAccessor from "../../util/DbAccessor";
import * as actions from './actions';
import * as operationActions from '../operation/actions';
import * as operationThunks from '../../store/operation/thunks';
import { FeatureDocItem, FeatureDocItemStore } from "../../types/feature";
import { DialogMode } from "../../types/operation";

/**
 * 地物情報をロードする
 */
export function loadFeatureThunk(): ThunkAction<void, RootState, unknown, Action<string>> {
    return async (dispatch: Dispatch<any>, getState: () => RootState) => {
        dbAccessor.getFeatureQuery().onSnapshot(async (querySnapshot) => {
            const registItems = [] as Array<{id: string; item: FeatureDocItem}>;
            querySnapshot.docChanges().forEach((change) => {
                const doc = change.doc;
                const id = doc.id;
                switch(change.type) {
                    case "added":
                    case "modified":
                        const data = doc.data() as FeatureDocItemStore;
                        if (data.date === null) {
                            const date= doc.get('date', {
                                serverTimestamps: 'estimate',
                            });
                            data.date = date;
                        }
                        const item = dbAccessor.parseFeatureDocItemStore(data);
    
                        registItems.push({id, item});
                        break;

                    case "removed":
                        dispatch(actions.removeFeatureAction([id]));
                        break;
                }
            });
            if (registItems.length > 0) {
                // ストアにまとめて登録
                dispatch(actions.registFeatureAction(registItems));
            }
            dispatch(operationActions.changeModeAction(MapMode.NORMAL));
        });
    }
}

/**
 * 強調表示対象のタグ情報を更新する
 * @param param 
 */
export function updateThemeTagThunk(tag: TagInfo | undefined): ThunkAction<void, RootState, unknown, Action<string>> {
    return async (dispatch: Dispatch<any>, getState: () => RootState) => {
        const newThemeDefines = getState().featureReducer.themeDefines.concat();
        // 現在のタグ指定による主題図定義を除去
        const index = getState().featureReducer.themeDefines.findIndex((def) => {
            return def.kind === ThemeKind.Tag;
        });
        if (index !== -1) {
            newThemeDefines.splice(index, 1);
        }
        if (tag === undefined) {
            // 「タグ指定なし」にする場合は、タグ指定除去したのみで更新実行
            dispatch(actions.updateThemeDefinesAction(newThemeDefines));
            return;
        }

        // 強調表示対象となる建物を取得する
        let featureInfoMap = {} as {[featureId: string]: number[]};  // 強調表示対象の建物ID一覧
        featureInfoMap = await dbAccessor.getTaggedFeatureInfoMap(tag);

        const define = {
            kind: ThemeKind.Tag,
            value: tag.id,
            color: tag.color === undefined ? DEFAULT_TAG_COLOR : tag.color,
            featureInfoMap,
        } as ThemeDefine;
        newThemeDefines.push(define);
        dispatch(actions.updateThemeDefinesAction(newThemeDefines));
    };
}

export function searchByKeywordThunk(keyword: string): ThunkAction<void, RootState, unknown, Action<string>> {
    return async (dispatch: Dispatch<any>, getState: () => RootState) => {
        // 強調表示対象となる建物を取得する
        const featureInfoMap = await dbAccessor.searchByKeyword(keyword);

        // 建物名から検索 TODO: 将来的には、DB検索に切り替え
        Object.values(getState().featureReducer.features).filter((feature) => {
            return feature.name.indexOf(keyword) !== -1;
        }).forEach((feature) => {
            if (featureInfoMap[feature.id] === undefined) {
                featureInfoMap[feature.id] = [];
            }
        });

        if (Object.keys(featureInfoMap).length === 0) {
            // 検索結果が0件の場合
            dispatch(
                operationThunks.confirmThunk({
                    message: '検索結果が0件です。',
                    mode: DialogMode.OkOnly,
                })
            );
            return;
        }
        const define = {
            kind: ThemeKind.Keyword,
            value: keyword,
            color: DEFAULT_TAG_COLOR,
            featureInfoMap,
        } as ThemeDefine;

        // 強調表示
        dispatch(actions.updateThemeDefinesAction([define]));

    };
}

/**
 * 未読情報を保持する建物を強調表示する
 */
export function unreadInfoFeatureThemeThunk(): ThunkAction<void, RootState, unknown, Action<string>> {
    return async (dispatch: Dispatch<any>, getState: () => RootState) => {
        // 強調表示対象となる建物を取得する
        const featureInfoMap = await dbAccessor.getUnreadInfoFeature();
        console.log('featureInfoMap', featureInfoMap);
 
        if (Object.keys(featureInfoMap).length === 0) {
            // 検索結果が0件の場合
            dispatch(
                operationThunks.confirmThunk({
                    message: '未読はありません。',
                    mode: DialogMode.OkOnly,
                })
            );
            return;
        }

        const define = {
            kind: ThemeKind.Keyword,
            value: '',
            color: UNREAD_THEME_COLOR,
            featureInfoMap,
        } as ThemeDefine;

        // 強調表示
        dispatch(actions.updateThemeDefinesAction([define]));
    }
}