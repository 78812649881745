import React, { useEffect, useState } from 'react';
import { FeatureType, RoadWidth } from '../../../types/types';
import PromptMessageBox from './PromptMessageBox';
import OlFeature from 'ol/Feature';
import MapUtil from '../MapUtil';
import { Map } from 'ol';
import VectorSource from 'ol/source/Vector';
import VectorLayer from 'ol/layer/Vector';
import { topographyStyleFunction } from '../style';

type Props = {
    map: Map;
    targetRoad: OlFeature;
    width?: RoadWidth;  // 初期値
    onOk: (width: RoadWidth) => void;
    onCancel: () => void;
}

const widthSimulateSource = new VectorSource();

/**
 * 道幅選択部品
 */
export default function RoadWidthSelecter(props: Props) {
    const [width, setWidth] = useState(props.width === undefined ? RoadWidth.M : props.width);

    useEffect(() => {
        const widthSimulateLayer: VectorLayer = new VectorLayer({
            source: widthSimulateSource,
            style: topographyStyleFunction,
        });
    
        props.map.addLayer(widthSimulateLayer);

        return () => {
            props.map.removeLayer(widthSimulateLayer);
        };
    }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []);

    useEffect(() => {
        // 新たな道幅で描画
        const tempFeature = props.targetRoad.clone();
        tempFeature.setProperties({'type': FeatureType.ROAD});
        const distance = MapUtil.getRoadWidth(width);
        MapUtil.convertLineToPolygon(tempFeature, distance);
        widthSimulateSource.clear();
        widthSimulateSource.addFeature(tempFeature);
    }, [width, props.targetRoad]);

    // 道幅変更
    const onSelectWidthChanged = (evt: React.ChangeEvent<HTMLSelectElement>) => {
        const newwidth = evt.target.value as any as RoadWidth;
        setWidth(newwidth);
    }

    const message = '道幅を選択してください.';
    return (
        <PromptMessageBox message={message} okname="完了" ok={()=>{props.onOk(width)}} cancel={props.onCancel}>
            <select value={width} className="form-control" onChange={onSelectWidthChanged}>
                <option value={RoadWidth.S}>小道</option>
                <option value={RoadWidth.M}>一般道</option>
                <option value={RoadWidth.L}>大通り</option>
            </select>
       </PromptMessageBox>
    );

}