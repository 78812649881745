export const ROAD_COLOR = '#b1a560';
export const STRUCTURE_COLOR = '#E5DEB8'
export const EARTH_COLOR = '#f5f2e9'
export const FOREST_COLOR = '#B6EB7A'
export const SELECT_COLOR = '#75CF31';
export const DEFAULT_TAG_COLOR = '#0000ff';
export const UNREAD_THEME_COLOR = '#6ac991';

// 道路
export const ROAD_STYLE = {
    color: ROAD_COLOR,
    weight: 1,
    fillColor: ROAD_COLOR,
    fillOpacity: 1,
};
// 建物
export const STRUCTURE_STYLE = {
    color: STRUCTURE_COLOR,
    fillColor: STRUCTURE_COLOR,
    weight: 1,
    fillOpacity: 1,
};
// 地面
export const EARTH_STYLE = {
    color: EARTH_COLOR,
    fillColor: EARTH_COLOR,
    weight: 0,
    fillOpacity: 1,
}
// 緑地
export const FOREST_STYLE = {
    color: FOREST_COLOR,
    fillColor: FOREST_COLOR,
    weight: 0,
    fillOpacity: 1,
}

export const SELECT_STYLE = {
    color: SELECT_COLOR,
    weight: 3,
};
