import React, { Component } from 'react'
import { DispatchProps, TagInfo } from '../../types/types';
import { RootState } from '../../store';
import { connect } from 'react-redux';
import './TagPanel.scss';
import TagList from './TagList';
import { DEFAULT_TAG_COLOR } from '../../styles/constants';
import StateUtility from '../../util/StateUtility';
import TagBadge from '../common/TagBadge';

type OwnProps = {
    /** 親からもらうprops定義 */
    tags: TagInfo[];        // 現在のタグ
    change?: (tags: TagInfo[]) => void; // タグ内容が変化した場合。undefinedの場合、タグの追加、削除不可。
}
type StateProps = OwnProps & {
    /** ストアの内容から生成するprops定義 */
    tagInfo: (name: string) => TagInfo | undefined; // 既存のタグに指定のタグ名のものが存在したら返す
}
type Props = StateProps & DispatchProps;

const mapStateToProps = (state: RootState, ownProps: OwnProps): StateProps => ({
    ...ownProps,
    tagInfo: (name: string): TagInfo | undefined => {
        return Object.values(state.infoReducer.tags).find((tag: TagInfo) => {
            return tag.name === name;
        });
    },
});

enum Mode {
    NONE,
    INPUT,  // タグ入力中
}
type State = {
    mode: Mode;
    value: string;  // 入力中の文字列
    selected: TagInfo | undefined;    // 色変更中のタグ
};

class TagPanel extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            mode: Mode.NONE,
            value: '',
            selected: undefined,
        };
    }

    render() {
        const tags = this.taglist.map((tag: TagInfo) => {
            const onDelete = this.props.change !== undefined ? this.onDeleteClicked.bind(this, tag) : undefined;
            return (
                <TagBadge tag={tag} delete={onDelete} colorChangeable colorChange={this.onColorChanged.bind(this, tag)} key={tag.id} />
            );
        });
        // タグ入力エリア
        let editArea;
        if (this.props.change !== undefined) {
            if (this.state.mode === Mode.NONE) {
                editArea = <span className="add-tag-label" onClick={this.onAddClicked.bind(this)}>タグを追加..</span>;
            } else {
                editArea =
                    <React.Fragment>
                        <input autoFocus className="form-control form-control-sm" type="text" onChange={this.onTagNameChanged.bind(this)} onKeyPress={this.onTagNameKeyPressed.bind(this)} onBlur={this.onTagNameInputBlur.bind(this)} />
                        <div className="tag-list">
                            <TagList exclude={this.props.tags} keyword={this.state.value} select={this.onSelect.bind(this)} />
                        </div>
                    </React.Fragment>
            }
        }
        return (
            <div className="tag-area">
                {tags}
                {editArea}
            </div>
        );
    }

    private get taglist(): TagInfo[] {
        return StateUtility.sortedTagInfo(this.props.tags);
    }

    private onAddClicked(event: React.MouseEvent<HTMLInputElement>) {
        this.setState({
            mode: Mode.INPUT,
        });
        event.stopPropagation();
    }

    private onTagNameKeyPressed(event: React.KeyboardEvent<HTMLInputElement>) {
        if (this.props.change === undefined) {
            return;
        }
        const key = event.keyCode || event.charCode || 0;

        if (key !== 13) {
            return;
        }
        // Enterの場合、タグ仮生成
        let newTagInfo = this.props.tagInfo(this.state.value);
        if (newTagInfo === undefined) {
            newTagInfo = {
                id: '',
                name: this.state.value,
                color: DEFAULT_TAG_COLOR,
            };
        }
        const newTagInfos = this.props.tags.concat(newTagInfo);
        this.props.change(newTagInfos);
        this.setState({
            mode: Mode.NONE,
            value: '',
        });
    }

    private onTagNameChanged(event: React.ChangeEvent<HTMLInputElement>) {
        const newTagName = event.target.value;
        this.setState({
            value: newTagName,
        });
    }

    // 既存のタグが選択された場合
    private onSelect(tag: TagInfo) {
        if (this.props.change === undefined) {
            return;
        }
        const newTagInfos = this.props.tags.concat(tag);
        this.props.change(newTagInfos);
        this.setState({
            mode: Mode.NONE,
        });
    }

    // タグ除去
    private onDeleteClicked(deltag: TagInfo) {
        if (this.props.change === undefined) {
            return;
        }
        const newTagInfos = this.props.tags.filter((tag) => {
            return tag.id !== deltag.id;
        });
        this.props.change(newTagInfos);
    }

    private onColorChanged(tag: TagInfo, newColor: string) {
        // タグの色更新
        const newTag = Object.assign({}, tag, {
            color: newColor,
        }) as TagInfo;

        if (newTag.id.length === 0) {
            // 未登録タグの場合
            const newTagInfos = JSON.parse(JSON.stringify(this.props.tags)) as TagInfo[];
            const hit = newTagInfos.find((tag) => {
                return tag.name === newTag.name;
            });
            if (hit === undefined) {
                console.warn('対象タグなし');
                return;
            }
            hit.color = newColor;
            if (this.props.change !== undefined) {
                this.props.change(newTagInfos);
            }
        }

    }

    private onTagNameInputBlur() {
        if (this.state.mode !== Mode.INPUT) {
            return;
        }
        // 既存タグが選択された場合に備えて、一拍おく
        setTimeout(() => {
            this.setState({
                mode: Mode.NONE,
            });
        }, 200);
    }

}
export default connect(mapStateToProps)(TagPanel);
