import React, { useEffect } from 'react';
import styles from './App.module.scss';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Main from './view/Main';
import ConfirmDialog from './components/common/ConfirmDialog';
import { DispatchProps } from './types/types';
import { RootState } from './store';
import { connect } from 'react-redux';
import SelectVillage from './view/SelectVillage';
import * as systemThunks from './store/system/thunks';
import DeleteUser from './components/auth/DeleteUser';
import AuthNotification from './components/auth/AuthNotification';

type OwnProps = {
    /** 親からもらうprops定義 */
}
type StateProps = OwnProps & {
    /** ストアの内容から生成するprops定義 */
    signInUserId: string | undefined;
}
type Props = StateProps & DispatchProps;

const mapStateToProps = (state: RootState, ownProps: OwnProps): StateProps => ({
    ...ownProps,
    signInUserId: state.systemReducer.signInUserId,
});

type State = {}

function App(props: Props) {
    useEffect(() => {
        props.dispatch(systemThunks.startWatchSignInOutThunk());

    }, 
    // eslint-disable-next-line
    []);

    return (
        <Router basename="/">
            <div className={styles.App}>
                <Switch>
                    <Route exact path="/delete" component={DeleteUser} />
                    <Route exact path="/:id" component={Main} />
                    <Route path="/" component={SelectVillage} />
                </Switch>
                <ConfirmDialog />
                <AuthNotification />
            </div>
        </Router>
    );
}
export default connect(mapStateToProps)(App);

