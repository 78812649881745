import React, { useCallback, useEffect, useState } from 'react';
import styles from './ImageRegister.module.scss';
import { Form } from 'react-bootstrap';
import { ImageInfo } from '../../types/types';
import * as CommonUtility from '../../util/CommonUtility';

enum Mode {
    SELECT_METHOD,  // 画像登録方法選択状態
    INPUT_URL,      // URLでの画像登録中
    INPUT_FILE,     // 端末画像登録中
}

type Props = {
    maxOriginalSize?:number,    // 大サイズ画像長辺サイズ
    maxThumbSize?: number,      // サムネイル長辺サイズ
    onSelect: (image: ImageInfo) => void,  // 画像が選択された場合のコールバック
    onCancel: () => void;
}

/**
 * 画像取得コンポーネント
 * Webまたは端末から画像を取得する。
 * @param props 
 * @returns 
 */
export default function ImageRegister(props: Props) {
    const [currentMode, setCurrentMode] = useState(Mode.SELECT_METHOD);
    const [url, setUrl] = useState('');
    const [errMsg, setErrMsg] = useState('');

    useEffect(() => {
        setErrMsg('');
    }, [currentMode]);

    const handleChangeUrl = useCallback((event: any) => {
        const url = event.target.value;
        setUrl(url);
    }, []);

    /**
     * 画像ロード用のImageオブジェクトを生成して返す
     */
    const createImageForLoad = useCallback((): HTMLImageElement => {
        const img = CommonUtility.createImageForLoad({
            maxOriginalSize:  props.maxOriginalSize,
            maxThumbSize: props.maxThumbSize,
            callback: (image: ImageInfo) => {
                props.onSelect(image);
            },
            errorCallback: () => {
                setErrMsg('この画像は取得できません.');
            }
        })
        return img;
    }, [props]);
    
    const onUploadBtnClicked = useCallback(() => {
        const img = createImageForLoad();
        img.src = url;
    }, [url, createImageForLoad]);

    const onFileSelected = useCallback((event: React.FormEvent) => {
        const files = (event.target as any).files as FileList;
        if (files.length === 0) {
            return;
        }
        const file = files[0];

        const img = createImageForLoad();
        var reader = new FileReader();
        reader.onload = (evt) => {
            img.src = evt.target?.result as string;
        };
        reader.readAsDataURL(file);
    }, [createImageForLoad]);

    switch(currentMode) {
        case Mode.SELECT_METHOD:
            return (
                <React.Fragment>
                    <div className={styles.InputArea}>
                        <button className={`${styles.btn} btn btn-outline-secondary`} onClick={() => setCurrentMode(Mode.INPUT_URL)}>
                            <i className="icon-sphere"></i>
                            Web画像
                        </button>
                        <button className={`${styles.btn} btn btn-outline-secondary`} onClick={() => setCurrentMode(Mode.INPUT_FILE)}>
                            <i className="icon-display"></i>
                            端末画像
                        </button>
                    </div>
                    <div className={styles.BtnArea}>
                        <button className={`${styles.btn} btn btn-secondary`} onClick={() => props.onCancel()}>Cancel</button>
                    </div>
                </React.Fragment>
            );
        case Mode.INPUT_URL:
            return (
                <React.Fragment>
                    <div className={styles.InputArea}>
                        <input value={url} className="form-control" placeholder="画像URLを入力" onChange={(e) => handleChangeUrl(e)} />
                        <p className={`${styles.ErrorMessage} text-danger`}>{errMsg}</p>
                    </div>
                    <div className={styles.BtnArea}>
                        <button className={`${styles.btn} btn btn-primary`} onClick={() => onUploadBtnClicked()}>取込</button>
                        <button className={`${styles.btn} btn btn-secondary`} onClick={() => setCurrentMode(Mode.SELECT_METHOD)}>Cancel</button>
                    </div>
                </React.Fragment>
            );

        case Mode.INPUT_FILE:
            return (
                <React.Fragment>
                    <div className={styles.InputArea}>
                        <Form.File label="画像ファイル" custom data-browse="選択" accept="image" onChange={(e: React.FormEvent) => onFileSelected(e)} />
                        <p className={`${styles.ErrorMessage} text-danger`}>{errMsg}</p>
                    </div>
                    <div className={styles.BtnArea}>
                        <button className={`${styles.btn} btn btn-secondary`} onClick={() => setCurrentMode(Mode.SELECT_METHOD)}>Cancel</button>
                    </div>
                </React.Fragment>
            );
    }
}