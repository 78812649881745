import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import { RootState } from '../../../store';
import { DispatchProps, Feature, StructureImageDefine } from '../../../types/types';
import styles from './StructureListDialog.module.scss';
import * as dbAccessor from "../../../util/DbAccessor";

type OwnProps = {
    /** 親からもらうprops定義 */
    cancel: () => void;
    finish: () => void; // 削除実行完了時のコールバック
    featureIdArr: string[];
}
type StateProps = OwnProps & {
    /** ストアの内容から生成するprops定義 */
    features: { [id: string]: Feature };  // 全地物情報
    iconDefine: StructureImageDefine[],
}
const mapStateToProps = (state: RootState, ownProps: OwnProps): StateProps => ({
    ...ownProps,
    features: state.featureReducer.features,
    iconDefine: state.systemReducer.iconDefine,
});
type Props = StateProps & DispatchProps;

function StructureListDialog(props: Props) {
    const [infoNumMap, setInfoNumMap] = useState(null as null | {[id: string]: number});
    const [checkMap, setCheckMap] = useState({} as {[id: string]: boolean});
    const [running, setRunning] = useState(false);  // 削除処理実行中かどうか
    const [featureInfoMap, setFeatureInfoMap] = useState({} as {[id: string]: {icon: string, name: string}});   // 削除すると情報が消えるのでここで保持しておく。

    useEffect(() => {
        const newMap = {} as {[id: string]: {icon: string, name: string}};
        props.featureIdArr.forEach((id) => {
            const feature = props.features[id];
            if (feature === undefined) {
                return;
            }

            const iconDef = props.iconDefine.find((def) => {
                if (feature.geoJson.properties === null) {
                    return false;
                }
                if (feature.geoJson.properties.iconId) {
                    return def.id === feature.geoJson.properties.iconId;
                } else {
                    return def.imagePath === feature.geoJson.properties.iconPath;
                }
            });
            newMap[id] = {
                name: feature.name,
                icon: iconDef ? iconDef.imagePath : '',
            }
        });
        setFeatureInfoMap(newMap);
    }, [props.features, props.featureIdArr, props.iconDefine]);

    useEffect(() => {
        dbAccessor.getInfoNum(props.featureIdArr)
        .then((infoNumMap) => {
            setInfoNumMap(infoNumMap);

            // 情報ゼロ&建物名なしのものについて、チェックボックスの初期値True
            const newCheckMap = {} as {[id: string]: boolean};
            Object.keys(infoNumMap).forEach((featureId) => {
                newCheckMap[featureId] = (infoNumMap[featureId] === 0 && props.features[featureId].name.length === 0);
            });
            setCheckMap(newCheckMap);
        })
    }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []);

    const list = props.featureIdArr.map((id) => {
        if (featureInfoMap[id] === undefined) {
            return null;
        }
        console.log('featureInfoMap', featureInfoMap);
        const name = featureInfoMap[id].name;
        const iconPath = featureInfoMap[id].icon;

        const numValue = infoNumMap === null ? "取得中…" : infoNumMap[id];

        return (
            <tr>
                <td><input type="checkbox" className="form-check-input" onChange={(e) => onCheckChanged(id, e)} checked={checkMap[id]} /></td>
                <td><img src={iconPath} alt="structure" /></td>
                <td>{name}</td>
                <td>{numValue}</td>
            </tr>
        )
    });

    const onCheckChanged = (id: string, event: React.ChangeEvent<HTMLInputElement>) => {
        const newCheckMap = Object.assign({}, checkMap);
        newCheckMap[id] = event.target.checked;
        setCheckMap(newCheckMap);
    }

    const checkedFeatureIdArr = Object.keys(checkMap).filter((id) => {return checkMap[id]});

    // 削除実行
    const execute = async () => {
        setRunning(true);
        await Promise.all(checkedFeatureIdArr.map((featureId) => {
            return new Promise<void>(async(resolve, reject) => {
                // DB更新
                await dbAccessor.removeFeature(featureId);

                resolve();
            })
        }));
        props.finish();
    }

    const mask = infoNumMap === null || running ? (
        <div className={styles.mask}>
            <div className="spinner-border text-light" role="status">
                <span className="sr-only">{running ? 'deleting...' : 'Loading...'}</span>
            </div>
        </div>
    ) : null;

    const checkedNum = checkedFeatureIdArr.length;

    return (
        <Modal show={true}>
            <Modal.Header>確認</Modal.Header>
            <Modal.Body>
                削除して問題ない建物をチェックしてください。
                <div className={styles.bodyArea}>
                    {mask}
                    <table className={`table ${styles.structureList}`}>
                        <thead>
                            <tr>
                                <th scope="col" className={styles.checkColumn}>削除</th>
                                <th scope="col" className={styles.imageColumn}></th>
                                <th scope="col" className={styles.nameColumn}>建物名</th>
                                <th scope="col" className={styles.infoColumn}>情報数</th>
                            </tr>
                        </thead>
                        <tbody>
                            {list}
                        </tbody>
                    </table>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <span className={styles.countArea}>
                    選択数 {checkedNum}/{props.featureIdArr.length}
                </span>
                <button className="btn btn-primary" disabled={checkedNum===0 || running} onClick={execute}>実行</button>
                <button className="btn btn-secondary" disabled={running} onClick={props.cancel}>Cancel</button>
            </Modal.Footer>
        </Modal>
    )
}
export default connect(mapStateToProps)(StructureListDialog);
