import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { RootState } from '../../store';
import { DispatchProps } from '../../types/types';
import styles from './InfoLink.module.scss';
import * as operationActions from '../../store/operation/actions';
import { KickCommand } from '../../types/operation';
import * as dbAccessor from "../../util/DbAccessor";

type InfoLinkProps = {
    infoId: number;
    title: string;
}
export function InfoLink(props: InfoLinkProps) {
    const title = useMemo(() => {
        if (props.title.length > 8) {
            return props.title.substring(0, 8) + '...';
        } else {
            return props.title;
        }
    }, [props.title]);

    return (
        <span className={styles.infotag} contentEditable={false} data-info={props.infoId}>{title}</span>
    );
}

type OwnProps = {
    /** 親からもらうprops定義 */
    infoId: number;
}
type StateProps = OwnProps & {
    /** ストアの内容から生成するprops定義 */
}
type Props = StateProps & DispatchProps;

const mapStateToProps = (state: RootState, ownProps: OwnProps): StateProps => ({
    ...ownProps,
});

function InfoLinkWithStoreFunction(props: Props) {
    const [fullTitle, setFullTitle] = useState('wait...');

    useEffect(() => {
        dbAccessor.getInfoTitle(props.infoId)
        .then((title: string) => {
            setFullTitle(title);
        });
    }, [props.infoId]);

    const title = useMemo(() => {
        if (fullTitle.length > 8) {
            return fullTitle.substring(0, 8) + '...';
        } else {
            return fullTitle;
        }
    }, [fullTitle]);

    const onClick = useCallback(() => {
        // 指定の情報へ移動
        props.dispatch(operationActions.kickCommand(KickCommand.JumpToInfo, {infoId: props.infoId}));
    }, [props]);

    return (
        <span className={`${styles.infotag} ${styles.linkable}`} onClick={onClick} contentEditable={false} data-info={props.infoId}>{title}</span>
    );
}
export const InfoLinkWithStore = connect(mapStateToProps)(InfoLinkWithStoreFunction);
