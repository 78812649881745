import { Dispatch } from "react"
import { ThunkDispatch } from "redux-thunk"
import { Operation } from "../util/DbAccessor"
import { FeatureDocItem, TagsDocItem } from "./feature"

export type DispatchProps = {
    dispatch: Dispatch<any> | ThunkDispatch<any, any, any>;
}

export type VillageInfo = {
    disclosure: 'public' | 'private' | 'hidden';
    name: string;
}
export type VillageUserInfoStore = {
    name: string;
    groups: string[];
    special: boolean;
    view: { center: number[]; zoom: number };
}
export type VillageUserInfo = VillageUserInfoStore & {
    id: string;
}
export type UserInfoStore = {
    messagingToken?: string[];
}
export type GroupInfoStore = {
    name: string;
}
export type GroupInfo = GroupInfoStore & {
    id: string;
}

/**
 * 地物情報
 */
export type Feature = FeatureDocItem & {
    id: string;
    // children?: string[];
}
export type FeatureWithOperation = Feature & {
    operation: Operation;
    endDate?: Date;      // この地物情報の寿命（終了）
}
export type TagInfo = TagsDocItem & {
    id: string;
};

/**
 * 情報
 */
export type ImageInfo = {
    original: string;
    thumb: string;
}

/**
 * 地図モード
 */
export enum MapMode {
    LOADING,        // 地図データ読み込み中
    NORMAL,
    DRAW,           // 作図中（地図上に建物選択やメニュー実行などができない状態）
}

/**
 * 地物種別
 * (DBに登録するので、後から増減可能なように文字列型にしている)
 */
export enum FeatureType {
    STRUCTURE = 'STRUCTURE',
    ROAD = 'ROAD',
    EARTH = 'EARTH',
    FOREST = 'FOREST',
}

export enum ThemeKind {
    Tag,            // タグ指定
    Keyword,        // キーワード
    Calendar,       // 日時
}
// 主題図定義
export type ThemeDefine = {
    kind: ThemeKind;
    value: string;          // タグ指定の場合、タグID。キーワード指定の場合、キーワード。カレンダー指定の場合、日付文字列。
    color: string;          // 色
    featureInfoMap: {[featureId: string]: number[]};    // 主題図条件に該当する建物IDと情報ID。建物名など建物に付随する情報のみに該当している場合は情報IDゼロ件
}

export type StructureImageDefine = {
    id: string;
    imagePath: string;
    name: string;
    original: boolean;
}
export enum RoadWidth {
    S = 'S',
    M = 'M',
    L = 'L',
}
export type FacebookGroupEventInfoResult = {
    token: string;  // 取得時に使用したトークン
    id: string;
    groups: FacebookGroupEventInfo[];
}
export type FacebookGroupEventInfo = {
    id: string;
    name: string;
    events: FacebookEventInfo[];
}
export type FacebookEventInfo = {
    id: string;
    name: string;
    cover?: {
        id: string;
        offset_x: number;
        offset_y: number;
        source: string;
    }
    description: string;
    start_time: string;
    end_time?: string;
}
export type SourceInfo = {
    source_type: 'manual' | 'facebook';
} & (FacebookSourceInfo | {});

export enum FacebookInfoType {
    Event = 'Event',
}
type FacebookSourceInfo = {
    token: string;  // 情報取得時に使用したtoken
    info_type: FacebookInfoType;
    id: string;
}
