// 地図上のポップアップダイアログ
import React, { useMemo, useState } from 'react'
import { DispatchProps } from '../../types/types';
import { RootState } from '../../store';
import { connect } from 'react-redux';
import styles from './Popup.module.scss';
import { KickCommand } from '../../types/operation';
import MapDefine from './map_define';
import { useCommandCatch } from '../../util/customHooks';

type OwnProps = {
    /** 親からもらうprops定義 */
    imageUrl: string;
    close?: () => void;  // ポップアップのcloseボタンが押された場合のイベントハンドラ
    click?: () => void;  // ポップアップがclickされた場合のイベントハンドラ
}
type StateProps = OwnProps & {
    /** ストアの内容から生成するprops定義 */
    zoom: number;
    isThemed: boolean;
    disabledPopup: boolean;
}
type Props = StateProps & DispatchProps;

const mapStateToProps = (state: RootState, ownProps: OwnProps): StateProps => ({
    ...ownProps,
    zoom: state.operationReducer.zoom,
    isThemed: state.featureReducer.themeDefines.length > 0,
    disabledPopup: state.operationReducer.disabledPopup,
});

function Popup(props: Props) {
    let clickFlag = false;
    const [minimum, setMinimum] = useState(false);
    const visible = useMemo(() => {
        if (props.disabledPopup) {
            return false;
        }
        // 一定以上に引いている場合は、ポップアップ非表示
        return props.isThemed || props.zoom > MapDefine.PopupShowZoomLv;
    }, [props.isThemed, props.zoom, props.disabledPopup]);

    let className = styles.Popup;
    if (minimum) {
        className += ' ' + styles.minimum;
    }

    useCommandCatch(KickCommand.AllPopupOpen, () => {
        // 最大化
        setMinimum(false);
    });
    useCommandCatch(KickCommand.AllPopupClose, () => {
        // 最小化
        setMinimum(true);
    });

    const onMouseDown = () => {
        clickFlag = true;
    }

    const onMouseMove = () => {
        // D&Dはクリックとみなさない
        clickFlag = false;
    }

    const onMouseUp = () => {
        if (props.click === undefined) {
            return;
        }
        if (!clickFlag) {
            return;
        }
        props.click();
    }

    const onSwitchSize = () => {
        setMinimum(!minimum);
    }


    if (!visible) {
        return null;
    }
    

    return (
        <div className={className} 
            onMouseDown={onMouseDown} onTouchStart={onMouseDown}
            onMouseMove={onMouseMove} onTouchMove={onMouseMove} 
            onMouseUp={onMouseUp} onTouchEnd={onMouseUp}>
            {!minimum ?
                <React.Fragment>
                    <div className={styles.MinBtn}>
                        <a href="# " onClick={onSwitchSize}><i className="icon-caret-down"></i></a>
                    </div>
                    <div className={styles.content}>
                        <img draggable={false} src={props.imageUrl} alt="infoimage" />
                    </div>

                </React.Fragment>
                :
                <a href="# " onClick={onSwitchSize}><i className="icon-external-link"></i></a>
            }
        </div>
    );



}
export default connect(mapStateToProps)(Popup);
