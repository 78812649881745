import React, { Component } from 'react';
import { DispatchProps, ThemeDefine, ThemeKind } from '../../types/types';
import { RootState } from '../../store';
import { connect } from 'react-redux';
import styles from './SearchPanel.module.scss';
import '../../styles/_common.scss';
import * as featureThunks from '../../store/feature/thunks';
import * as featureActions from '../../store/feature/actions';

type OwnProps = {
    /** 親からもらうprops定義 */
}
type StateProps = OwnProps & {
    /** ストアの内容から生成するprops定義 */
    keywordThemeDefine: ThemeDefine[];
}
type Props = StateProps & DispatchProps;

const mapStateToProps = (state: RootState, ownProps: OwnProps): StateProps => ({
    ...ownProps,
    keywordThemeDefine: state.featureReducer.themeDefines.filter((def: ThemeDefine) => {
        return def.kind === ThemeKind.Keyword;
    }),
});

type State = {
    keyword: string;
}

class SearchPanel extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        const keyword = props.keywordThemeDefine.length > 0 ? props.keywordThemeDefine[0].value : '';

        this.state = {
            keyword,
        };
    }

    render() {
        return (
            <div>
                <div className="input-group mb-3">
                    <input value={this.state.keyword} type="text" className="form-control" placeholder="キーワード" aria-label="検索キーワード入力" onKeyPress={this.onKeyPress.bind(this)} onChange={this.onKeywordChanged.bind(this)} />
                    <div className="input-group-append">
                        <button className={`${styles.searchBtn} btn btn-info`} onClick={this.onSearchBtnClicked.bind(this)} disabled={!this.searchable}>
                            <i className="icon-search"></i>
                        </button>
                    </div>
                </div>
                <div className="input-group">
                    <button className={`${styles.clearBtn} btn btn-light`} onClick={this.onClearBtnClicked.bind(this)}>クリア</button>
                </div>
            </div>
        );
    }

    private onKeyPress(evt: React.KeyboardEvent<HTMLInputElement>) {
        if (evt.key === 'Enter') {
            this.onSearchBtnClicked();
        }

    }
    private onKeywordChanged(evt: React.ChangeEvent<HTMLInputElement>) {
        this.setState({
            keyword: evt.target.value,
        });
    }

    private get searchable(): boolean {
        return this.state.keyword.length > 0;
    }

    private onSearchBtnClicked() {
        if (!this.searchable) {
            return;
        }
        this.props.dispatch(featureThunks.searchByKeywordThunk(this.state.keyword));
    }

    private onClearBtnClicked() {
        this.props.dispatch(featureActions.updateThemeDefinesAction([]));
        this.setState({
            keyword: '',
        });
    }
}
export default connect(mapStateToProps)(SearchPanel);
