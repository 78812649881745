import React, { useEffect, useState } from 'react';
import { connect } from "react-redux";
import { RootState } from '../../store';
import { DispatchProps } from '../../types/types';
import FirebaseAuth from './FirebaseAuth';
import RegistUser from './RegistUser';
import * as dbAccessor from "../../util/DbAccessor";

enum Stage {
    Authing,    // Firebase認証待ち
    SignUp,     // 住民登録待ち
    Finish,     // 完了
}

type OwnProps = {
    /** 親からもらうprops定義 */
    group?: string;     // 住民登録する際に所属させるグループID

    complete: () => void;   // 認証～住民登録まで完了した際のコールバック
}

type StateProps = OwnProps & {
    /** ストアの内容から生成するprops定義 */
    villageName: string;
    signInUserId: string | undefined;
}

type Props = StateProps & DispatchProps;

const mapStateToProps = (state: RootState, ownProps: OwnProps): StateProps => ({
    ...ownProps,
    villageName: state.systemReducer.village.name,
    signInUserId: state.systemReducer.signInUserId,
});

/**
 * 認証部品。
 * Firebase認証～住民登録まで行う。
 * @param props 
 */
function Auth(props: Props) {
    const [stage, setStage] = useState(Stage.Authing);

    useEffect(() => {
        if (props.signInUserId !== undefined && stage === Stage.Authing) {
            // Firebase認証された場合
            console.log('Firebase認証通過')

            // 住民登録済みかチェック
            dbAccessor.isRegistedUser(props.signInUserId)
            .then((result: boolean) => {
                if (result) {
                    // 住民登録済みの場合
                    setStage(Stage.Finish);
                } else {
                    // 住民登録
                    setStage(Stage.SignUp);
                }
            })
        }
    }, [props.signInUserId, stage]);

    useEffect(() => {
        if (stage === Stage.Finish) {
            props.complete();
        }
    }, [stage, props]);

    switch(stage) {
        case Stage.Authing:
            // Firebase認証待ち
            return (
                <FirebaseAuth />
            );

        case Stage.SignUp:
            // 住民登録待ち
            return (
                <div className="auth-area">
                    <h2>ようこそ、{props.villageName}へ！</h2>
                    <RegistUser uid={props.signInUserId as string} group={props.group} complete={()=>{setStage(Stage.Finish)}} />
                </div>
            );

        case Stage.Finish:
            return null;
    }
}
export default connect(mapStateToProps)(Auth);
