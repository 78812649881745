import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { KickCommand } from '../../types/operation';
import { useCommandCatch } from '../../util/customHooks';
import Auth from './Auth';

type Props = {
    /** 親からもらうprops定義 */
}

/**
 * 認証ダイアログ
 * Firebase認証～住民登録までを行う
 * @param props 
 */
export default function AuthDialog(props: Props) {
    const [show,  setShow] = useState(false);

    useCommandCatch(KickCommand.SignIn, () => setShow(true));

    const handleClose = () => {
        setShow(false);
    }

    return (
        <Modal show={show} onHide={() => handleClose()}>
            <Modal.Header closeButton>
                <Modal.Title>認証</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Auth complete={()=>{setShow(false)}} />
            </Modal.Body>
        </Modal>
    );
}
